import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    hasPopup: false,//视频弹框
    playVideo: [],//视频已播放
  },
  getters: {
  },
  mutations: {
    setShowPopup(state) {
      state.hasPopup = true
    },
    setPlayVideo(state, name) {
      if (state.playVideo.indexOf(name) == -1) {
        state.playVideo.push(name)
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
