<template>
  <!-- 我的任务 -->
  <div class="box">
    <fitNav title="我的任务"></fitNav>
    <van-row class="main">
      <!-- 进度 -->
      <van-row class="m-pace">
        <van-row type="flex" align="center" justify="space-between">
          <van-row type="flex" align="center">
            <van-image
              width="18px"
              height="18px"
              :src="require('../assets/pace.png')"
            >
            </van-image>
            <div class="b-title">任务进度</div>
            <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
          </van-row>
          
        </van-row>
        <van-row class="m-strip">
          <van-progress
            :percentage="task.taskProgress"
            color="linear-gradient(90deg, #34ABDD, #4285F4, #59C3FB)"
          />
          <van-row
            type="flex"
            align="center"
            justify="space-between"
            style="padding: 10px 0px; color: #999999"
          >
            <div>0%</div>
            <div>100%</div>
          </van-row>
        </van-row>
      </van-row>
      <!-- 千卡 -->
      <van-row
        class="m-kcal"
        type="flex"
        align="center"
        justify="space-between"
      >
        <van-col span="8" style="text-align: center">
          <div style="padding: 5px 0px">
            <span style="color: #4285f4">摄入</span>（千卡）
          </div>
          <div style="font-size: 35px">{{ task.inTakeEnergy }}</div>
        </van-col>
        <van-col span="8" style="text-align: center">
          <van-circle
            v-model="task.intakeRate"
            :rate="0"
            :speed="100"
            :text="text"
            layer-color="linear-gradient(38deg, #4285F4, #FBA55B, #FFC88D)"
          />
          <div style="padding-top: 5px">{{ task.energyLevel }}千卡</div>
        </van-col>
        <van-col span="8" style="text-align: center">
          <div><span style="color: #4285f4">燃烧</span>（千卡）</div>
          <div style="font-size: 35px">{{ task.useUpEnergy }}</div>
        </van-col>
      </van-row>
      <!-- <膳食日记 -->
      <van-row class="m-dite">
        <van-row type="flex" align="center">
          <van-image
            width="18"
            height="18"
            :src="require('../assets/pace.png')"
          ></van-image>
          <div class="b-title">膳食记录反馈</div>
          <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
        </van-row>
        <van-row class="d-ka">
          <div style="padding: 5px">
            <span style="color: #4285f4">还能摄入</span
            ><span style="color: #666"></span>
          </div>
          <div class="d-sum">{{ task.needIntake }}</div>
        </van-row>
        <van-row type="flex" justify="space-between">
          <van-col span="3" style="color: #666">推荐</van-col>
          <van-col span="5" style="text-align: center">
            <div style="color: #666">369-400</div>
            <van-image
              width="40px"
              height="40px"
              :src="require('../assets/drinkIcon.png')"
            ></van-image>
            <div style="color: #666; font-size: 13px">早餐</div>
            <div style="font-size: 16px">{{ task.zaoIntake }}</div>
          </van-col>
          <van-col span="5" style="text-align: center">
            <div style="color: #666">369-400</div>
            <van-image
              width="40px"
              height="40px"
              :src="require('../assets/drinkIcon.png')"
            ></van-image>
            <div style="color: #666; font-size: 13px">加餐</div>
            <div style="font-size: 16px">{{ task.jiaIntake }}</div>
          </van-col>
          <van-col span="5" style="text-align: center">
            <div style="color: #666">369-400</div>
            <van-image
              width="40px"
              height="40px"
              :src="require('../assets/drinkIcon.png')"
            ></van-image>
            <div style="color: #666; font-size: 13px">午餐</div>
            <div style="font-size: 16px">{{ task.wuIntake }}</div>
          </van-col>
          <van-col span="5" style="text-align: center">
            <div style="color: #666">369-400</div>
            <van-image
              width="40px"
              height="40px"
              :src="require('../assets/drinkIcon.png')"
            ></van-image>
            <div style="color: #666; font-size: 13px">晚餐</div>
            <div style="font-size: 16px">{{ task.wanIntake }}</div>
          </van-col>
        </van-row>
        <van-row class="d-hint" type="flex" align="center" v-show="task.moreIntake > 0">
          <div style="color: #4285f4; padding-right: 10px">多摄入</div>
          <div style="color: #666">{{ task.moreIntake }}卡</div>
        </van-row>
        <van-row style="padding: 20px 10px 0px 10px"> 今天还可以吃 </van-row>
        <van-row style="margin-top: 10px">
          <van-row
            type="flex"
            align="center"
            justify="space-between"
            style="margin-bottom: 10px"
          >
            <van-col span="7" class="col-power1">
              <div class="p-txt">谷类</div>
              <div class="p-num">{{ task.recommend.grain }}份</div>
            </van-col>
            <van-col span="8" class="col-power2">
              <div class="p-txt">薯类</div>
              <div class="p-num">{{ task.recommend.tubers }}份</div>
            </van-col>
            <van-col span="7" class="col-power3">
              <div class="p-txt">蔬菜类</div>
              <div class="p-num">{{ task.recommend.vegetables }}份</div>
            </van-col>
          </van-row>
          <van-row
            type="flex"
            align="center"
            justify="space-between"
            style="margin-bottom: 10px"
          >
            <van-col span="7" class="col-power4">
              <div class="p-txt">水果类</div>
              <div class="p-num">{{ task.recommend.fruit }}份</div>
            </van-col>
            <van-col span="8" class="col-power5">
              <div class="p-txt">畜禽肉水产类</div>
              <div class="p-num">{{ task.recommend.aquaticMeat }}份</div>
            </van-col>
            <van-col span="7" class="col-power6">
              <div class="p-txt">蛋类</div>
              <div class="p-num">{{ task.recommend.eggs }}份</div>
            </van-col>
          </van-row>
          <van-row type="flex" align="center" justify="space-between">
            <van-col span="7" class="col-power7">
              <div class="p-txt">大豆坚果类</div>
              <div class="p-num">{{ task.recommend.beanNut }}份</div>
            </van-col>
            <van-col span="8" class="col-power8">
              <div class="p-txt">奶及奶制品</div>
              <div class="p-num">{{ task.recommend.milchigs }}份</div>
            </van-col>
            <van-col span="7" class="col-power9">
              <div class="p-txt">油脂类</div>
              <div class="p-num">{{ task.recommend.oil }}份</div>
            </van-col>
          </van-row>
        </van-row>
      </van-row>
      <!-- 运动记录反馈 -->
      <van-row class="m-dite">
        <van-row type="flex" align="center">
          <van-image
            width="18"
            height="18"
            :src="require('../assets/pace.png')"
          ></van-image>
          <div class="b-title">运动记录反馈</div>
          <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
        </van-row>
        <van-row style="padding: 10px; text-align: center">
          <div style="color: #666">
            <span style="color: #4285f4">消耗总能量</span>(千卡)
          </div>
          <div style="font-size: 30px; padding: 10px 0px">{{ task.useUpEnergy }}</div>
          <div style="color: #a6a9b3">活动了{{ task.sportsTime }}分钟</div>
        </van-row>
        <!-- <van-row type="flex" justify="space-between" style="font-size: 13px">
          <van-col span="12" class="m-sport">
            <van-row type="flex" justify="center" align="center">
              <van-image
                width="40"
                height="40"
                :src="require('../assets/cereal.png')"
              ></van-image>
            </van-row>
            <van-row style="padding-left: 5px">
              <div style="padding: 5px 0px">有氧运动消耗</div>
              <div style="color: #a6a9b3">
                <span style="font-size: 20px; color: black; padding-right: 5px"
                  >120</span
                >千卡
              </div>
            </van-row>
          </van-col>
          <van-col span="11" class="m-sport">
            <van-row type="flex" justify="center" align="center">
              <van-image
                width="40"
                height="40"
                :src="require('../assets/cereal.png')"
              ></van-image>
            </van-row>
            <van-row style="padding-left: 5px">
              <div style="padding: 5px 0px">抗阻运动消耗</div>
              <div style="color: #a6a9b3">
                <span style="font-size: 20px; color: black; padding-right: 5px"
                  >120</span
                >千卡
              </div>
            </van-row>
          </van-col>
        </van-row>
        <van-row
          type="flex"
          justify="space-between"
          style="margin-top: 15px; font-size: 13px"
        >
          <van-col span="12" class="m-sport">
            <van-row type="flex" justify="center" align="center">
              <van-image
                width="40"
                height="40"
                :src="require('../assets/cereal.png')"
              ></van-image>
            </van-row>
            <van-row style="padding-left: 5px">
              <div style="padding: 5px 0px">柔韧性运动消耗</div>
              <div style="color: #a6a9b3">
                <span style="font-size: 20px; color: black; padding-right: 5px"
                  >120</span
                >千卡
              </div>
            </van-row>
          </van-col>
          <van-col span="11" class="m-sport">
            <van-row type="flex" justify="center" align="center">
              <van-image
                width="40"
                height="40"
                :src="require('../assets/cereal.png')"
              ></van-image>
            </van-row>
            <van-row style="padding-left: 5px">
              <div style="padding: 5px 0px">神经肌肉动作</div>
              <div style="color: #a6a9b3">
                <span style="font-size: 20px; color: black; padding-right: 5px"
                  >120</span
                >千卡
              </div>
            </van-row>
          </van-col>
        </van-row>
        <van-row type="flex" style="padding: 15px 10px 0px 10px">
          <div>今天还需要运动</div>
          <div style="color: #a6a9b3; padding-left: 10px">
            中等强度有氧运动20分钟
          </div>
        </van-row> -->
      </van-row>
      <!-- 记录 -->
      <van-row class="m-record">
        <van-row
          type="flex"
          align="center"
          justify="space-between"
          style="margin-bottom: 5px"
        >
          <van-col span="16">
            <van-row type="flex" align="center">
              <van-image
                width="50px"
                height="50px"
                :src="require('../assets/rice.png')"
              ></van-image>
              <van-row style="padding: 10px">
                <div class="re-title">膳食日记</div>
                <div>
                  <span style="display: inline-block; margin-right: 10px"
                    >{{ task.dietTaskCount }}/3</span
                  >未完成
                </div>
              </van-row>
            </van-row>
          </van-col>
          <van-col span="8" style="text-align: right">
            <van-row>
              <van-image
                width="15px"
                height="15px"
                :src="require('../assets/medals.png')"
              ></van-image
              ><span class="re-num">+1</span>
            </van-row>
            <van-button
              color="#005DFF"
              style="width: 70px; height: 32px"
              size="mini"
              round
              @click="getDiteDiary"
              >去记录</van-button
            >
          </van-col>
        </van-row>
        <van-row
          type="flex"
          align="center"
          justify="space-between"
          style="margin-bottom: 5px"
        >
          <van-col span="16">
            <van-row type="flex" align="center">
              <van-image
                width="50px"
                height="50px"
                :src="require('../assets/walk.png')"
              ></van-image>
              <van-row style="padding: 10px">
                <div class="re-title">运动日记</div>
                <div>
                  <span style="display: inline-block; margin-right: 10px"
                    >{{ task.sportsTaskCount }}/1</span
                  >未完成
                </div>
              </van-row>
            </van-row>
          </van-col>
          <van-col span="8" style="text-align: right">
            <van-row>
              <van-image
                width="15px"
                height="15px"
                :src="require('../assets/medals.png')"
              ></van-image
              ><span class="re-num">+1</span>
            </van-row>
            <van-button
              color="#005DFF"
              style="width: 70px; height: 32px"
              size="mini"
              round
              @click="getSportDiary"
              >去记录</van-button
            >
          </van-col>
        </van-row>
        <van-row
          type="flex"
          align="center"
          justify="space-between"
          style="margin-bottom: 5px"
        >
          <van-col span="16">
            <van-row type="flex" align="center">
              <van-image
                width="50px"
                height="50px"
                :src="require('../assets/smokers.png')"
              ></van-image>
              <van-row style="padding: 10px">
                <div class="re-title">吸烟打卡</div>
                <div>
                  <span style="display: inline-block; margin-right: 10px"
                    >{{ task.smokeTaskCount }}/1</span
                  >未完成
                </div>
              </van-row>
            </van-row>
          </van-col>
          <van-col span="8" style="text-align: right">
            <!-- <van-row type="flex" justify="end"> -->
            <van-row>
              <van-image
                width="15px"
                height="15px"
                :src="require('../assets/medals.png')"
              ></van-image
              ><span class="re-num">+1</span>
            </van-row>
            <van-button
              color="#005DFF"
              style="width: 70px; height: 32px"
              size="mini"
              round
              v-if="isSmok"
              @click="getClock('1')"
              >打卡</van-button
            >
            <!-- </van-row> -->
          </van-col>
        </van-row>
        <van-row type="flex" align="center" justify="space-between">
          <van-col span="16">
            <van-row type="flex" align="center">
              <van-image
                width="50px"
                height="50px"
                :src="require('../assets/wine.png')"
              ></van-image>
              <van-row style="padding: 10px">
                <div class="re-title">限酒打卡</div>
                <div>
                  <span style="display: inline-block; margin-right: 10px"
                    >{{ task.wineTaskCount }}/1</span
                  >未完成
                </div>
              </van-row>
            </van-row>
          </van-col>
          <van-col span="8" style="text-align: right">
            <van-row>
              <van-image
                width="15px"
                height="15px"
                :src="require('../assets/medals.png')"
              ></van-image
              ><span class="re-num">+1</span>
            </van-row>
            <van-button
              color="#005DFF"
              style="width: 70px; height: 32px"
              size="mini"
              round
              v-if="isDrink"
              @click="getClock('2')"
              >打卡</van-button
            >
          </van-col>
        </van-row>
      </van-row>
    </van-row>
  </div>
</template>
  
  <script>
import FitNav from "@/components/fitNav.vue";
import { getClockInfo, getClock } from "../api/task";
import { Toast } from "vant";
import { realTimeReport } from "../api/report";

export default {
  name: "task",
  data() {
    return {
      currentRate: 0,
      isSmok: true,
      isDrink: true,
      task: {
        inTakeEnergy: 0,
        jiaIntake: 0,
        needIntake: 0,
        recommend: {},
        taskProgress: 0,
        useUpEnergy: 0,
        wanIntake: 0,
        wuIntake: 0,
        zaoIntake: 0,
        intakeRate: 0,
        energyLevel: 0,
        moreIntake: 0,
        dietTaskCount: 0,
        sportsTaskCount: 0,
        smokeTaskCount: 0,
        wineTaskCount: 0,

      }
    };
  },
  components: {
    FitNav,
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + "%";
    },
    
  },
  mounted(){
    
  },
  created() {
    this.getRealTimeReport();
    this.getClockInfos();
  },
  methods: {
    getRealTimeReport() {
      realTimeReport().then((res) => {
        this.task = res.data
      });
    },
    //获取打卡信息
    getClockInfos() {
      // 获取打卡信息
      getClockInfo().then((res) => {
        console.log(res);
        if(res.data.smoke != null && res.data.smoke.source == "1"){
          this.isSmok = false;
        }
        if(res.data.wine != null && res.data.wine.source == "2"){
          this.isDrink = false;
        }
      });
    },
    //打卡
    getClock(val) {
      let from = {
        source: val,
      };
      getClock(from).then((res) => {
        if (res.code == 200) {
          if (from.source == "1") {
            Toast.success("戒烟打卡成功！");
            this.isSmok = false;
          } else {
            Toast.success("限酒打卡成功！");
            this.isDrink = false;
          }
          this.getRealTimeReport();
          this.getClockInfos();
        } else {
          Toast.fail("打卡失败！");
        }
      });
    },
    //膳食日记记录
    getDiteDiary(){
      this.$router.push({
        path:"/dietDiary"
      })
    },
    //运动日记记录
    getSportDiary(){
      this.$router.push({
        path:"/sportDiary"
      })
    },
    handleDiet() {
  		this.$router.push({
  		  path: "/dietDiary",
  		});
  	},
  	handleSports() {
  		this.$router.push({
  		  path: "/addSwap",
  		});
  	}
  },
};
</script>
  
<style scoped>
* {
  padding: 0;
  margin: 0;
}
.box {
  width: 100%;
  background: url("../assets/taskBck.png") no-repeat;
  background-size: 100%;
  overflow: hidden;
  padding-top: 5.5rem;
}
.main {
  padding: 1rem 10px 1rem 10px;
  background: #dbe3f9;
  border-radius: 32px 32px 0px 2px;
  font-family: PingFang SC ExtraLight;
  line-height: 0.6rem;
}
.m-pace,
.m-kcal,
.m-record {
  padding: 10px 15px;
  background-color: #f3f6fe;
  border-radius: 32px;
}
.m-strip {
  padding: 30px 0px 0px 0px;
}
.m-kcal,
.m-record {
  margin-top: 15px;
  color: #a6a9b3;
}

.re-title {
  color: #4285f4;
  padding: 5px 0px;
  font-size: 0.4rem;
}
.re-num {
  color: black;
  font-size: 0.38rem;
  padding: 0px 15px 5px 5px;
}
.m-dite {
  padding: 10px;
  background: #f3f6fe;
  border-radius: 32px;
  margin-top: 15px;
}
.b-title {
  padding: 5px 10px;
  font-size: 0.35rem;
}
.d-ka {
  padding: 10px;
  background: #f3f6fe;
  /* border-radius: 32px; */
  text-align: center;
}
.d-sum {
  padding: 5px;
  font-size: 1rem;
}
.d-hint {
  padding: 5px 20px;
  background: #ebf0fe;
  border-radius: 28px;
  margin-top: 15px;
}
.col-power1,
.col-power2,
.col-power3,
.col-power4,
.col-power5,
.col-power6,
.col-power7,
.col-power8,
.col-power9 {
  border-radius: 10px;
  padding: 10px 15px;
}
.col-power1 {
  background: url("../assets/cerealBck.png") no-repeat;
  background-size: 100% 100%;
}
.col-power2 {
  background: url("../assets/potatoBck.png") no-repeat;
  background-size: 100% 100%;
}
.col-power3 {
  background: url("../assets/vetBck.png") no-repeat;
  background-size: 100% 100%;
}
.col-power4 {
  background: url("../assets/fruitBck.png") no-repeat;
  background-size: 100% 100%;
}
.col-power5 {
  background: url("../assets/meatBck.png") no-repeat;
  background-size: 100% 100%;
}
.col-power6 {
  background: url("../assets/eggBck.png") no-repeat;
  background-size: 100% 100%;
}
.col-power7 {
  background: url("../assets/nutBck.png") no-repeat;
  background-size: 100% 100%;
}
.col-power8 {
  background: url("../assets/milkBck.png") no-repeat;
  background-size: 100% 100%;
}
.col-power9 {
  background: url("../assets/oilBck.png") no-repeat;
  background-size: 100% 100%;
}
.p-txt {
  font-size: 0.3rem;
  color: #4e4c82;
}
.p-num {
  font-size: 0.28rem;
}
.m-sport {
  padding: 10px;
  background: #ebf0fe;
  border-radius: 28px;
  display: flex;
  /* justify-content: space-between; */
  text-align: left;
}
</style>