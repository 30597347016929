<template>
  <!-- 既往病史问卷 -->
  <div class="box">
    <FitNav title=""></FitNav>
    <van-row class="main">
      <van-row class="m-title">既往病史</van-row>
      <van-row style="padding: 5px"> 1 您目前及曾经是否患有疾病？ </van-row>
      <!-- <van-form ref="form"> -->
        <van-row class="m-form">
          <van-radio-group
            v-model="form.hasDisease"
            @change="getChange"
          >
            <van-radio name="0" shape="square" style="margin-top: 5px"
              >没有健康问题</van-radio
            >
            <van-radio name="1" shape="square" style="margin-top: 5px"
              >有</van-radio
            >
          </van-radio-group>
        </van-row>
		<van-row v-show="isShow">
			<van-row style="padding: 5px"> 2 所患疾病包括： </van-row>
			<van-row class="m-form">
			  <van-checkbox-group
			    v-model="form.jibing"
			    @change="getSelect"
			    direction="horizontal"
			  >
			    <van-checkbox name="1" shape="square" style="margin-top: 5px"
			      >高血压</van-checkbox>
			
			    <van-checkbox name="3" shape="square" style="margin-top: 5px"
			      >2型糖尿病</van-checkbox>
			    <van-checkbox name="4" shape="square" style="margin-top: 5px"
			      >糖尿病肾病</van-checkbox>
			    <van-checkbox name="5" shape="square" style="margin-top: 5px"
			      >冠心病</van-checkbox>
			    <van-checkbox name="6" shape="square" style="margin-top: 5px"
			      >肥胖症</van-checkbox>
			    <van-checkbox name="2" shape="square" style="margin-top: 5px"
			      >1型糖尿病</van-checkbox>
			    <select
			      id="selected"
			      v-model="form.diabetesYear"
			    >
			      <option value="0">无</option>
			      <option value="1">0~9年</option>
			      <option value="2">10~19年</option>
			      <option value="3">20年以上</option>
			    </select>
			    <van-checkbox name="7" shape="square" style="margin-top: 5px"
			      >慢性肾脏病</van-checkbox>
			    <select
			      v-model="form.kidneyStage"
			      style="margin-top: 5px"
			    >
			      <option value="0">无</option>
			      <option value="1">一期</option>
			      <option value="2">二期</option>
			      <option value="3">三期</option>
			      <option value="4">四期</option>
			      <option value="5">五期</option>
			    </select>
			    <van-checkbox name="8" shape="square" style="margin-top: 5px"
			      >慢性阻塞性肺病</van-checkbox>
			    <van-checkbox name="9" shape="square" style="margin-top: 5px"
			      >高尿酸血症</van-checkbox>
			    <van-checkbox name="10" shape="square" style="margin-top: 5px"
			      >其他疾病</van-checkbox>
			  </van-checkbox-group>
			  <van-field v-model="form.other"></van-field
			  >
			</van-row>
		</van-row>
        
        <van-row style="padding: 30px 5px 10px 5px">
          <van-button
            style="border-radius: 16px"
            type="primary"
            size="large"
            color="#005DFF"
            @click="getOver"
            >完成</van-button
          >
        </van-row>
      <!-- </van-form> -->
    </van-row>
  </div>
</template>
  
  <script>
import FitNav from "@/components/fitNav.vue";
import { Toast } from "vant";
import { addQuestionnaire } from "../api/index";
export default {
  name: "smoking",
  components: {
    FitNav,
  },

  data() {
    return {
      form: {
        step: 6,
        hasDisease: "",
        disease: "",
        jibing: [],
        other: "",
        diabetesYear: "",
        kidneyStage: "",
      },
      isShow: false,
    };
  },
  methods: {
    getChange(val) {
      if (val == 1) {
        this.isShow = true;
        this.form.disease = "";
        this.form.other = "";
        this.form.diabetesYear = "";
        this.form.kidneyStage = "";
      } else {
        this.isShow = false;
      }
    },
    getSelect(val) {
      if (val != 2) {
        this.form.diabetesYear = "";
      }
      if (val != 7) {
        this.form.kidneyStage = "";
      }
    },
    getOver() {
      // this.$refs.form
      //   .validate()
      //   .then(() => {
          this.form.disease = this.form.jibing.toString(",");
          addQuestionnaire(this.form).then((res) => {
            if (res.code == 200) {
              Toast.success("提交成功！");
              setTimeout(() => {
                this.$router.push({
              path: "/reports",
              query: {
                title: "ascvdOver",
              },
            });
              }, 1000);
            } else {
              Toast.fail("提交失败！");
            }
          });
        // })
        // .catch(() => {
        //   this.$toast.fail("请填写完整！");
        // });
    },
  },
};
</script>
  
  <style scoped>
  *{
    padding: 0;
    margin: 0;
  }
.box {
  width: 100%;
  overflow: hidden;
  background: url(../assets/bkg.png) no-repeat;
  background-size: 100% 100%;
  padding-top: 2rem;
}
.main {
  padding: 1rem 15px 0rem 15px;
  overflow: hidden;
  background: #dbe3f9;
  border-radius: 32px 32px 0px 2px;
  font-family: PingFang SC ExtraLight;
  /* font-weight: 550; */
  color: #1d1d1d;
  line-height: 0.6rem;
  font-size: 0.4rem;
  min-height: calc(100vh - 3rem);
}
.smok-box {
  overflow: hidden;
  width: 100%;
}
.m-title {
  font-size: 0.6rem;
  padding: 10px 0px 10px 0px;
}
.m-form {
  font-size: 0.35rem;
  border-radius: 10px;
  background-color: #f3f6fe;
  padding: 15px;
  margin: 5px 0px 10px 0px;
}
/deep/ .van-field__body {
  background-color: #f3f6fe;
  border-bottom: 1px #0b9aff solid;
  margin: 10px 5px 0px 5px;
}
/deep/ .van-cell {
  width: 4rem;
  padding: 0px;
  display: inline-block;
  background-color: #f3f6fe;
}
</style>