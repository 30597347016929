<template>
  <!-- 打卡周报 -->
  <div class="rl-box">
    <fit-nav title=""></fit-nav>
    <van-row class="main">
      <div class="date">
        <el-calendar :range="rangeDate">
          <template slot="dateCell" slot-scope="{ data }">
            <div>
              {{ data.day.split("-").slice(2).join("-") }}
            </div>
            <span v-for="(item, index) in calendarData" :key="index">
              <span v-if="data.day == item.day">
                <span v-if="item.status == '1'" class="smok"></span>
                <span v-if="item.status == '2'" class="drink"></span>
              </span>
            </span>
          </template>
        </el-calendar>

        <van-row style="padding-top: 20px" type="flex" justify="space-between">
          <van-col span="12" class="f-smok">
            <van-row class="s-img" @click="Sign('smok')"> </van-row>
            <van-row class="s-txt">
              <div>戒烟<span style="color: #999999">(天)</span></div>
              <div class="s-num">{{ smokedNum }}</div>
            </van-row>
          </van-col>
          <van-col span="12" class="f-smok" @click="Sign('drink')">
            <van-row class="d-img"> </van-row>
            <van-row class="s-txt">
              <div>限酒<span style="color: #999999">(天)</span></div>
              <div class="s-num">{{ drinkedNum }}</div>
            </van-row>
          </van-col>
        </van-row>
      </div>
    </van-row>
  </div>
</template>

<script>
import fitNav from "@/components/fitNav.vue";
import { clockWeeklyReport } from "../api/report";
export default {
  name: "clockMonthly",
  components: {
    fitNav,
  },
  data() {
    return {
      calendarData: [],
      isSmok: false,
      isDrink: false,
      rangeDate: [],
      smokedNum:"",
      drinkedNum:""
    };
  },
  mounted() {
    let id = this.$route.query.id;
    clockWeeklyReport(id).then((res) => {
      this.smokedNum = res.data.smoke.length;
      this.drinkedNum = res.data.wine.length
      for (let i = 0; i < res.data.smoke.length; i++) {
        var time = res.data.smoke[i].createTime;
        time = time.substr(0, 10);
        let from = {
          day: time,
          status: res.data.smoke[i].source,
        };
        this.calendarData.push(from);
      }
      for (let i = 0; i < res.data.wine.length; i++) {
        var time = res.data.wine[i].createTime;
        time = time.substr(0, 10);
        let from = {
          day: time,
          status: res.data.wine[i].source,
        };
        this.calendarData.push(from);
      }
      var startDay = res.data.startTime;
      var endDay = res.data.endTime;

      this.rangeDate.push(startDay, endDay);
    });
   
  },
};
</script>

<style scoped>
*{
    padding: 0;
    margin: 0;
  }
.rl-box {
  width: 100%;
  overflow: hidden;
  height: 100vh;
  background: url("../assets/dkzb.png") no-repeat;
  background-size: 100%;
}
.main {
  margin-top: 5.5rem;
  padding: 1rem 10px 10px 10px;
  background: #dbe3f9;
  border-radius: 32px 32px 0px 2px;
  font-family: PingFang SC ExtraLight;
  line-height: 0.6rem;
  font-size: 0.35rem;
  height: 100%;
}
.date {
  background: #f3f6fe;
  padding-top: 15px;
  border-radius: 32px;
}
/deep/ .el-calendar {
  border-radius: 32px;
  background: #f3f6fe;
}
/deep/ .el-calendar-day {
  height: 50px;
  text-align: center;
}
/deep/ .el-calendar-table tr td {
  border: none;
}
/deep/ .el-calendar-table tr td .el-calendar-day {
  height: 50px;
}
/deep/ .el-calendar-table tr td:first-child {
  border: none;
}
/deep/ .el-calendar-table tr:first-child td {
  border: none;
}
.smok {
  /* color: #C1D0FF; */
  display: inline-block;
  padding: 3px;
  border-radius: 50%;
  background: #c1d0ff;
  margin: 2px;
}
.drink {
  display: inline-block;
  padding: 3px;
  border-radius: 50%;
  background: #eed0a4;
  margin: 2px;
}
.f-smok {
  display: flex;
  padding: 10px;
  justify-content: center;
}
.s-img,
.d-img {
  width: 40px;
  height: 40px;
}
.s-img {
  background: url("../assets/jy.png") no-repeat;
  background-size: 100% 100%;
}
.d-img {
  background: url("../assets/xj.png") no-repeat;
  background-size: 100% 100%;
}
.s-num {
  color: #4285f4;
  font-size: 0.8rem;
  padding: 5px;
}
.s-txt {
  padding-left: 10px;
}
</style>