import Vue from 'vue';
import {
    Button,
    Col,
    Row,
    Image as VanImage,
    Form,
    Field,
    RadioGroup,
    Radio,
    NavBar,
    Icon,
    PasswordInput,
    NumberKeyboard,
    Checkbox,
    CheckboxGroup,
    Collapse,
    CollapseItem,
    Stepper,
    Grid,
    GridItem,
    Calendar,
    Tab,
    Tabs,
    Progress,
    Circle,
    Card,
    Search,
    CountDown,
    DatetimePicker,
    Toast,
    Cell, CellGroup,
    Popup ,
    PullRefresh ,
    List
} from 'vant';

Vue.use(Button);
Vue.use(Col);
Vue.use(Row);
Vue.use(VanImage);
Vue.use(Form);
Vue.use(Field);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Stepper);
Vue.use(GridItem);
Vue.use(Grid);
Vue.use(Calendar);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Progress);
Vue.use(Circle);
Vue.use(Card);
Vue.use(Search);
Vue.use(CountDown);
Vue.use(Toast);
Vue.use(DatetimePicker);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Popup);
Vue.use(PullRefresh);
Vue.use(List);