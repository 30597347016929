<template>
  <!-- 填写个人信息 -->
  <div class="box">
    <!-- logo、图像 -->
    <publicNav :text="['info']"></publicNav>

    <van-row class="b-main">
      <van-row>
        <van-col span="4">
          <div class="m-img"></div>
        </van-col>
        <van-col span="20">
          <div class="m-txt">
            您已开启“企业员工慢病健康管理”服务。接下来，第一步，请完善您的基本信息。
          </div>
        </van-col>
      </van-row>
      <!-- 填写个人表单 -->
      <van-row>
        <van-col span="4">
          <div class="m-img"></div>
        </van-col>
        <van-col span="20">
          <div class="m-txt">
            <van-form label-width="1.5rem" ref="form">
              <van-field
                v-model="userForm.name"
                name="姓名"
                label="姓名"
                placeholder="姓名"
                :rules="[{ required: true, message: '请填写姓名' }]"
              />
              <van-field name="radio" label="性别">
                <template #input>
                  <van-radio-group
                    v-model="userForm.sex"
                    :rules="[{ required: true, message: '请选择性别' }]"
                    direction="horizontal"
                  >
                    <van-radio name="1" style="margin-right: 15px"
                      >男</van-radio
                    >
                    <van-radio name="2">女</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
              <MyDatepicker
                v-model="userForm.birthday"
                label="出生日期"
                :rules="[{ required: true, message: '请填写出生日期' }]"
              ></MyDatepicker>
              <!-- <van-field
                v-model="userForm.birthday"
                name="input"
                label="出生日期"
                placeholder="YYYY-MM-DD"
                :rules="[
                  { required: true, message: '请填写出生日期' },
                  {
                    pattern: /^\d{4}\-\d{2}\-\d{2}$/,
                    message: '出生日期格式错误！',
                  },
                ]"
              /> -->
              <van-field
                v-model="userForm.phone"
                name="input"
                label="手机号"
                placeholder="手机号"
                type="tel"
                :rules="[
                  { required: true, message: '请填写手机号' },
                  {
                    pattern: /^1[3456789]\d{9}$/,
                    message: '手机号码格式错误！',
                  },
                ]"
              />
            </van-form>
            <div class="u-btn">
              <van-button
                round
                size="large"
                native-type="submit"
                type="primary"
                color="#005DFF"
                @click="onSubmit"
                class="b-btn"
                >{{ finishText }}</van-button
              >
            </div>
          </div>
        </van-col>
      </van-row>
    </van-row>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getUserInfos, addUserInfos } from "../api/index";
import publicNav from "@/components/publicNav.vue";
import MyDatepicker from "@/components/MyDatepicker.vue";
export default {
  name: "base-info",
  data() {
    return {
      text: "您已开启“企业员工慢病健康管理”服务。接下来，第一步，请完善您的基本信息。",
      userForm: {
        name: "",
        phone: "",
        birthday: "",
        sex: "1",
      },
      finishText: "完成",
      isActive: false,
    };
  },
  components: { publicNav, MyDatepicker },
  created() {
    this.getInfos();
  },
  methods: {
    //获取个人信息
    getInfos() {
      getUserInfos().then((res) => {
        if (res.code == 200) {
          this.userForm.name = res.data.user.name;
          this.userForm.phone = res.data.user.phone;
          this.userForm.birthday = res.data.user.birthday;
          this.userForm.sex = JSON.stringify(res.data.user.sex);
        }
      });
    },
    //提交个人信息
    onSubmit() {
      this.$refs.form
        .validate()
        .then(() => {
          addUserInfos(this.userForm).then((res) => {
            if (res.code == 200) {
              Toast.success("填写成功！");
              setTimeout(() => {
                this.$router.push({
                  path: "/reports",
                });
              }, 1000);
            } else {
              Toast.fail("提交失败！");
            }
          });
        })
        .catch(() => {
          this.$toast.fail("请填写完整！");
        });
    },
  },
};
</script>

<style scoped lang="less">
* {
  padding: 0;
  margin: 0;
}
.box {
  width: 100%;
  overflow: hidden;
  background: url("../assets/bkg.png") no-repeat;
  background-size: 100%;
  padding: 0;
}
.b-main {
  margin-top: 6.5rem;
  padding: 0.6rem 10px 0.55rem 10px;
  overflow: hidden;
  background-color: #dbe3f9;
  border-radius: 32px 32px 0px 0px;
  font-size: 0.4rem;
  font-family: PingFang SC ExtraLight;
  color: #1d1d1d;
  line-height: 0.6rem;
  min-height: calc(100vh - 10.05rem);
}
.m-img {
  width: 1.2rem;
  height: 1.2rem;
  background: url("../assets/user.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 0.5rem;
}
.m-txt {
  padding: 15px;
  background: #f3f6fe;
  border-radius: 2px 32px 32px 32px;
  margin-top: 1rem;
}
.u-btn {
  padding: 15px 0px 0px 0px;
}
.b-btn {
  height: 1rem;
  font-size: 0.35rem;
}
/deep/ .van-cell {
  background-color: #f3f6fe;
  padding: 5px 0px;
}
/deep/ .van-field__control {
  border-bottom: 1px solid #0b9aff;
}
/deep/ .van-field__control--custom {
  border: none;
}
</style>