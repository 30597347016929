<template>
  <!-- 体格检查 -->
  <div class="q-box">
	  <!-- 导航条 -->
	  <fitNav title="问卷信息"></fitNav>
    <div class="q-main">

    <van-row class="title">体格检查</van-row>
    <van-row class="p-label"> 1 身高 </van-row>
    <van-row type="flex" align="center" class="phy-form">
      <van-stepper min="" v-model="form.height" disabled />
      <div style="padding-left: 10px">厘米（cm）</div>
    </van-row>
    <van-row class="p-label"> 2 体重 </van-row>
    <van-row type="flex" align="center" class="phy-form">
      <van-stepper min="" v-model="form.weight" disabled />
      <div style="padding-left: 10px">公斤（kg）</div>
    </van-row>
    <van-row class="p-label"> 3 腰围 </van-row>
    <van-row type="flex" align="center" class="phy-form">
      <van-stepper min="" v-model="form.waist" disabled />
      <div style="padding-left: 10px">厘米（cm）</div>
    </van-row>
    <van-row class="p-label"> 4 收缩压 </van-row>
    <van-row type="flex" align="center" class="phy-form">
      <van-stepper min="" v-model="form.highPressure" disabled />
      <div style="padding-left: 10px">mmHg</div>
    </van-row>
    <van-row class="p-label"> 5 舒张压 </van-row>
    <van-row type="flex" align="center" class="phy-form">
      <van-stepper min="" v-model="form.lowPressure" disabled/>
      <div style="padding-left: 10px">mmHg</div>
    </van-row>
    <van-row class="title"> 血液生化检查 </van-row>
    <van-row class="p-label"> 1 空腹血糖（GLU） </van-row>
    <van-row type="flex" align="center" class="phy-form">
      <van-stepper min="" step="0.1" v-model="form.bloodGlucose" disabled />
      <div style="padding-left: 10px">mmol/L</div>
    </van-row>
    <van-row class="p-label"> 2 总胆固醇（TC） </van-row>
    <van-row type="flex" align="center" class="phy-form">
      <van-stepper min="" step="0.1" v-model="form.tc" disabled />
      <div style="padding-left: 10px">mmol/L</div>
    </van-row>
    <van-row class="p-label"> 3 甘油三酯（TG） </van-row>
    <van-row type="flex" align="center" class="phy-form">
      <van-stepper min="" step="0.1" v-model="form.tg" disabled/>
      <div style="padding-left: 10px">mmol/L</div>
    </van-row>
    <van-row class="p-label"> 4 高密度脂蛋白胆固醇（HDL） </van-row>
    <van-row type="flex" align="center" class="phy-form">
      <van-stepper min="" step="0.1" v-model="form.hdl" disabled/>
      <div style="padding-left: 10px">mmol/L</div>
    </van-row>
    <van-row class="p-label"> 5 低密度脂蛋白胆固醇（HDL） </van-row>
    <van-row type="flex" align="center" class="phy-form">
      <van-stepper min="" step="0.1" v-model="form.ldl" disabled/>
      <div style="padding-left: 10px">mmol/L</div>
    </van-row>

    <van-row class="m-title">膳食</van-row>
    <van-row style="padding: 5px">
      1
      请回忆您平常的用餐习惯，是否吃过以下食物，并估计该食物的平均食用量和次数.
    </van-row>
    <!-- title -->
    <!-- 精致谷类（精致大米和面粉) -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type"> 精致谷类（精致大米和面粉）： </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group
          v-model="form.refinedGrainCycle"
          direction="horizontal" disabled
        >
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="form.refinedGrainTimes" disabled/>
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="form.refinedGrainEdible" disabled/>
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
    </van-row>
    <!-- 全谷物杂豆（全麦、糙米、小米、玉米、燕麦、红小豆、豌豆等)： -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type">
        全谷物杂豆（全麦、糙米、小米、玉米、燕麦、红小豆、豌豆等）：
      </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="form.wholeGrainCycle"  direction="horizontal" disabled>
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="form.wholeGrainTimes" disabled/>
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="form.wholeGrainEdible" disabled/>
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
    </van-row>
    <!-- 薯类（土豆、红薯、芋头、山药等)： -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type">
        薯类（土豆、红薯、芋头、山药等）：
      </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="form.tubersCycle"   direction="horizontal" disabled>
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="form.tubersTimes" disabled/>
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="form.tubersEdible" disabled/>
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
    </van-row>
    <!-- 新鲜蔬菜： -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type"> 新鲜蔬菜： </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="form.vegetablesCycle"  direction="horizontal" disabled>
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="form.vegetablesTimes" disabled/>
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="form.vegetablesEdible" disabled/>
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
    </van-row>
    <!-- 新鲜水果： -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type"> 新鲜水果： </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="form.fruitCycle"  direction="horizontal" disabled>
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="form.fruitTimes" disabled/>
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="form.fruitEdible" disabled/>
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
    </van-row>
    <!-- 畜禽肉类食物（猪、牛、羊、禽类)： -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type">
        畜禽肉类食物（猪、牛、羊、禽类）：
      </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="form.meatCycle"  direction="horizontal" disabled>
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="form.meatTimes" disabled/>
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="form.meatEdible" disabled/>
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
    </van-row>
    <!-- 水产品（鱼虾)： -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type"> 水产品（鱼虾）： </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="form.aquaticCycle"  direction="horizontal" disabled>
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="form.aquaticTimes" disabled/>
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="form.aquaticEdible" disabled/>
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
    </van-row>
    <!-- 蛋类及制品： -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type"> 蛋类及制品： </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="form.eggsCycle"  direction="horizontal" disabled>
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="form.eggsTimes" disabled />
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="form.eggsEdible" disabled/>
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
    </van-row>
    <!-- 奶及奶制品（鲜奶、奶粉、酸奶、奶酪等）： -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type">
        奶及奶制品（鲜奶、奶粉、酸奶、奶酪等）：
      </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="form.milchigsCycle"  direction="horizontal" disabled>
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="form.milchigsTimes" disabled/>
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="form.milchigsEdible" disabled />
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
      <van-row class="m-btm"> （一袋200毫升纯牛奶约为206克） </van-row>
    </van-row>
    <!-- 大豆及豆制品（黄豆、绿豆、豆腐、豆腐皮等）： -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type">
        大豆及豆制品（黄豆、绿豆、豆腐、豆腐皮等）：
      </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="form.beansCycle"  direction="horizontal" disabled>
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="form.beansTimes" disabled />
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="form.beansEdible" disabled/>
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
    </van-row>
    <!-- 坚果（瓜子仁、杏仁等）： -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type"> 坚果（瓜子仁、杏仁等）： </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="form.nutCycle"  direction="horizontal" disabled>
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="form.nutTimes" disabled/>
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="form.nutEdible" disabled/>
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
    </van-row>

    <!-- 烹调油 -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type"> 烹调油： </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top" type="flex" align="center">
        <div style="padding-right: 10px">每天</div>
        <van-stepper min="" v-model="form.oilEdible" disabled/>
        <div style="padding-left: 10px">勺（家用搪瓷勺）</div>
      </van-row>
    </van-row>
    <!-- 食用盐 -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type"> 食用盐： </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top" type="flex" align="center">
        <div style="padding-right: 10px">每天</div>
        <van-stepper min="" v-model="form.saltEdible" disabled/>
        <div style="padding-left: 10px">茶勺（一小平勺）</div>
      </van-row>
    </van-row>
    <!-- 饮水 -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type">
        饮水（白开水、矿泉水、纯净水)：
      </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top" type="flex" align="center">
        <div style="padding-right: 10px">每天</div>
        <van-stepper min="" v-model="form.waterEdible" disabled />
        <div style="padding-left: 10px">杯（1杯≈240毫升）</div>
      </van-row>
    </van-row>
    <!-- 第二个问题 -->
    <van-row style="padding: 10px 5px 10px 5px">
      2 您更偏爱于哪种饮食口味
    </van-row>
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="form.taste" direction="horizontal" disabled>
          <van-radio name="1" style="margin-right: 12px">清淡</van-radio>
          <van-radio name="2" style="margin-right: 12px">甜</van-radio>
          <van-radio name="3" style="margin-right: 12px">麻辣</van-radio>
          <van-radio name="4" style="margin-right: 12px">咸</van-radio>
          <van-radio name="5">多调料</van-radio>
        </van-radio-group>
      </van-row>
    </van-row>
    <!-- 第三个问题 -->
    <van-row style="padding: 10px 5px 10px 5px">
      3 您有下列哪些饮食习惯（每周3次以上）
    </van-row>
    <van-row class="t-form">
      <van-row class="m-top">
        <van-checkbox-group v-model="form.aihao" direction="horizontal" disabled>
          <van-checkbox name="1" shape="square" style="margin-top: 5px"
            >熏制/腌制类</van-checkbox
          >
          <van-checkbox name="2" shape="square" style="margin-top: 5px"
            >甜点</van-checkbox
          >
          <van-checkbox name="3" shape="square" style="margin-top: 5px"
            >油炸食品</van-checkbox
          >
          <van-checkbox name="4" shape="square" style="margin-top: 5px"
            >肥肉/动物内脏</van-checkbox
          >
          <van-checkbox name="5" shape="square" style="margin-top: 5px"
            >吃零食（适量坚果除外)</van-checkbox
          >
          <van-checkbox name="6" shape="square" style="margin-top: 5px"
            >吃快餐</van-checkbox
          >
          <van-checkbox name="7" shape="square" style="margin-top: 5px"
            >订外卖</van-checkbox
          >
          <van-checkbox name="8" shape="square" style="margin-top: 5px"
            >喝粥（≥2次/天)</van-checkbox
          >
          <van-checkbox name="9" shape="square" style="margin-top: 5px"
            >吃夜宵</van-checkbox
          >
          <van-checkbox name="10" shape="square" style="margin-top: 5px"
            >三餐不规律</van-checkbox
          >
          <van-checkbox name="0" shape="square" style="margin-top: 5px"
            >以上均无</van-checkbox
          >
        </van-checkbox-group>
      </van-row>
    </van-row>
    
    <van-row class="m-title">饮酒</van-row>
    <van-row style="padding: 5px"> 1 您喝酒吗？ </van-row>
    <van-row class="m-form">
      <van-radio-group v-model="form.drink" disabled>
        <van-radio name="1" shape="square" style="margin: 5px 15px 0px 0px"
          >从来不喝</van-radio
        >
        <van-radio name="2" shape="square" style="margin: 5px 15px 0px 0px"
          >以前喝，现在已戒酒</van-radio
        >
        <van-radio name="3" shape="square" style="margin: 5px 15px 0px 0px"
          >每天喝</van-radio
        >
        <van-radio name="4" shape="square" style="margin: 5px 15px 0px 0px"
          >每周喝</van-radio
        >
        <van-radio name="5" shape="square" style="margin: 5px 15px 0px 0px"
          >每月喝</van-radio
        >
      </van-radio-group>
    </van-row>
	<van-row v-show="!isNoDrink">
		<van-row style="padding: 5px"> 2 请选择以下类型的酒及饮酒量(多选) </van-row>
		<van-row class="m-form">
		  <van-row class="f-con" type="flex" align="center">
		    <div style="padding-right: 10px">低度白酒（＞38度)</div>
		    <van-stepper min="" v-model="form.highAlcohol" :disabled="isNoDrink" disabled/>
		    <div style="padding-left: 10px">两</div>
		  </van-row>
		  <van-row class="f-con" type="flex" align="center"
		    ><div style="padding-right: 10px">高度白酒（≤38度)</div>
		    <van-stepper min="" v-model="form.lowAlcohol" :disabled="isNoDrink" disabled/>
		    <div style="padding-left: 10px">两</div>
		  </van-row>
		  <van-row class="f-con" type="flex" align="center"
		    ><div style="padding-right: 10px">啤 酒</div>
		    <van-stepper min="" v-model="form.beer" :disabled="isNoDrink" disabled/>
		    <div style="padding-left: 10px">毫升</div>
		  </van-row>
		  <van-row class="f-con" type="flex" align="center"
		    ><div style="padding-right: 10px">葡萄酒或黄酒</div>
		    <van-stepper min="" v-model="form.wine" :disabled="isNoDrink" disabled/>
		    <div style="padding-left: 10px">毫升</div>
		  </van-row>
		</van-row>
	</van-row>
    
  <van-row class="m-title">吸烟</van-row>
    <van-row style="padding: 5px"> 1 近30天内您吸烟吗？ </van-row>
    <van-row class="m-form">
      <van-radio-group v-model="form.smoking" disabled>
        <van-radio name="1" shape="square" style="margin: 5px 15px 0px 0px"
          >是</van-radio
        >
        <van-radio name="0" shape="square" style="margin: 5px 15px 0px 0px"
          >否</van-radio
        >
      </van-radio-group>
    </van-row>
	<van-row v-show="form.smoking != '' && form.smoking == 0">
		<van-row style="padding: 5px">2 是否已戒烟</van-row>
		<van-row class="m-form">
		  <van-radio-group v-model="form.quitSmoking" disabled>
		    <van-radio name="1" shape="square" style="margin: 5px 15px 0px 0px"
		      >是
		    </van-radio>
		    <van-radio name="0" shape="square" style="margin: 5px 15px 0px 0px"
		      >否</van-radio
		    >
		  </van-radio-group>
		</van-row>
	</van-row>
	
	<van-row v-show="form.quitSmoking != '' && form.quitSmoking == 1">
		<van-row style="padding: 5px"> 3 您的戒烟年龄？ </van-row>
		<van-row class="m-form">
		  <van-row type="flex" align="center">
		    <van-stepper min="" v-model="form.quitSmokingAge" disabled/>
		    <div style="padding-left: 10px">岁 )</div>
		  </van-row>
		</van-row>
	</van-row>
	
	<van-row v-show="(form.smoking != '' && form.smoking == 1) || (form.quitSmoking != '' && form.quitSmoking == 0)">
		<van-row style="padding: 5px"> {{ smokeCountNum }} 您平均每天抽多少支香烟？ </van-row>
		<van-row class="m-form">
		  <van-row type="flex" align="center">
		    <van-stepper min="" v-model="form.smokeCount" disabled/>
		    <div style="padding-left: 10px">支</div>
		  </van-row>
		</van-row>
	</van-row>
    
	<van-row v-show="(form.smoking != '' && form.smoking == 1) || (form.quitSmoking != '' && form.quitSmoking == 0)">
		<van-row style="padding: 5px"> {{ smokeYearNum }} 您已经吸烟多少年了？ </van-row>
		<van-row class="m-form">
		  <van-row type="flex" align="center">
		    <van-stepper v-model="form.smokeYear" min="" disabled />
		    <div style="padding-left: 10px">年</div>
		  </van-row>
		</van-row>
	</van-row>
    
    <van-row style="padding: 5px">
      {{ aroundSmokeNum }} 和您一起工作的同事或一起生活的家人中是否有人经常吸烟？
    </van-row>
    <van-row class="m-form">
      <van-radio-group v-model="form.aroundSmoke" direction="horizontal" disabled>
        <van-radio name="1" shape="square" style="margin-right: 15px"
          >是</van-radio
        >
        <van-radio name="0" shape="square">否</van-radio>
      </van-radio-group>
    </van-row>
    
    <van-row class="m-title">身体活动</van-row>
    <!-- 第一个问题 -->
    <van-row style="padding: 5px">
      1 请您回忆过去一周，累积做了多少分钟的中等强度有氧身体活动？
      <span class="t-des">
        （有氧身体活动是指躯干、四肢等大肌肉群参与为主的、有节律、时间较长、能够维持在一个稳定状态的身体活动，如跑步、快走、游泳、滑冰、跳绳、骑车、跳舞、打篮球、踢足球等。中等强度身体活动是指感觉有点累，心跳和呼吸都加快，轻微出汗，可以轻松说话的身体活动）
      </span>
    </van-row>
    <van-row class="m-form">
      <van-row type="flex" align="center">
        <van-stepper min="" v-model="form.moderateSweat" disabled />
        <div style="padding-left: 10px">分钟</div>
      </van-row>
    </van-row>
    <!-- 第二个问题 -->
    <van-row style="padding: 5px">
      2 过去一周，您累计做了多少分钟的高强度有氧身体活动？
      <span class="t-des">
        高强度身体活动是指我感觉很累，全身出了很多汗，说话较费力或停止运动才能说话，呼吸急促，大口喘气，心跳很快的身体活动）
      </span>
    </van-row>
    <van-row class="m-form">
      <van-row type="flex" align="center">
        <van-stepper min="" v-model="form.highSweat" disabled/>
        <div style="padding-left: 10px">分钟</div>
      </van-row>
    </van-row>
    <!-- 第三个问题 -->
    <van-row style="padding: 5px">
      3 过去一周，您主动锻炼累计多长时间？
      <span class="t-des"> （主动锻炼是指以锻炼健身为目的的身体活动） </span>
    </van-row>
    <van-row class="m-form">
      <van-row type="flex" align="center">
        <van-stepper min="" v-model="form.totalTime" disabled/>
        <div style="padding-left: 10px">分钟</div>
      </van-row>
    </van-row>
    <!-- 第四个问题 -->
    <van-row style="padding: 5px">
      4 过去一周，您最主要的出行方式是什么？
    </van-row>
    <van-row class="m-form">
      <van-row style="margin-bottom: 5px">
        <div>A 交通方式</div>
        <van-radio-group v-model="form.travelMode" direction="horizontal" disabled>
          <van-radio name="1" shape="square" style="margin: 5px 15px 0px 0px"
            >走路</van-radio
          >
          <van-radio name="2" shape="square" style="margin: 5px 15px 0px 0px"
            >骑自行车</van-radio
          >
          <van-radio name="3" shape="square" style="margin: 5px 15px 0px 0px"
            >坐车</van-radio
          >
          <van-radio name="4" shape="square" style="margin: 5px 15px 0px 0px"
            >开车</van-radio
          >
        </van-radio-group>
      </van-row>
      <van-row style="margin-bottom: 5px">
        <div>B 强度</div>
        <van-radio-group v-model="form.travelIntensity" direction="horizontal" disabled>
          <van-radio name="1" shape="square" style="margin: 5px 15px 0px 0px"
            >低强度</van-radio
          >
          <van-radio name="2" shape="square" style="margin: 5px 15px 0px 0px"
            >中等强度</van-radio
          >
          <van-radio name="3" shape="square" style="margin: 5px 15px 0px 0px"
            >高强度</van-radio
          >
          <van-radio name="4" shape="square" style="margin: 5px 15px 0px 0px"
            >静态行为（坐着、靠着、躺着，不包括睡觉）</van-radio
          >
        </van-radio-group>
      </van-row>
      <van-row>
        <div>C 累计持续时间</div>
        <van-row type="flex" align="center">
          <van-stepper v-model="form.travelTime" disabled/>
          <div style="padding-left: 10px">分钟</div>
        </van-row>
      </van-row>
    </van-row>
    <!-- 后四个问题 -->
    <van-row style="padding: 5px">
      5 过去一周，您累计总静态行为时间是多少小时？
      <span class="t-des">
        （坐着、靠着或躺着做的活动，包括工作时间、闲暇时间的静态行为）
      </span>
    </van-row>
    <van-row class="m-form">
      <van-row type="flex" align="center">
        <van-stepper min="" v-model="form.staticTotalTime" disabled/>
        <div style="padding-left: 10px">小时</div>
      </van-row>
    </van-row>
    <van-row style="padding: 5px">
      6 过去一周，您累计闲暇静态行为时间是多少小时？
      <span class="t-des">
        （闲暇时间坐着、靠着或躺着做的活动，不包括工作时间的静态行为）
      </span>
    </van-row>
    <van-row class="m-form">
      <van-row type="flex" align="center">
        <van-stepper min="" v-model="form.staticLeisureTime" disabled/>
        <div style="padding-left: 10px">小时</div>
      </van-row>
    </van-row>
    <van-row style="padding: 5px">
      7 过去一周，您累计视屏时间是多少小时？
      <span class="t-des"> （如：看电视/手机/电脑/平板电脑等） </span>
    </van-row>
    <van-row class="m-form">
      <van-row type="flex" align="center">
        <van-stepper min="" v-model="form.screenTime" disabled/>
        <div style="padding-left: 10px">小时</div>
      </van-row>
    </van-row>
    <van-row style="padding: 5px">
      8 过去一周，您累计睡眠时间是多少小时？
      <span class="t-des"> （不包括在床上没有睡着的时间） </span>
    </van-row>
    <van-row class="m-form">
      <van-row type="flex" align="center">
        <van-stepper min="" v-model="form.bedtime" disabled />
        <div style="padding-left: 10px">小时</div>
      </van-row>
    </van-row>

    <van-row class="m-title">既往病史</van-row>
      <van-row style="padding: 5px"> 1 您目前及曾经是否患有疾病？ </van-row>
      <!-- <van-form ref="form"> -->
        <van-row class="m-form">
          <van-radio-group
            v-model="form.hasDisease" disabled
          >
            <van-radio name="0" shape="square" style="margin-top: 5px"
              >没有健康问题</van-radio
            >
            <van-radio name="1" shape="square" style="margin-top: 5px"
              >有</van-radio
            >
          </van-radio-group>
        </van-row>
		<van-row v-show="isShowDisease">
			<van-row style="padding: 5px"> 2 所患疾病包括： </van-row>
			<van-row class="m-form">
			  <van-checkbox-group
			    v-model="form.disease"
			    direction="horizontal"
          disabled
			  >
        <van-checkbox name="1" shape="square" style="margin-top: 5px"
            >高血压</van-checkbox
          >
      
          <van-checkbox name="3" shape="square" style="margin-top: 5px"
            >2型糖尿病</van-checkbox
          >
          <van-checkbox name="4" shape="square" style="margin-top: 5px"
            >糖尿病肾病</van-checkbox
          >
          <van-checkbox name="5" shape="square" style="margin-top: 5px"
            >冠心病</van-checkbox
          >
          <van-checkbox name="6" shape="square" style="margin-top: 5px"
            >肥胖症</van-checkbox
          >
          <van-checkbox name="2" shape="square" style="margin-top: 5px"
            >1型糖尿病</van-checkbox
          >
          <select
            id="selected"
            v-model="form.diabetesYear"
            disabled
          >
            <option value="0">无</option>
            <option value="1">0~9年</option>
            <option value="2">10~19年</option>
            <option value="3">20年以上</option>
          </select>
          <van-checkbox name="7" shape="square" style="margin-top: 5px"
            >慢性肾脏病</van-checkbox
          >
          <select
            v-model="form.kidneyStage"
            style="margin-top: 5px"
            disabled
          >
            <option value="0">无</option>
            <option value="1">一期</option>
            <option value="2">二期</option>
            <option value="3">三期</option>
            <option value="4">四期</option>
            <option value="5">五期</option>
          </select>
          <van-checkbox name="8" shape="square" style="margin-top: 5px"
            >慢性阻塞性肺病</van-checkbox
          >
          <van-checkbox name="9" shape="square" style="margin-top: 5px"
            >高尿酸血症</van-checkbox
          >
          <van-checkbox name="10" shape="square" style="margin-top: 5px"
            >其他疾病</van-checkbox
          >
			  </van-checkbox-group>
			  <van-field v-model="form.other"></van-field
			  >
			</van-row>
		</van-row>

    </div>
  </div>
</template>

<script>
import fitNav from "../components/fitNav.vue";
import { getQuestionDetail } from "../api/index";
import { ref } from 'vue';

export default {
  name: "physique-info",
  data() {
    return {
      form: {
        step:1,
        height: "170",
        weight: "60",
        waist: "80",
        highPressure: "120",
        lowPressure: "80",
        bloodGlucose: "5.0",
        tc: "5.0",
        tg: "1.5",
        hdl: "1.5",
        ldl: "2.6",
        refinedGrainCycle: "",
        refinedGrainTimes: "",
        refinedGrainEdible: "",
        wholeGrainCycle: "",
        wholeGrainTimes: "",
        wholeGrainEdible: "",
        tubersCycle: "",
        tubersTimes: "",
        tubersEdible: "",
        vegetablesCycle: "",
        vegetablesTimes: "",
        vegetablesEdible: "",
        fruitCycle: "",
        fruitTimes: "",
        fruitEdible: "",
        meatCycle: "",
        meatTimes: "",
        meatEdible: "",
        aquaticCycle: "",
        aquaticTimes: "",
        aquaticEdible: "",
        eggsCycle: "",
        eggsTimes: "",
        eggsEdible: "",
        milchigsCycle: "",
        milchigsTimes: "",
        milchigsEdible: "",
        beansCycle: "",
        beansTimes: "",
        beansEdible: "",
        nutCycle: "",
        nutTimes: "",
        nutEdible: "",
        oilEdible: "",
        saltEdible: "",
        waterEdible: "",
        taste: "",
        habits: "",
        aihao:[],
        drink: "",
        highAlcohol: "",
        lowAlcohol: "",
        beer: "",
        wine: "",
        smoking: "",
        quitSmoking: "",
        quitSmokingAge: "",
        smokeCount: "",
        smokeYear: "",
        aroundSmoke: "",
        moderateSweat: "",
        highSweat: "",
        totalTime: "",
        travelMode: "",
        travelIntensity: "",
        travelTime: "",
        staticTotalTime: "",
        staticLeisureTime: "",
        screenTime: "",
        bedtime: "",
        hasDisease: "",
        disease: [],
        other: "",
        diabetesYear: "",
        kidneyStage: "",
      },
      smokeCountNum: 0,
	    smokeYearNum: 0,
	    aroundSmokeNum: 2,
      isNoDrink:true,
      isShowDisease: false,
    };
  },
  components: {
    fitNav,
  },
  created() {
    let recordId = this.$route.query.recordId;
    this.getDetail(recordId);
  },
  methods: {
    getSports(value) {
      // console.log("0000",value);
      this.$emit("getSports", value);
    },

    getDrink(value){
      this.$emit('getDrink',value);
    },
    getDetail(id) {
      getQuestionDetail(id).then((res) => {
        this.form = res.data
        this.form.refinedGrainCycle = this.form.refinedGrainCycle.toString()
        this.form.wholeGrainCycle = this.form.wholeGrainCycle.toString()
        this.form.tubersCycle = this.form.tubersCycle.toString()
        this.form.vegetablesCycle = this.form.vegetablesCycle.toString()
        this.form.fruitCycle = this.form.fruitCycle.toString()
        this.form.meatCycle = this.form.meatCycle.toString()
        this.form.aquaticCycle = this.form.aquaticCycle.toString()
        this.form.eggsCycle = this.form.eggsCycle.toString()
        this.form.milchigsCycle = this.form.milchigsCycle.toString()
        this.form.beansCycle = this.form.beansCycle.toString()
        this.form.nutCycle = this.form.nutCycle.toString()
        this.form.taste = this.form.taste.toString()
        this.form.aihao = this.form.habits.split(",")
        
        if (this.form.drink == 1 || this.form.drink == 2) {
          this.isNoDrink = true;
        } else {
          this.isNoDrink = false;
        }
        this.form.drink = this.form.drink.toString()
        if (this.form.smoking == 1) {
		      this.smokeCountNum = 2;
		      this.smokeYearNum = 3;
		      this.aroundSmokeNum = 4;
        } else if(this.form.smoking == 0) {
		      this.aroundSmokeNum = 3
        }
        if (this.form.quitSmoking == 1) {
			    this.aroundSmokeNum = 4
		    } else if(this.form.quitSmoking == 0) {
			    this.smokeCountNum = 3;
			    this.smokeYearNum = 4;
			    this.aroundSmokeNum = 5;
			    this.form.smokeCount="1",
			    this.form.smokeYear = "1";
		    }
        this.form.smoking = this.form.smoking.toString()
        this.form.quitSmoking = this.form.quitSmoking.toString()
        this.form.aroundSmoke = this.form.aroundSmoke.toString()
        this.form.travelMode = this.form.travelMode.toString()
        this.form.travelIntensity = this.form.travelIntensity.toString()
        if (this.form.hasDisease == 1) {
          this.isShowDisease = true;
        } else {
          this.isShowDisease = false;
        }
        this.form.hasDisease = this.form.hasDisease.toString()
        this.form.disease = this.form.disease.split(",")
        this.form.diabetesYear = this.form.diabetesYear.toString()
        this.form.kidneyStage = this.form.kidneyStage.toString()
      })
    }
  },
};
</script>

<style scoped>
*{
    padding: 0;
    margin: 0;
  }
.q-box {
  width: 100%;
  overflow: hidden;
  background: url("../assets/bkg.png") no-repeat;
  background-size: 100%;
}
.q-main {
  padding: 20px 15px 15px 15px;
  overflow: hidden;
  background: #dbe3f9;
  border-radius: 32px 32px 0px 2px;
  font-family: PingFang SC ExtraLight;
  color: #1d1d1d;
  line-height: 0.7rem;
  font-size: 0.4rem;
  margin-top: 2.5rem;
}
.title {
  font-size: 0.5rem;
  padding: 10px 0px 10px 0px;
}
.phy-form {
  padding: 10px;
  background-color: #f3f6fe;
  border-radius: 10px;
  font-size: 0.35rem;
  /* margin-bottom: 10px; */
}
.p-label {
  padding: 10px 0px 10px 0px;
}

.m-title {
  font-size: 0.6rem;
  padding: 10px 0px 10px 0px;
}
.m-round {
  height: 0.1rem;
  width: 0.1rem;
  border: 2px solid #f43140;
  border-radius: 50%;
  display: inline-block;
  line-height: 0.4rem;
}
.m-type {
  display: inline-block;
  line-height: 0.6rem;
  margin-left: 0.25rem;
}
.t-form {
  background-color: #ecf0fc;
  border-radius: 10px;
  overflow: hidden;
  font-size: 0.35rem;
}

.m-top {
  background-color: #f3f6fe;
  padding: 15px 10px 15px 15px;
}
.m-btm {
  padding: 10px;
  text-align: center;
  color: #666666;
  font-size: 0.3rem;
}

.m-form {
  font-size: 0.35rem;
  border-radius: 10px;
  background-color: #f3f6fe;
  padding: 15px;
  margin: 5px 0px 10px 0px;
}
.t-des {
  color: #666666;
  font-size: 0.4rem;
  font-family: PingFang SC;
  font-weight: 400;
}
.f-con{
  padding-top: 5px;
}

/deep/ .van-stepper__input {
  background-color: #f3f6fe;
  border-bottom: 1px #0b9aff solid;
  margin: 0px 5px 0px 5px;
  width: 80px;
  color: #1D1D1D;
  height: 20px;
}
/deep/ .van-stepper__minus{
  background-color: #0B9AFF;
  color: white;
  height: 20px;
  width: 20px;
}
/deep/ .van-stepper__plus{
  background-color: #0B9AFF;
  color: white;
  height: 20px;
  width: 20px;
}

:deep(.van-cell) {
  width: 4rem;
  display: inline-block;
  background-color: #f3f6fe;
  border-bottom: 1px solid black;
}
</style>