<template>
  <!-- 生活方式问卷 -->
  <div class="box">
    <!-- 导航条 -->
    <fitNav :title="title"></fitNav>
    <!-- 主题 -->
    <div class="main">
      <!-- 膳食 -->
      <lifeDiet v-if="isDiet" @getDrink="getNextPage"></lifeDiet>
      <!-- 喝酒 -->
      <drinking v-if="isDrink" @getSmok="getNextPage"></drinking>
      <!-- 吸烟 -->
      <smoking v-if="isSmok" @getSports="getNextPage"></smoking>
      <!-- 身体运动 -->
      <bodySports v-if="isSports"></bodySports>
      
    </div>
  </div>
</template>

<script>
import fitNav from "../components/fitNav.vue"
import lifeDiet from '../components/life-diet.vue'
import bodySports from '../components/body-sports.vue'
import drinking from '../components/drinking.vue'
import smoking from '../components/smoking.vue'
export default {
  data() {
    return {
      title:"生活方式问卷",
      isDiet:true,
      isSports:false,
      isDrink:false,
      isSmok:false
    };
  },
  components:{ 
    fitNav,
    lifeDiet,
    bodySports,
    drinking,
    smoking
  },
  methods: {
    getNextPage(msg){
      if(msg == "sports"){
        // this.isDiet = false;
        this.isSports = true;
        this.isSmok = false;
      }else if(msg == "drink"){
        this.isDiet = false;
        this.isSports = false;
        this.isDrink = true;
      }else if(msg == 'smok'){
        this.isDiet = false;
        this.isDrink = false;
        this.isSports = false;
        this.isSmok = true;
      }
    }
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
.box {
  width: 100%;
  overflow: hidden;
  background: url(../assets/bkg.png) no-repeat;
  background-size: 100% 100%;
  padding-top: 2rem;
}
.main {
  padding: 1rem 15px 1rem 15px;
  overflow: hidden;
  background: #dbe3f9;
  border-radius: 32px 32px 0px 2px;
  font-family: PingFang SC ExtraLight;
  /* font-weight: 550; */
  color: #1d1d1d;
  line-height: 0.6rem;
  font-size: 0.4rem;
  min-height: calc(100vh - 4rem);
}

</style>