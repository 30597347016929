<template>
  <!-- 健康科普 -->
  <div class="box">
    <fitNav title="健康科普"></fitNav>
    <div class="main">
      <!-- <van-row type="flex">
        <van-search v-model="searchParam" placeholder="请输入搜索关键词" />
        <div class="m-title" @click="getQuery">搜索</div>
      </van-row> -->
      <van-row>
        <van-tabs
          v-model="active"
          title-active-color="#4285F4"
          @click="handleTabs"
        >
          <van-tab
            :title="item.name"
            v-for="(item, index) in tabs"
            :key="index"
          >
            <van-row
              class="card"
              type="flex"
              align="center"
              justify="space-between"
              v-for="(list, index) in data"
              :key="index"
              @click="getInfos(list.id)"
            >
              <van-col span="10">
                <van-image
                  style="min-height: 100px"
                  width="100%"
                  :src="list.imgUrl"
                >
                </van-image>
              </van-col>
              <van-col span="13" class="c-con">
                <van-row class="c-title"> {{ list.title }} </van-row>
                <van-row class="c-time">
                  <van-row type="flex" align="center">
                    <van-icon
                      :name="require('../assets/eye.png')"
                      size="18px"
                      style="margin-left: 10px"
                    ></van-icon
                    ><span class="c-num">{{ list.browseNumber }}</span>
                  </van-row>
                  <van-row> {{ list.createTime }} </van-row>
                </van-row>
              </van-col>
            </van-row>
          </van-tab>
        </van-tabs>
      </van-row>
    </div>
  </div>
</template>

<script>
import fitNav from "@/components/fitNav.vue";
import { getKepuType, getKepu } from "../api/kepu";
import { Toast } from 'vant'
export default {
  components: { fitNav },
  name: "fitKepu",
  data() {
    return {
      searchParam: "",
      active: "1",
      tabs: [],
      data: [],
      activeId: "",
    };
  },
  mounted() {
    //获取tabs
    getKepuType().then((res) => {
      if(res.code == 200){
        this.tabs = res.data;
      if (this.active == "1") {
        this.activeId = this.tabs[0].id;
        this.getQuery();
      }
      }else { 
        Toast.fail('查询失败');
      }
    });
  },
  methods: {
    //切换tabs
    handleTabs(name, title) {
      for (var i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i].name == title) {
          this.activeId = this.tabs[i].id;
        }
      }
      this.getQuery();
    },
    //搜索
    getQuery() {
      let data = {
        type: this.activeId,
        searchParam: this.searchParam,
      };
      getKepu(data).then((res) => {
        if(res.code == 200){
          this.data = res.data;
        }else { 
          Toast.fail('查询失败');
        }
        
      });
    },
    //查看详情
    getInfos(id) {
      this.$router.push({
        path: "/kepuDetail",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
.box {
  background: url("../assets/kepuBck.png") no-repeat;
  background-size: 100%;
  padding-top: 5.5rem;
}
.main {
  padding: 1rem 10px 1rem 10px;
  background: #dbe3f9;
  border-radius: 32px 32px 0px 2px;
  min-height: calc(100vh - 7.5rem);
  line-height: 0.6rem;
}
/deep/ .van-search {
  background: #dbe3f9;
}
/deep/ .van-search__content {
  border-radius: 30px;
  background-color: #d1d9f1;
}
.m-title {
  color: #4285f4;
  font-size: 0.4rem;
  padding-right: 10px;
}
/deep/ .van-tabs__nav {
  background-color: #dbe3f9;
}
/deep/ .van-tabs__line{
  background-color: #4285F4;
}
.card {
  padding: 10px;
  background: #f3f6fe;
  border-radius: 32px;
  margin-top: 10px;
}
/deep/ .van-image__img {
  border-radius: 32px;
}
.c-con {
  padding: 5px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.c-title {
  font-family: PingFang SC;
  color: #1d1d1d;
  font-size: 0.4rem;
  margin-bottom: 10px;
}
.c-time {
  font-family: PingFang SC ExtraLight;
  color: #a6a9b3;
}
.c-num {
  display: inline-block;
  padding: 0px 10px 0px 5px;
}
</style>