import request from "../utils/request"

// 用户打卡
export function getClock(data) {
    return request({
      url: '/web/punchCard',
      method: 'post',
      data:data
    })
  }

  //查询今日打卡信息
  export function getClockInfo() {
    return request({
      url: '/web/punchCard/today',
      method: 'get',
    })
  }

  
  
