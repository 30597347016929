<template>
  <!-- 膳食月报 -->
  <div class="box">
    <fitNav title=""></fitNav>
    <van-row v-if="noData"> 无用户记录 </van-row>
    <van-row v-else>
      <van-row class="main">
        <van-row style="padding: 10px" type="flex" align="center">
          <div>干得好,您在一月中记录了{{ monthly.recordDay }}天</div>
          <!-- <div style="padding-left: 10px">
          <van-button
            color="#005DFF"
            size="mini"
            round
            style="width: 60px; height: 30px"
            >详情</van-button
          >
        </div> -->
        </van-row>

        <van-row class="m-block" type="flex" justify="space-between">
          <van-col span="10" class="b-item">
            <!-- 日能量目标 -->
            <van-row type="flex" style="padding: 5px 0px">
              <van-row
                type="flex"
                justify="center"
                align="center"
                style="padding: 0px 10px"
              >
                <van-image
                  width="30px"
                  height="30px"
                  :src="require('../assets/flag.png')"
                ></van-image>
              </van-row>
              <van-row>
                <div style="color: #005dff; font-size: 20px">
                  {{ monthly.energyLevel }}
                </div>
                <div style="font-size: 12px; color: #666">日能量目标</div>
              </van-row>
            </van-row>

            <van-row type="flex" style="padding: 5px 0px">
              <van-row
                type="flex"
                justify="center"
                align="center"
                style="padding: 0px 10px"
              >
                <van-image
                  width="30px"
                  height="30px"
                  :src="require('../assets/knife.png')"
                ></van-image>
              </van-row>
              <van-row>
                <div style="color: #005dff; font-size: 20px">
                  {{ monthly.avgIntake }}
                </div>
                <div style="font-size: 12px; color: #666">本月日均：千卡</div>
              </van-row>
            </van-row>
            <!-- <van-row type="flex" style="padding: 5px 0px"> -->
              <!-- <van-row
              type="flex"
              justify="center"
              align="center"
              style="padding: 0px 10px"
            >
              <van-image
                width="30px"
                height="30px"
                :src="require('../assets/salt.png')"
              ></van-image>
            </van-row>
             <van-row>
              <div style="color: #005dff; font-size: 20px">1200</div>
              <div style="font-size: 12px; color: #666">本月日均：千卡</div>
            </van-row>-->
            <!-- </van-row> -->
          </van-col>
          <!-- 散点图 -->
          <van-col span="13" style="color: #ebf0fe">
            <div id="scatter"></div>
          </van-col>
        </van-row>
        <!-- 日均摄入比推荐目标 -->
        <van-row class="m-block">
          <van-row type="flex" align="center">
            <van-image
              width="18"
              height="18"
              :src="require('../assets/pace.png')"
            ></van-image>
            <div class="b-title">日均摄入比推荐目标</div>
            <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
          </van-row>
          <van-row
            type="flex"
            align="center"
            justify="center"
            style="padding: 10px 0px"
          >
            <van-circle
              v-model="currentRate"
              :rate="monthly.intakeRate"
              :stroke-width="100"
              :text="monthly.intakeMemo"
              :color="gradientColor"
            />
          </van-row>
          <van-row
            type="flex"
            align="center"
            style="padding: 10px; font-size: 13px"
          >
            <!-- <div>本周体重变化：0.5公斤</div>
          <div class="d-detail">详情<van-icon name="arrow" size="12px" /></div> -->
          </van-row>
        </van-row>
        <!-- 膳食宝塔 -->
        <van-row class="m-block">
          <!-- 膳食宝塔结构 -->
          <van-row type="flex" align="center">
            <van-image
              width="18px"
              height="18px"
              :src="require('../assets/steps.png')"
            ></van-image>
            <div class="b-title">膳食宝塔结构</div>
            <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
          </van-row>

          <van-row class="col-title"> 膳食宝塔对比图 </van-row>
          <van-row type="flex" align="center" justify="space-between">
            <div>推荐摄入量</div>
            <div>我的摄入量</div>
          </van-row>
          <van-row class="pagoda">
            <van-row class="border"></van-row>
            <van-row style="position: absolute; top: 0; width: 100%">
              <!-- 盐/油 -->
              <van-row type="flex">
                <van-col span="8" style="position: relative">
                  <div>5克</div>
                  <div>25~30克</div>
                </van-col>
                <van-col span="8" style="position: relative; overflow: hidden">
                  <van-row
                    :class="[
                      mealTower?.P5?.level == 'L1'
                        ? 'saltLess'
                        : mealTower?.P5?.level == 'L2'
                        ? 'saltJust'
                        : 'saltBig',
                    ]"
                  ></van-row>
                  <van-row style="position: relative">
                    <van-row type="flex" align="center" justify="center">
                      <van-image
                        width="18px"
                        height="18px"
                        :src="require('../assets/salt.png')"
                      ></van-image>
                      <div style="font-size: 12px">盐</div>
                    </van-row>
                    <van-row type="flex" align="center" justify="center">
                      <van-image
                        width="18px"
                        height="18px"
                        :src="require('../assets/oil.png')"
                      ></van-image>
                      <div style="font-size: 12px">油</div>
                    </van-row>
                  </van-row>
                </van-col>
                <van-col
                  span="8"
                  type="flex"
                  align="right"
                  style="position: relative"
                >
                  <div>{{ mealTower?.salt?.daily }}克</div>
                  <div>{{ mealTower?.oil?.daily }}克</div>
                </van-col>
              </van-row>
              <!-- 奶/大豆 -->
              <van-row class="milkTop">
                <van-row type="flex">
                  <van-col span="8">
                    <div>300~500克</div>
                    <div>25~35克</div>
                  </van-col>
                  <van-col span="8" style="position: relative">
                    <van-row
                      :class="[
                        mealTower?.P4?.level == 'L1'
                          ? 'milkLess'
                          : mealTower?.P4?.level == 'L2'
                          ? 'milkJust'
                          : 'milkBig',
                      ]"
                    ></van-row>
                    <van-row style="position: relative">
                      <van-row type="flex" align="center" justify="center">
                        <van-image
                          width="18px"
                          height="18px"
                          :src="require('../assets/milk.png')"
                        ></van-image>
                        <div style="font-size: 10px">奶</div>
                      </van-row>
                      <van-row type="flex" align="center" justify="center">
                        <van-image
                          width="18px"
                          height="18px"
                          :src="require('../assets/nut.png')"
                        ></van-image>
                        <div style="font-size: 10px">大豆坚果</div>
                      </van-row>
                    </van-row>
                  </van-col>
                  <van-col
                    span="8"
                    type="flex"
                    align="right"
                    style="position: relative"
                  >
                    <div>{{ mealTower?.milk?.daily }}克</div>
                    <div>{{ mealTower?.beannut?.daily }}克</div>
                  </van-col>
                </van-row>
              </van-row>
              <!-- 肉蛋 -->
              <van-row class="meatTop">
                <van-row
                  type="flex"
                  align="center"
                  justify="space-between"
                  style="position: relative"
                >
                  <van-col span="8"> 120~200克</van-col>
                  <van-col span="8" style="position: relative">
                    <van-row
                      :class="[
                        mealTower?.P3?.level == 'L1'
                          ? 'meatLess'
                          : mealTower?.P3?.level == 'L2'
                          ? 'meatJust'
                          : 'meatBig',
                      ]"
                    ></van-row>
                    <van-row style="position: relative">
                      <van-row
                        type="flex"
                        align="center"
                        justify="center"
                        class="meat"
                      >
                        <van-image
                          width="18px"
                          height="18px"
                          :src="require('../assets/meat.png')"
                        ></van-image>
                        <div style="font-size: 12px">肉蛋</div>
                      </van-row>
                    </van-row>
                  </van-col>
                  <van-col
                    span="8"
                    style="text-align: right; position: relative"
                    >{{ mealTower?.meat?.daily }}克</van-col
                  >
                </van-row>
              </van-row>
              <!-- 蔬菜水果 -->
              <van-row class="vetTop">
                <van-row style="position: relative">
                  <van-row
                    :class="[
                      mealTower?.P2?.level == 'L1'
                        ? 'fruitLess'
                        : mealTower?.P2?.level == 'L2'
                        ? 'fruitJust'
                        : 'fruitBig',
                    ]"
                  >
                  </van-row>
                  <van-row style="position: relative">
                    <van-col span="8">
                      <div>300~500克</div>
                      <div>200~350克</div>
                    </van-col>
                    <van-col span="8">
                      <van-row
                        type="flex"
                        align="center"
                        justify="center"
                        style="position: relative"
                      >
                        <van-image
                          width="18px"
                          height="18px"
                          :src="require('../assets/vegetable.png')"
                        ></van-image>
                        <div style="font-size: 10px">蔬菜</div>
                      </van-row>
                      <van-row type="flex" align="center" justify="center">
                        <van-image
                          width="18px"
                          height="18px"
                          :src="require('../assets/fruit.png')"
                        ></van-image>
                        <div style="font-size: 10px">水果</div>
                      </van-row>
                    </van-col>
                    <van-col span="8" type="flex" align="right">
                      <div>{{ mealTower?.vegetables?.daily }}克</div>
                      <div>{{ mealTower?.fruit?.daily }}克</div>
                    </van-col>
                  </van-row>
                </van-row>
              </van-row>
              <!-- 谷类/薯类/ -->
              <van-row class="cerealTop">
                <van-row style="position: relative">
                  <van-row
                    :class="[
                      mealTower?.P1?.level == 'L1'
                        ? 'cerealLess'
                        : mealTower?.P1?.level == 'L2'
                        ? 'cerealJust'
                        : 'cerealBig',
                    ]"
                  >
                  </van-row>
                  <van-row style="position: relative">
                    <van-col span="8" style="position: relative">
                      <div>200~300克</div>
                      <div>50~150克</div>
                    </van-col>
                    <van-col span="8">
                      <van-row type="flex" align="center" justify="center">
                        <van-image
                          width="18px"
                          height="18px"
                          :src="require('../assets/cereal.png')"
                        ></van-image>
                        <div style="font-size: 12px">谷类</div>
                      </van-row>
                      <van-row type="flex" align="center" justify="center">
                        <van-image
                          width="18px"
                          height="18px"
                          :src="require('../assets/potato.png')"
                        ></van-image>
                        <div style="font-size: 12px">薯类</div>
                      </van-row>
                    </van-col>
                    <van-col
                      span="8"
                      type="flex"
                      align="right"
                      style="position: relative"
                    >
                      <div style="position: relative">
                        {{ mealTower?.grain?.daily }}克
                      </div>
                      <div style="position: relative">
                        {{ mealTower?.tubers?.daily }}克
                      </div>
                    </van-col>
                  </van-row>
                </van-row>
              </van-row>
              <!-- 水 -->
              <van-row class="waterTop">
                <van-row style="position: relative">
                  <van-row
                    :class="[
                      mealTower?.P0?.level == 'L1'
                        ? 'waterLess'
                        : mealTower?.P0?.level == 'L2'
                        ? 'waterJust'
                        : 'waterBig',
                    ]"
                  ></van-row>
                  <van-row type="flex" align="center" justify="space-between">
                    <van-col span="10" style="position: relative">
                      1500~1700毫升</van-col
                    >
                    <van-col span="6">
                      <van-row
                        type="flex"
                        align="center"
                        justify="center"
                        class="water"
                        style="position: relative"
                      >
                        <van-image
                          width="18px"
                          height="18px"
                          :src="require('../assets/water.png')"
                        ></van-image>
                        <div style="font-size: 12px">水</div>
                      </van-row>
                    </van-col>
                    <van-col
                      span="8"
                      style="text-align: right; position: relative"
                      >{{ mealTower?.water?.daily }}毫升</van-col
                    >
                  </van-row>
                </van-row>
              </van-row>
            </van-row>
          </van-row>
          <van-row type="flex" align="center" justify="space-around">
            <van-col span="6">
              <van-row type="flex" align="center" justify="space-around">
                <div class="col-rount" style="background: #abe9e7"></div>
                <div class="rount-txt">标准推荐</div>
              </van-row>
            </van-col>
            <van-col span="6">
              <van-row type="flex" align="center" justify="space-around">
                <div class="col-rount" style="background: #f9ccab"></div>
                <div class="rount-txt">摄入量多</div>
              </van-row>
            </van-col>
            <van-col span="6">
              <van-row type="flex" align="center" justify="space-around">
                <div class="col-rount" style="background: #ede2b5"></div>
                <div class="rount-txt">摄入量少</div>
              </van-row></van-col
            >
          </van-row>
          <van-row class="col-explain">
            上图是根据您提供的膳食信息模拟的膳食结构图，
            与中国营养学会推荐的中国居民平衡膳食宝塔
            （2022版）结构的对照图。膳食宝塔共包括六层
            食物和食品，根据图示您可以清楚的看出自己的 差距.
          </van-row>
        </van-row>
        <!-- 下月膳食建议 -->
        <van-row class="m-block">
          <van-row type="flex" align="center">
            <van-image
              width="18px"
              height="18px"
              :src="require('../assets/steps.png')"
            ></van-image>
            <div class="b-title">下月膳食建议</div>
            <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
          </van-row>
          <!-- 需改善 -->
          <van-row
            style="margin-bottom: 5px"
            :gutter="2"
            type="flex"
            justify="space-between"
            align="center"
          >
            <van-col span="4" style="width: 1.5rem">
              <van-row class="col-warn">
                <div>需改善</div>
                <div>
                  <van-image
                    width="14px"
                    height="14px"
                    :src="require('../assets/warn.png')"
                  ></van-image>
                </div>
              </van-row>
            </van-col>
            <van-col span="20" style="height: auto; flex: 1">
              <van-grid :gutter="2" direction="horizontal">
                <van-grid-item
                  v-for="(item, index) in needImprove"
                  :key="'i' + index"
                  :icon="item.icon"
                  :text="item.memo"
                  class="v-grid"
                />
              </van-grid>
            </van-col>
          </van-row>

          <!-- 需保持 -->
          <van-row
            :gutter="2"
            type="flex"
            align="center"
            justify="space-between"
          >
            <van-col span="4" style="width: 1.5rem">
              <van-row class="col-keep">
                <div>需保持</div>
                <div>
                  <van-image
                    width="14px"
                    height="14px"
                    :src="require('../assets/try.png')"
                  ></van-image>
                </div>
              </van-row>
            </van-col>
            <van-col span="20" style="height: auto; flex: 1">
              <van-grid :gutter="2" direction="horizontal">
                <van-grid-item
                  v-for="(item, index) in needKeep"
                  :key="'k' + index"
                  :icon="item.icon"
                  :text="item.memo"
                  class="v-grid"
                />
              </van-grid>
            </van-col>
          </van-row>
        </van-row>
      </van-row>
    </van-row>
  </div>
</template>

<script>
import fitNav from "@/components/fitNav.vue";
import * as echarts from "echarts";
import { dietMonthlyReport } from "../api/report";
var myChart;

export default {
  name: "dietMonthly",
  components: { fitNav },
  data() {
    return {
      currentRate: 0,
      gradientColor: {
        "50%": "#FBA55B",
        "100%": "#FFC88D",
      },
      monthly: {
        intakeRate: 0,
        standardDay: 0,
        avgIntake: 0,
        recordDay: 0,
        sumIntake: 0,
        intakeMemo: "",
        energyLevel: 0,
      },
      //需要改善
      needImproveVisivle: false,
      //需要保持
      needKeepVisible: false,
      //需要改善
      needImprove: [],
      //需要保持
      needKeep: [],
      mealTower: {},
      noData: false,
    };
  },
  mounted() {
    myChart = echarts.init(document.getElementById("scatter"));
  },
  created() {
    let id = this.$route.query.id;
    this.getDietMonthlyReport(id);
  },
  methods: {
    getDietMonthlyReport(id) {
      dietMonthlyReport(id).then((res) => {
        this.monthly = res.data;
        if (JSON.stringify(this.monthly) == "{}") {
          this.noData = true;
        }
        this.needImprove = this.monthly.needImprove;
        this.needKeep = this.monthly.needKeep;
        this.mealTower = this.monthly.mealTower;
        this.addIcon(this.needImprove);
        this.addIcon(this.needKeep);
        myChart.setOption({
          xAxis: {},
          yAxis: {},
          series: [
            {
              type: "scatter",
              data: this.monthly.chartData,
            },
          ],
        });
      });
    },
    addIcon(arr) {
      for (var item of arr) {
        if (item["type"] == "grain") {
          item["icon"] = require("../assets/cereal.png");
        } else if (item["type"] == "tubers") {
          item["icon"] = require("../assets/potato.png");
        } else if (item["type"] == "vegetables") {
          item["icon"] = require("../assets/vegetable.png");
        } else if (item["type"] == "fruit") {
          item["icon"] = require("../assets/fruit.png");
        } else if (item["type"] == "meat") {
          item["icon"] = require("../assets/meat.png");
        } else if (item["type"] == "aquatic") {
          item["icon"] = require("../assets/aquatic.png");
        } else if (item["type"] == "eggs") {
          item["icon"] = require("../assets/eggs.png");
        } else if (item["type"] == "milk") {
          item["icon"] = require("../assets/milk.png");
        } else if (item["type"] == "beans") {
          item["icon"] = require("../assets/beans.png");
        } else if (item["type"] == "nut") {
          item["icon"] = require("../assets/nut.png");
        } else if (item["type"] == "oil") {
          item["icon"] = require("../assets/oil.png");
        } else if (item["type"] == "salt") {
          item["icon"] = require("../assets/salt.png");
        } else if (item["type"] == "water") {
          item["icon"] = require("../assets/water.png");
        } else if (item["type"] == "taste") {
          item["icon"] = require("../assets/taste.png");
        } else if (item["type"] == "drink") {
          item["icon"] = require("../assets/drinkIcon.png");
        } else if (item["type"] == "habits") {
          item["icon"] = require("../assets/habits.png");
        }
      }
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
.box {
  width: 100%;
  overflow: hidden;
  background: url(../assets/dietMonthly.png) no-repeat;
  background-size: 100%;
  padding-top: 5.5rem;
}
.main {
  padding: 10px;
  background: #dbe3f9;
  border-radius: 32px 32px 0px 2px;
  font-family: PingFang SC ExtraLight;
  /* line-height: 0.6rem; */
}
.m-block {
  background-color: #f3f6fe;
  border-radius: 28px;
  padding: 10px;
  margin-bottom: 15px;
}
.b-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#scatter {
  height: 5rem;
  width: 5rem;
}
.b-title {
  padding: 5px 10px;
  font-size: 0.35rem;
  margin-bottom: 5px;
}
.d-detail {
  padding-left: 10px;
  color: #005dff;
}
.col-title {
  padding: 8px;
  background-color: #ebf0fe;
  border-radius: 30px;
  color: #4285f4;
  font-size: 0.35rem;
  text-align: center;
  margin: 10px 0px;
}
.pagoda {
  margin-top: 10px;
  position: relative;
}
.saltLess {
  width: 0;
  height: 0;
  border-left: 0.4rem solid transparent;
  border-right: 0.4rem solid transparent;
  border-bottom: 0.8rem solid #ede2b5;
  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -0.4rem;
}
.saltJust {
  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-bottom: 0.8rem solid #abe9e7;
  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -0.5rem;
}
.saltBig {
  width: 0;
  height: 0;
  border-left: 0.9rem solid transparent;
  border-right: 0.9rem solid transparent;
  border-bottom: 0.85rem solid #f9ccab;
  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -0.9rem;
}
.milkLess {
  width: 0.8rem;
  height: 0;
  border-width: 0 0.4rem 0.8rem 0.4rem;
  border-style: none solid solid;
  border-color: transparent transparent #ede2b5;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -0.8rem;
}
.milkJust {
  width: 1.2rem;
  height: 0;
  border-width: 0 0.6rem 1rem 0.6rem;
  border-style: none solid solid;
  border-color: transparent transparent #abe9e7;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1.2rem;
}
.milkBig {
  width: 1.8rem;
  height: 0;
  border-width: 0 0.8rem 1rem 0.8rem;
  border-style: none solid solid;
  border-color: transparent transparent #f9ccab;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1.7rem;
}
.meatLess {
  width: 1.6rem;
  height: 0;
  border-width: 0 0.2rem 0.6rem 0.2rem;
  border-style: none solid solid;
  border-color: transparent transparent #ede2b5;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1rem;
}
.meatJust {
  width: 2.4rem;
  height: 0;
  border-width: 0 0.4rem 0.6rem 0.4rem;
  border-style: none solid solid;
  border-color: transparent transparent #abe9e7;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1.6rem;
}
.meatBig {
  width: 3.5rem;
  height: 0;
  border-width: 0 0.4rem 0.6rem 0.4rem;
  border-style: none solid solid;
  border-color: transparent transparent #f9ccab;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2.2rem;
}
.fruitLess {
  width: 2rem;
  height: 0;
  border-width: 0 0.4rem 0.8rem 0.4rem;
  border-style: none solid solid;
  border-color: transparent transparent #ede2b5;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1.4rem;
}
.fruitJust {
  width: 3.35rem;
  height: 0;
  border-width: 0 0.55rem 0.8rem 0.5rem;
  border-style: none solid solid;
  border-color: transparent transparent #abe9e7;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2.2rem;
}
.fruitBig {
  width: 4.4rem;
  height: 0;
  border-width: 0 0.65rem 0.9rem 0.65rem;
  border-style: none solid solid;
  border-color: transparent transparent #f9ccab;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2.8rem;
}
.cerealLess {
  width: 2.8rem;
  height: 0;
  border-width: 0 0.4rem 0.8rem 0.4rem;
  border-style: none solid solid;
  border-color: transparent transparent #ede2b5;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1.8rem;
}
.cerealJust {
  width: 4.4rem;
  height: 0;
  border-width: 0 0.6rem 0.8rem 0.6rem;
  border-style: none solid solid;
  border-color: transparent transparent #abe9e7;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2.8rem;
}
.cerealBig {
  width: 5.7rem;
  height: 0;
  border-width: 0 0.6rem 0.85rem 0.6rem;
  border-style: none solid solid;
  border-color: transparent transparent #f9ccab;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -3.4rem;
}
.waterLess {
  width: 3.8rem;
  height: 0;
  border-width: 0 0.3rem 0.4rem 0.3rem;
  border-style: none solid solid;
  border-color: transparent transparent #ede2b5;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2.2rem;
}
.waterJust {
  width: 5.5rem;
  height: 0;
  border-width: 0 0.3rem 0.4rem 0.3rem;
  border-style: none solid solid;
  border-color: transparent transparent #abe9e7;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -3.4rem;
}
.waterBig {
  width: 7rem;
  height: 0;
  border-width: 0 0.3rem 0.4rem 0.3rem;
  border-style: none solid solid;
  border-color: transparent transparent #f9ccab;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -3.8rem;
}

.border {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 3.1rem 5.1rem;
  border-color: transparent transparent #abe9e7;
  position: relative;
  margin: 0 auto;
}
.border:after {
  content: "";
  border-style: solid;
  border-width: 0 3rem 5rem;
  border-color: transparent transparent #f3f6fe;
  position: absolute;
  top: 0.05rem;
  left: -3rem;
}
.milkTop {
  position: absolute;
  top: 1rem;
  width: 100%;
}
.meatTop {
  position: absolute;
  top: 2.05rem;
  width: 100%;
}
.vetTop {
  position: absolute;
  top: 2.7rem;
  width: 100%;
}
.cerealTop {
  position: absolute;
  top: 3.7rem;
  width: 100%;
  overflow-y: hidden;
}
.waterTop {
  width: 100%;
  position: absolute;
  top: 4.65rem;
  overflow-y: hidden;
}
.col-explain {
  background: #ebf0fe;
  border-radius: 32px;
  padding: 15px;
  font-size: 0.35rem;
  color: #1d1d1d;
  line-height: 0.6rem;
}
.col-warn,
.col-keep {
  font-size: 0.35rem;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 1.5rem;
}
.col-warn {
  background: #f7f1e6;
  color: #a3552d;
}
.col-keep {
  background: #e4ebf7;
  color: #4f6070;
}
.v-grid {
  min-width: 2.45rem;
}
</style>