import request from "../utils/request"

// 慢病报告
export function basicReport(id) {
    return request({
      url: '/web/report/basic/' + id,
      headers: {
        isToken: false
      },
      method: 'get',
    })
}

// 生活方式报告
export function lifeReport(id) {
    return request({
      url: '/web/report/life/' + id,
      headers: {
        isToken: false
      },
      method: 'get',
    })
}

// ascvd报告
export function ascvdReport(id) {
    return request({
      url: '/web/report/ascvd/' + id,
      headers: {
        isToken: false
      },
      method: 'get',
    })
}

export function dailyReport(id) {
  return request({
    url: '/web/report/dailyReport/' + id,
    headers: {
      isToken: false
    },
    method: 'get',
  })
}

export function cycleReportList(data) {
  return request({
    url: '/web/report/cycleReportList',
    params: data,
    headers: {
      isToken: false
    },
    method: 'get',
  })
}

export function realTimeReport() {
  return request({
    url: '/web/report/realTimeReport',
    headers: {
      isToken: false
    },
    method: 'get',
  })
}

export function dietWeeklyReport(id) {
  return request({
    url: '/web/report/dietWeeklyReport/' + id,
    headers: {
      isToken: false
    },
    method: 'get',
  })
}


export function dietMonthlyReport(id) {
  return request({
    url: '/web/report/dietMonthlyReport/' + id,
    headers: {
      isToken: false
    },
    method: 'get',
  })
}

export function sportsWeeklyReport(id) {
  return request({
    url: '/web/report/sportsWeeklyReport/' + id,
    headers: {
      isToken: false
    },
    method: 'get',
  })
}

export function sportsMonthlyReport(id) {
  return request({
    url: '/web/report/sportsMonthlyReport/' + id,
    headers: {
      isToken: false
    },
    method: 'get',
  })
}

export function clockWeeklyReport(id) {
  return request({
    url: '/web/report/clockWeeklyReport/' + id,
    headers: {
      isToken: false
    },
    method: 'get',
  })
}

export function clockMonthlyReport(id) {
  return request({
    url: '/web/report/clockMonthlyReport/' + id,
    headers: {
      isToken: false
    },
    method: 'get',
  })
}