var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('publicNav',{attrs:{"text":['info']}}),_c('van-row',{staticClass:"b-main"},[_c('van-row',[_c('van-col',{attrs:{"span":"4"}},[_c('div',{staticClass:"m-img"})]),_c('van-col',{attrs:{"span":"20"}},[_c('div',{staticClass:"m-txt"},[_vm._v(" 您已开启“企业员工慢病健康管理”服务。接下来，第一步，请完善您的基本信息。 ")])])],1),_c('van-row',[_c('van-col',{attrs:{"span":"4"}},[_c('div',{staticClass:"m-img"})]),_c('van-col',{attrs:{"span":"20"}},[_c('div',{staticClass:"m-txt"},[_c('van-form',{ref:"form",attrs:{"label-width":"1.5rem"}},[_c('van-field',{attrs:{"name":"姓名","label":"姓名","placeholder":"姓名","rules":[{ required: true, message: '请填写姓名' }]},model:{value:(_vm.userForm.name),callback:function ($$v) {_vm.$set(_vm.userForm, "name", $$v)},expression:"userForm.name"}}),_c('van-field',{attrs:{"name":"radio","label":"性别"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-radio-group',{attrs:{"rules":[{ required: true, message: '请选择性别' }],"direction":"horizontal"},model:{value:(_vm.userForm.sex),callback:function ($$v) {_vm.$set(_vm.userForm, "sex", $$v)},expression:"userForm.sex"}},[_c('van-radio',{staticStyle:{"margin-right":"15px"},attrs:{"name":"1"}},[_vm._v("男")]),_c('van-radio',{attrs:{"name":"2"}},[_vm._v("女")])],1)]},proxy:true}])}),_c('MyDatepicker',{attrs:{"label":"出生日期","rules":[{ required: true, message: '请填写出生日期' }]},model:{value:(_vm.userForm.birthday),callback:function ($$v) {_vm.$set(_vm.userForm, "birthday", $$v)},expression:"userForm.birthday"}}),_c('van-field',{attrs:{"name":"input","label":"手机号","placeholder":"手机号","type":"tel","rules":[
                { required: true, message: '请填写手机号' },
                {
                  pattern: /^1[3456789]\d{9}$/,
                  message: '手机号码格式错误！',
                },
              ]},model:{value:(_vm.userForm.phone),callback:function ($$v) {_vm.$set(_vm.userForm, "phone", $$v)},expression:"userForm.phone"}})],1),_c('div',{staticClass:"u-btn"},[_c('van-button',{staticClass:"b-btn",attrs:{"round":"","size":"large","native-type":"submit","type":"primary","color":"#005DFF"},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.finishText))])],1)],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }