<template>
  <!-- 知情同意书 -->
  <div class="info-box">
    <van-row v-for="(item, index) in infos" :key="index">
      <van-col span="4">
        <div class="m-img"></div>
      </van-col>
      <van-col span="20">
        <div class="m-txt">
          {{ item.text }}
        </div>
      </van-col>
    </van-row>
    <van-row>
      <van-col span="4">
        <div class="m-img"></div>
      </van-col>
      <van-col span="20">
        <div class="m-txt">
          <van-row class="t-title"> “企业员工慢病健康管理”健康服务 </van-row>
          <van-row class="t-title"> 知情同意书 </van-row>
          <van-row class="t-con">
            欢迎您加入上海君石生命科学研究院（以
            下简称SJLSI）的“企业员工慢病健康管理“ ”服务项目。 一、项目介绍
            通过在线数字人进行生活方式指导，以健
            康体重为核心，以膳食和身体活动干预为
            抓手，聚焦主要慢性病危险因素（血压、
            血脂、血糖、超重和肥胖），并对这些危
            险因素进行科学管理的项目。每个人都有
            自己的健康问题，有些人吸烟，有些
            人肥胖，有些人血压高，有些人饮食不平衡，有
            些人有其他健康问题等等。但是上述慢性病的发
            生非常隐匿，在早期没有明显症状，临床医生很
            难做出诊断，个人也不可能主动治疗或采取预防
            措施；或者有些人已经诊断为高血压、糖尿病，
            饱受慢病的折磨，但又不知道从何入手有效进行
            疾病的治疗。“企业员工慢病健康管理”服务将通
            过专业化的健康风险指标评估技术，对您的当前
            健康状况进行分析评价，对相关危险因素进行预
            警，以个人健康需求为先导，通过建立一个良好
            的支持环境，使您更有效的控制高血压、糖尿病
            等慢性病，预防或减缓并发症的发生、发展。 二、服务阶段
            拥有长期健康管理服务经验的SJLSI员工（执业
            健康管理师），将针对您本次健康检测出来的B
            MI超标（超重肥胖）、体脂率超标（隐性肥胖）
            等健康问题，量身定制出有利于减脂增肌，全面
            提升营养水平和心肺耐力的《膳食营养处方》
            《运动锻炼处方》。并通过“准备、适应、达标、
            保持”四个阶段的生活方式及行为指导，促进您
            的以下健康指标达到理想范围，远离慢性病的危害.
          </van-row>
          <van-row type="flex" justify="space-between" algin="center" style="padding: 10px;">
            <van-button @click="handlDisAgree('noagree')" class="btn" round color="#005DFF" plain size="normal"
              >不同意</van-button
            >
            <van-button @click="handleAgree('agree')" class="btn" round color="#005DFF" size="normal"
              >同意</van-button
            >
          </van-row>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "info-Consent",
  data() {
    return {
      infos: [
        {
          id: "1",
          text: "亲爱的小伙伴，欢迎来到上海君石生命科学研究院的“企业员工慢病健康管理”服务项目！我们的目标是帮助您管理主要慢性病危险因素，包括血压、血脂、血糖、超重和肥胖。通过提供个性化的生活方式指导，以健康体重为核心，特别关注膳食和身体活动，帮助您科学管理这些危险因素。",
        },
        {
          id:"2",
          text:"我叫爱思小助，在这里，我会为您提供专业的健康管理服务帮助，远离慢性病从改变生活方式开始，让您拥有更健康的生活！ "
        },
        {
          id: "3",
          text: "由于本项目的特殊性质，患有进行性疾病、活动障碍、严重的精神疾病、重度肝病或肾病、恶性肿瘤、年龄18岁以下、60岁以上及孕产妇的个人，不能参加本项目，详情请参阅《知情同意书》中列出的不适宜参加本项目的疾病。我深切地关心您的健康状况，希望您能选择适合自身的健康计划。",
        },
        {
          id: "4",
          text: "您可以阅读下面的知情同意书，了解项目详情：",
        },
      ],
    };
  },
  methods:{ 
    handleAgree(){
        this.$router.push({
          path:"/login"
        })
    },
    handlDisAgree(){
      this.$router.push({
          path:"/fitKepu"
        })
    }
  }
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
.info-box {
  width: 100%;
  overflow: hidden;
}
.m-img {
  width: 1.2rem;
  height: 1.2rem;
  background: url("../assets/user.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 0.5rem;
}
.m-txt {
  padding: 15px;
  background: #f3f6fe;
  border-radius: 2px 32px 32px 32px;
  margin-top: 1rem;
}
.t-title {
  padding: 0px 10px 0px 10px;
  text-align: center;
  font-size: 0.4rem;
  font-family: PingFang SC;
  /* font-weight: 550;/ */
  color: #005dff;
}
.t-con {
  height: 5rem;
  overflow-y: scroll;
  padding: 10px;
  font-size: 0.2rem;
  font-family: PingFang SC ExtraLight;
  /* font-weight: 400; */
  color: #1d1d1d;
}
.btn {
  width: 3rem;
}
</style>