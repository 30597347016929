import { render, staticRenderFns } from "./cardiovascular.vue?vue&type=template&id=ee35c5c2&scoped=true&"
import script from "./cardiovascular.vue?vue&type=script&lang=js&"
export * from "./cardiovascular.vue?vue&type=script&lang=js&"
import style0 from "./cardiovascular.vue?vue&type=style&index=0&id=ee35c5c2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee35c5c2",
  null
  
)

export default component.exports