<template>
  <div class="nav-box">
    <van-nav-bar :title="title" fixed left-arrow @click-left="onClickLeft" />
  </div>
</template>

<script>
export default {
  name: "fitNav",
  props: ["title"],
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="less">
* {
  padding: 0;
  margin: 0;
}
.nav-box {
  font-size: 0.6rem;
  font-family: PingFang SC ExtraLight;
  font-weight: 500;
  width: 100%;
  background-color: #dbe3f9;
}
/deep/ .van-icon {
  color: black;
}
/deep/ .van-nav-bar__arrow {
  font-size: 20px;
}
</style>