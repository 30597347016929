import request from "../utils/request"

//添加个人信息
export function addUserInfos(data) {
    return request({
      url: '/web/member/saveInfo',
      method: 'post',
      data: data
    })
  }
  //获取用户信息
  export function getUserInfos() {
    return request({
      url: '/web/getInfo',
      method: 'get',
    })
  }

  //修改个人信息
  export function updateUserInfos(data) {
    return request({
      url: '/web/member/saveInfo',
      method: 'post',
      data: data
    })
  }

  //添加问卷
  export function addQuestionnaire(data) {
    return request({
      url: '/web/questionnaire/commit',
      method: 'post',
      data: data
    })
  }
  
  //问卷记录
  export function getQuestionList() {
    return request({
      url: '/web/questionnaire/list',
      method: 'get'
    })
  }
  
  //问卷详情
  export function getQuestionDetail(id) {
    return request({
      url: '/web/questionnaire/detail/' + id,
      method: 'get'
    })
  }
