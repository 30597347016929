<template>
  <div class="l-box">
    <!-- 导航条 -->
    <fitNav :title="title"></fitNav>
    <van-row class="l-main">
      <!-- 我的膳食评估 -->
      <van-row class="m-title">
        我的膳食评估
        <van-icon name="fire-o" color="#4285F4"></van-icon>
      </van-row>
      <van-row class="m-col">
        <!-- 膳食宝塔结构 -->
        <van-row type="flex" align="center">
          <van-image
            width="18px"
            height="18px"
            :src="require('../assets/steps.png')"
          ></van-image>
          <div class="life-title">膳食宝塔结构</div>
          <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
        </van-row>

        <van-row class="col-title"> 中国居民平衡膳食宝塔 </van-row>
        <van-row type="flex" align="center" justify="space-between">
          <div>推荐摄入量</div>
          <div>我的摄入量</div>
        </van-row>
        <van-row class="pagoda">
          <van-row class="border"></van-row>
          <van-row style="position: absolute; top: 0; width: 100%">
            <!-- 盐/油 -->
            <van-row type="flex" align="center">
              <van-col span="8" style="position: relative">
                <div>5克</div>
                <div>25~30克</div>
              </van-col>
              <van-col span="8" style="position: relative; overflow: hidden">
                <van-row
                  :class="[
                    mealTower?.P5?.level == 'L1'
                      ? 'saltLess'
                      : mealTower?.P5?.level == 'L2'
                      ? 'saltJust'
                      : 'saltBig',
                  ]"
                ></van-row>
                <van-row style="position: relative">
                  <van-row type="flex" align="center" justify="center">
                    <van-image
                      width="18px"
                      height="18px"
                      :src="require('../assets/salt.png')"
                    ></van-image>
                    <div style="font-size: 12px">盐</div>
                  </van-row>
                  <van-row type="flex" align="center" justify="center">
                    <van-image
                      width="18px"
                      height="18px"
                      :src="require('../assets/oil.png')"
                    ></van-image>
                    <div style="font-size: 12px">油</div>
                  </van-row>
                </van-row>
              </van-col>
              <van-col
                span="8"
                type="flex"
                align="right"
                style="position: relative"
              >
                <div>{{ mealTower?.salt?.daily }}克</div>
                <div>{{ mealTower?.oil?.daily }}克</div>
              </van-col>
            </van-row>
            <!-- 奶/大豆 -->
            <van-row class="milkTop">
              <van-row type="flex">
                <van-col span="8">
                  <div>300~500克</div>
                  <div>25~35克</div>
                </van-col>
                <van-col span="8" style="position: relative">
                  <van-row
                    :class="[
                      mealTower?.P4?.level == 'L1'
                        ? 'milkLess'
                        : mealTower?.P4?.level == 'L2'
                        ? 'milkJust'
                        : 'milkBig',
                    ]"
                  ></van-row>
                  <van-row style="position: relative">
                    <van-row type="flex" align="center" justify="center">
                      <van-image
                        width="18px"
                        height="18px"
                        :src="require('../assets/milk.png')"
                      ></van-image>
                      <div style="font-size: 10px">奶</div>
                    </van-row>
                    <van-row type="flex" align="center" justify="center">
                      <van-image
                        width="18px"
                        height="18px"
                        :src="require('../assets/nut.png')"
                      ></van-image>
                      <div style="font-size: 10px">大豆坚果</div>
                    </van-row>
                  </van-row>
                </van-col>
                <van-col
                  span="8"
                  type="flex"
                  align="right"
                  style="position: relative"
                >
                  <div>{{ mealTower?.milk?.daily }}克</div>
                  <div>{{ mealTower?.beannut?.daily }}克</div>
                </van-col>
              </van-row>
            </van-row>
            <!-- 肉蛋 -->
            <van-row class="meatTop">
              <van-row
                type="flex"
                align="center"
                justify="space-between"
                style="position: relative"
              >
                <van-col span="8"> 120~200克</van-col>
                <van-col span="8" style="position: relative">
                  <van-row
                    :class="[
                      mealTower?.P3?.level == 'L1'
                        ? 'meatLess'
                        : mealTower?.P3?.level == 'L2'
                        ? 'meatJust'
                        : 'meatBig',
                    ]"
                  ></van-row>
                  <van-row style="position: relative">
                    <van-row
                      type="flex"
                      align="center"
                      justify="center"
                      class="meat"
                    >
                      <van-image
                        width="18px"
                        height="18px"
                        :src="require('../assets/meat.png')"
                      ></van-image>
                      <div style="font-size: 12px">肉蛋</div>
                    </van-row>
                  </van-row>
                </van-col>
                <van-col span="8" style="text-align: right; position: relative"
                  >{{ mealTower?.meat?.daily }}克</van-col
                >
              </van-row>
            </van-row>
            <!-- 蔬菜水果 -->
            <van-row class="vetTop">
              <van-row style="position: relative">
                <van-row
                  :class="[
                    mealTower?.P2?.level == 'L1'
                      ? 'fruitLess'
                      : mealTower?.P2?.level == 'L2'
                      ? 'fruitJust'
                      : 'fruitBig',
                  ]"
                >
                </van-row>
                <van-row style="position: relative">
                  <van-col span="8">
                    <div>300~500克</div>
                    <div>200~350克</div>
                  </van-col>
                  <van-col span="8">
                    <van-row type="flex" align="center" justify="center">
                      <van-image
                        width="18px"
                        height="18px"
                        :src="require('../assets/vegetable.png')"
                      ></van-image>
                      <div style="font-size: 10px">蔬菜</div>
                    </van-row>
                    <van-row type="flex" align="center" justify="center">
                      <van-image
                        width="18px"
                        height="18px"
                        :src="require('../assets/fruit.png')"
                      ></van-image>
                      <div style="font-size: 10px">水果</div>
                    </van-row>
                  </van-col>
                  <van-col span="8" type="flex" align="right">
                    <div>{{ mealTower?.vegetables?.daily }}克</div>
                    <div>{{ mealTower?.fruit?.daily }}克</div>
                  </van-col>
                </van-row>
              </van-row>
            </van-row>
            <!-- 谷类/薯类/ -->
            <van-row class="cerealTop">
              <van-row style="position: relative">
                <van-row
                  :class="[
                    mealTower?.P1?.level == 'L1'
                      ? 'cerealLess'
                      : mealTower?.P1?.level == 'L2'
                      ? 'cerealJust'
                      : 'cerealBig',
                  ]"
                >
                </van-row>
                <van-row style="position: relative">
                  <van-col span="8" style="position: relative">
                    <div>200~300克</div>
                    <div>50~150克</div>
                  </van-col>
                  <van-col span="8">
                    <van-row type="flex" align="center" justify="center">
                      <van-image
                        width="18px"
                        height="18px"
                        :src="require('../assets/cereal.png')"
                      ></van-image>
                      <div style="font-size: 12px">谷类</div>
                    </van-row>
                    <van-row type="flex" align="center" justify="center">
                      <van-image
                        width="18px"
                        height="18px"
                        :src="require('../assets/potato.png')"
                      ></van-image>
                      <div style="font-size: 12px">薯类</div>
                    </van-row>
                  </van-col>
                  <van-col
                    span="8"
                    type="flex"
                    align="right"
                    style="position: relative"
                  >
                    <div>{{ mealTower?.grain?.daily }}克</div>
                    <div>{{ mealTower?.tubers?.daily }}克</div>
                  </van-col>
                </van-row>
              </van-row>
            </van-row>
            <!-- 水 -->
            <van-row class="waterTop">
              <van-row style="position: relative">
                <van-row
                  :class="[
                    mealTower?.P0?.level == 'L1'
                      ? 'waterLess'
                      : mealTower?.P0?.level == 'L2'
                      ? 'waterJust'
                      : 'waterBig',
                  ]"
                ></van-row>
                <van-row type="flex" align="center" justify="space-between">
                  <van-col span="10" style="position: relative">
                    1500~1700毫升</van-col
                  >
                  <van-col span="6">
                    <van-row
                      type="flex"
                      align="center"
                      justify="center"
                      class="water"
                      style="position: relative"
                    >
                      <van-image
                        width="18px"
                        height="18px"
                        :src="require('../assets/water.png')"
                      ></van-image>
                      <div style="font-size: 12px">水</div>
                    </van-row>
                  </van-col>
                  <van-col span="8" style="text-align: right;position: relative;"
                    >{{ mealTower?.water?.daily }}毫升</van-col
                  >
                </van-row>
              </van-row>
            </van-row>
          </van-row>
        </van-row>
        <van-row type="flex" align="center" justify="space-around">
          <van-col span="6">
            <van-row type="flex" align="center" justify="space-around">
              <div class="col-rount" style="background: #abe9e7"></div>
              <div class="rount-txt">标准推荐</div>
            </van-row>
          </van-col>
          <van-col span="6">
            <van-row type="flex" align="center" justify="space-around">
              <div class="col-rount" style="background: #f9ccab"></div>
              <div class="rount-txt">摄入量多</div>
            </van-row>
          </van-col>
          <van-col span="6">
            <van-row type="flex" align="center" justify="space-around">
              <div class="col-rount" style="background: #ede2b5"></div>
              <div class="rount-txt">摄入量少</div>
            </van-row></van-col
          >
        </van-row>
        <van-row class="col-explain">
          上图是根据您提供的膳食信息模拟的膳食结构图，
          与中国营养学会推荐的中国居民平衡膳食宝塔
          （2022版）结构的对照图。膳食宝塔共包括六层
          食物和食品，根据图示您可以清楚的看出自己的 差距.
        </van-row>
        <!-- <van-row type="flex" align="center">
          <van-image
            width="18px"
            height="18px"
            :src="require('../assets/trophic.png')"
          ></van-image>
          <div class="life-title">三大能量营养素推荐</div>
          <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
        </van-row>
        <van-row id="cirque"> </van-row>
        <van-row class="col-explain">
          三大能量营养素是指碳水化合物、蛋白质和脂肪。
          调整三大能量营养素的供能比例，有益于控制体
          重、维护心血管健康、保持能量平衡。根据您提
          供的健康信息，我们为您推荐了以上适宜您的营 养素供能比.
        </van-row> -->

        <van-row type="flex" align="center">
          <van-image
            width="18px"
            height="18px"
            :src="require('../assets/grade.png')"
          ></van-image>
          <div class="life-title">膳食习惯评价等级</div>
          <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
        </van-row>

        <van-row type="flex" align="center" style="margin-bottom: 5px">
          <van-col span="2"></van-col>
          <van-col span="4" class="refers" v-if="dietaryHabits.score <= 28">
            <div class="r-txt">{{ dietaryHabits.scoreMemo }}</div>
            <van-icon name="diamond" color="#7BCB77"></van-icon>
          </van-col>
          <van-col v-else span="4"></van-col>

          <van-col
            span="4"
            class="refers"
            v-if="dietaryHabits.score >= 29 && dietaryHabits.score <= 39"
          >
            <div class="r-txt">{{ dietaryHabits.scoreMemo }}</div>
            <van-icon name="diamond" color="#7BCB77"></van-icon>
          </van-col>
          <van-col v-else span="4"></van-col>

          <van-col
            span="4"
            class="refers"
            v-if="dietaryHabits.score >= 40 && dietaryHabits.score <= 58"
          >
            <div class="r-txt">{{ dietaryHabits.scoreMemo }}</div>
            <van-icon name="diamond" color="#7BCB77"></van-icon>
          </van-col>
          <van-col v-else span="4"></van-col>

          <van-col
            span="4"
            class="refers"
            v-if="dietaryHabits.score >= 59 && dietaryHabits.score <= 68"
          >
            <div class="r-txt">{{ dietaryHabits.scoreMemo }}</div>
            <van-icon name="diamond" color="#7BCB77"></van-icon>
          </van-col>
          <van-col v-else span="4"></van-col>

          <van-col span="4" class="refers" v-if="dietaryHabits.score >= 69">
            <div class="r-txt">{{ dietaryHabits.scoreMemo }}</div>
            <van-icon name="diamond" color="#7BCB77"></van-icon>
          </van-col>
          <van-col v-else span="4"></van-col>

          <van-col span="2"></van-col>
        </van-row>
        <van-row type="flex" justify="space-between" gutter="15" class="green">
          <van-col span="2" class="grade"> </van-col>
          <van-col span="4" class="grade"> </van-col>
          <van-col span="4" class="grade"> </van-col>
          <van-col span="4" class="grade"> </van-col>
          <van-col span="4" class="grade"> </van-col>
          <van-col span="4" class="grade"> </van-col>
          <van-col span="2" class="grade"> </van-col>
        </van-row>
        <!-- 需改善 -->
        <van-row
          style="margin-bottom: 5px"
          :gutter="2"
          type="flex"
          justify="space-between"
        >
          <van-col span="4" style="width: 1.5rem">
            <van-row class="col-warn">
              <div>需改善</div>
              <div>
                <van-image
                  width="14px"
                  height="14px"
                  :src="require('../assets/warn.png')"
                ></van-image>
              </div>
            </van-row>
          </van-col>
          <van-col span="20" style="height: auto; flex: 1">
            <van-grid :gutter="2" direction="horizontal">
              <van-grid-item
                v-for="(item, index) in needImprove"
                :key="'i' + index"
                :icon="item.icon"
                :text="item.memo"
                class="v-grid"
              />
            </van-grid>
          </van-col>
        </van-row>

        <!-- 需保持 -->
        <van-row :gutter="2" type="flex" justify="space-between">
          <van-col span="4" style="width: 1.5rem">
            <van-row class="col-keep">
              <div>需保持</div>
              <div>
                <van-image
                  width="14px"
                  height="14px"
                  :src="require('../assets/try.png')"
                ></van-image>
              </div>
            </van-row>
          </van-col>
          <van-col span="20" style="height: auto; flex: 1">
            <van-grid :gutter="2" direction="horizontal">
              <van-grid-item
                v-for="(item, index) in needKeep"
                :key="'k' + index"
                :icon="item.icon"
                :text="item.memo"
                class="v-grid"
              />
            </van-grid>
          </van-col>
        </van-row>
      </van-row>
      <!-- 我的膳食处方 -->
      <van-row class="m-title">
        我的膳食处方
        <van-icon name="fire-o" color="#4285F4"></van-icon>
      </van-row>
      <van-row class="m-col">
        <van-row type="flex" align="center">
          <van-image
            width="18px"
            height="18px"
            :src="require('../assets/nowFit.png')"
          ></van-image>
          <div class="life-title">当前健康状况</div>
          <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
        </van-row>
        <van-row class="col-status">
          {{ myHeath.details }}
        </van-row>

        <van-row type="flex" align="center">
          <van-image
            width="18px"
            height="18px"
            :src="require('../assets/recipe.png')"
          ></van-image>
          <div class="life-title">食谱推荐</div>
          <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
        </van-row>
        <van-row class="col-suggest">
          <div style="color: #1d1d1d; font-size: 13px">能量等级（千卡）</div>
          <div style="color: #4285f4; font-size: 22px">{{ recommend.cal }}</div>
        </van-row>
        <van-row style="margin-top: 10px">
          <van-row
            type="flex"
            align="center"
            justify="space-between"
            style="margin-bottom: 10px"
          >
            <van-col span="7" class="col-power1">
              <div class="p-txt">谷类</div>
              <div class="p-num">{{ recommend.meals?.grain }}份</div>
            </van-col>
            <van-col span="8" class="col-power2">
              <div class="p-txt">薯类</div>
              <div class="p-num">{{ recommend.meals?.tubers }}份</div>
            </van-col>
            <van-col span="7" class="col-power3">
              <div class="p-txt">蔬菜类</div>
              <div class="p-num">{{ recommend.meals?.vegetables }}份</div>
            </van-col>
          </van-row>
          <van-row
            type="flex"
            align="center"
            justify="space-between"
            style="margin-bottom: 10px"
          >
            <van-col span="7" class="col-power4">
              <div class="p-txt">水果类</div>
              <div class="p-num">{{ recommend.meals?.fruit }}份</div>
            </van-col>
            <van-col span="8" class="col-power5">
              <div class="p-txt">畜禽肉水产类</div>
              <div class="p-num">{{ recommend.meals?.aquaticMeat }}份</div>
            </van-col>
            <van-col span="7" class="col-power6">
              <div class="p-txt">蛋类</div>
              <div class="p-num">{{ recommend.meals?.eggs }}份</div>
            </van-col>
          </van-row>
          <van-row type="flex" align="center" justify="space-between">
            <van-col span="7" class="col-power7">
              <div class="p-txt">大豆坚果类</div>
              <div class="p-num">{{ recommend.meals?.beanNut }}份</div>
            </van-col>
            <van-col span="8" class="col-power8">
              <div class="p-txt">奶及奶制品</div>
              <div class="p-num">{{ recommend.meals?.milchigs }}份</div>
            </van-col>
            <van-col span="7" class="col-power9">
              <div class="p-txt">油脂类</div>
              <div class="p-num">{{ recommend.meals?.oil }}份</div>
            </van-col>
          </van-row>
        </van-row>
      </van-row>
      <!-- 我的身体活动评估 -->
      <van-row class="m-title">
        我的身体活动评估
        <van-icon name="fire-o" color="#4285F4"></van-icon>
      </van-row>
      <van-row class="m-col">
        <van-row class="m-assess">
          <van-row type="flex" align="center">
            <van-image
              width="18px"
              height="18px"
              :src="require('../assets/run.png')"
            ></van-image>
            <div class="life-title">身体活动水平</div>
            <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
          </van-row>

          <div
            v-if="activity.activeStatus == 1"
            style="font-size: 18px; padding: 10px 25px"
          >
            充足
          </div>
          <div v-else style="font-size: 18px; padding: 10px 25px">不足</div>
        </van-row>
        <van-row type="flex" align="center">
          <van-image
            width="18px"
            height="18px"
            :src="require('../assets/run2.png')"
          ></van-image>
          <div class="life-title">一周内平均每天工作及闲暇时活动的时间分布</div>
          <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
        </van-row>
        <van-row id="sports"> </van-row>
        <van-row class="col-status">
          根据您提供的信息，汇总成“一周内平均每天身体活
          动的时间分布图”，帮助您了解身体活动的情况，同
          时作为制定运动处方的依据.
        </van-row>
      </van-row>
      <!-- 我的身体活动处方 -->
      <van-row class="m-title">
        我的身体活动处方
        <van-icon name="fire-o" color="#4285F4"></van-icon>
      </van-row>
      <van-row class="m-col">
        <van-row type="flex" align="center">
          <van-image
            width="18px"
            height="18px"
            :src="require('../assets/steps.png')"
          ></van-image>
          <div class="life-title">锻炼目标</div>
          <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
        </van-row>
        <van-row class="col-status">
          {{ mySports?.aim?.details }}
        </van-row>
        <van-row type="flex" align="center">
          <van-image
            width="18px"
            height="18px"
            :src="require('../assets/steps.png')"
          ></van-image>
          <div class="life-title">热身活动</div>
          <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
        </van-row>
        <div class="col-status">{{ mySports?.warm?.details }}</div>
        <van-row v-for="(item, index) in mySports?.list" :key="'s' + index">
          <van-row type="flex" align="center">
            <van-image
              width="18px"
              height="18px"
              :src="require('../assets/run2.png')"
            ></van-image>
            <div class="life-title">{{ item.aim?.des }}</div>
            <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
          </van-row>

          <van-row
            type="flex"
            align="center"
            justify="space-between"
            class="ox-sports"
            v-show="item?.total != '' && item?.total != null"
          >
            <van-col span="4" class="s-title"> 总量 </van-col>
            <van-col span="19">
              {{ item?.total?.details }}
            </van-col>
          </van-row>
          <van-row
            type="flex"
            align="center"
            justify="space-between"
            class="ox-sports"
            v-show="item?.freq?.details != null && item?.freq?.details != ''"
          >
            <van-col span="4" class="s-title"> 频率 </van-col>
            <van-col span="19"> {{ item?.freq?.details }} </van-col>
          </van-row>
          <van-row
            type="flex"
            align="center"
            justify="space-between"
            class="ox-sports"
            v-show="
              item?.strength?.details != null && item?.strength?.details != ''
            "
          >
            <van-col span="4" class="s-title"> 强度 </van-col>
            <van-col span="19"> {{ item?.strength?.details }} </van-col>
          </van-row>
          <van-row
            type="flex"
            align="center"
            justify="space-between"
            class="ox-sports"
            v-show="item?.time?.details != null && item?.time?.details != ''"
          >
            <van-col span="4" class="s-title"> 时间 </van-col>
            <van-col span="19"> {{ item?.time?.details }} </van-col>
          </van-row>
          <van-row
            type="flex"
            align="center"
            justify="space-between"
            class="ox-sports"
            v-show="item?.way?.details != null && item?.way?.details != ''"
          >
            <van-col span="4" class="s-title"> 方式 </van-col>
            <van-col span="19">
              {{ item?.way?.details }}
            </van-col>
          </van-row>
          <van-row
            type="flex"
            align="center"
            justify="space-between"
            class="ox-sports"
            v-show="item?.note?.details != null && item?.note?.details != ''"
          >
            <van-col span="4" class="s-title"> 注意事项 </van-col>
            <van-col span="19">
              {{ item?.note?.details }}
            </van-col>
          </van-row>
        </van-row>
      </van-row>
      <!-- 我的戒烟处方 -->
      <van-row class="m-title" v-show="smokeVisible">
        我的戒烟处方
        <van-icon name="fire-o" color="#4285F4"></van-icon>
      </van-row>
      <van-row class="m-col" v-show="smokeVisible">
        <van-row class="smok-title"> {{ smoke.title }} </van-row>
        <van-row class="col-status">
          {{ smoke.details }}
        </van-row>
      </van-row>
      <!-- 我的限酒处方 -->
      <van-row class="m-title" v-show="drinkVisible">
        我的限酒处方
        <van-icon name="fire-o" color="#4285F4"></van-icon>
      </van-row>
      <van-row class="m-col" v-show="drinkVisible">
        <van-row class="drink-title"> {{ drink.title }} </van-row>
        <van-row type="flex" align="center">
          <van-image
            width="18px"
            height="18px"
            :src="require('../assets/steps.png')"
          ></van-image>
          <div class="life-title">措施</div>
          <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
        </van-row>

        <van-row class="col-status">
          {{ drink.details }}
        </van-row>
      </van-row>
    </van-row>
  </div>
</template>

<script>
import fitNav from "@/components/fitNav.vue";
import * as echarts from "echarts";
import { lifeReport } from "../api/report";
// var myCirquePie;
var mySportsPie;
export default {
  name: "lifestyle-card",
  data() {
    return {
      // 导航条
      title: "",
      smokeVisible: false,
      drinkVisible: false,
      //需要改善
      needImproveVisivle: false,
      //需要保持
      needKeepVisible: false,
      //需要改善
      needImprove: [],
      //需要保持
      needKeep: [],
      dietaryHabits: {},
      mealTower: {},
      recommend: {
        cal: 0,
        meals: {},
      },
      activity: {},
      myHeath: {},
      drink: {},
      smoke: {},
      mySports: {},
      span: 9,
      justify: "",
    };
  },
  components: {
    fitNav,
  },

  created() {
    let recordId = this.$route.query.recordId;
    this.getReport(recordId);
  },
  mounted() {
    //圆环饼状图
    // myCirquePie = echarts.init(document.getElementById("cirque"));
    //
    mySportsPie = echarts.init(document.getElementById("sports"));
  },
  methods: {
    getReport(recordId) {
      lifeReport(recordId).then((res) => {
        this.recommend = res.data.recommend;
        this.activity = res.data.activity;
        this.myHeath = res.data.myHeath;
        this.dietaryHabits = res.data.dietaryHabits;
        // if(this.dietaryHabits.scoreMemo){

        // }
        this.needImprove = this.dietaryHabits.needImprove;
        this.needKeep = this.dietaryHabits.needKeep;
        this.addIcon(this.needImprove);
        this.addIcon(this.needKeep);
        this.mealTower = res.data.mealTower;
        this.mySports = res.data.mySports;

        if (res.data.hasOwnProperty("smoke") == true) {
          this.smokeVisible = true;
          this.smoke = res.data.smoke;
        }
        if (res.data.hasOwnProperty("drink") == true) {
          this.drinkVisible = true;
          this.drink = res.data.drink;
        }
        // myCirquePie.setOption({
        //   series: [
        //     {
        //       type: "pie",
        //       data: res.data.energySupplyRatio,
        //       radius: ["40%", "70%"],
        //     },
        //   ],
        // });

        mySportsPie.setOption({
          series: [
            {
              type: "pie",
              data: res.data.activity.activeRatio,
              radius: "70%",
            },
          ],
        });
      });
    },

    addIcon(arr) {
      for (var item of arr) {
        if (item["type"] == "grain") {
          item["icon"] = require("../assets/cereal.png");
        } else if (item["type"] == "tubers") {
          item["icon"] = require("../assets/potato.png");
        } else if (item["type"] == "vegetables") {
          item["icon"] = require("../assets/vegetable.png");
        } else if (item["type"] == "fruit") {
          item["icon"] = require("../assets/fruit.png");
        } else if (item["type"] == "meat") {
          item["icon"] = require("../assets/meat.png");
        } else if (item["type"] == "aquatic") {
          item["icon"] = require("../assets/aquatic.png");
        } else if (item["type"] == "eggs") {
          item["icon"] = require("../assets/eggs.png");
        } else if (item["type"] == "milk") {
          item["icon"] = require("../assets/milk.png");
        } else if (item["type"] == "beans") {
          item["icon"] = require("../assets/beans.png");
        } else if (item["type"] == "nut") {
          item["icon"] = require("../assets/nut.png");
        } else if (item["type"] == "oil") {
          item["icon"] = require("../assets/oil.png");
        } else if (item["type"] == "salt") {
          item["icon"] = require("../assets/salt.png");
        } else if (item["type"] == "water") {
          item["icon"] = require("../assets/water.png");
        } else if (item["type"] == "taste") {
          item["icon"] = require("../assets/taste.png");
        } else if (item["type"] == "drink") {
          item["icon"] = require("../assets/drinkIcon.png");
        } else if (item["type"] == "habits") {
          item["icon"] = require("../assets/habits.png");
        }
      }
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
.l-box {
  width: 100%;
  overflow: hidden;
  background: url("../assets/lifeCard.png") no-repeat;
  background-size: 100%;
  padding-top: 6rem;
}
.l-main {
  padding: 10px;
  overflow: hidden;
  background: #dbe3f9;
  font-family: PingFang SC ExtraLight;
  border-radius: 32px 32px 0px 2px;
  font-size: 0.38rem;
}
.m-title {
  padding: 10px;
  font-size: 0.4rem;
  color: #1d1d1d;
  line-height: 40px;
}
/deep/ .van-cell {
  background-color: #f3f6fe;
  font-size: 0.38rem;
}
.m-col {
  padding: 10px;
  background-color: #f3f6fe;
  border-radius: 32px;
}
.col-title {
  padding: 8px;
  background-color: #ebf0fe;
  border-radius: 30px;
  color: #4285f4;
  font-size: 0.35rem;
  text-align: center;
}
.col-rount {
  background: #abe9e7;
  border-radius: 50%;
  width: 0.4rem;
  height: 0.4rem;
  display: inline-block;
}
.rount-txt {
  display: inline-block;
  /* font-weight: 400; */
  color: #1d1d1d;
  line-height: 40px;
  font-size: 0.35rem;
}
.col-explain {
  background: #ebf0fe;
  border-radius: 32px;
  padding: 15px;
  font-size: 0.35rem;
  color: #1d1d1d;
}
#cirque,
#sports {
  height: 6rem;
}
/deep/ .van-grid-item__text {
  width: 1.3rem;
}
.col-warn,
.col-try,
.col-keep {
  font-size: 0.35rem;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 1.5rem;
  height: 100%;
}
.col-warn {
  background: #f7f1e6;
  color: #a3552d;
}
.col-try {
  background: #e8f7e5;
  color: #50684f;
}
.col-keep {
  background: #e4ebf7;
  color: #4f6070;
}
/deep/ .van-grid-item__content--horizontal {
  border-radius: 10px;
}
.col-status {
  padding: 15px;
  color: #666666;
  font-size: 0.35rem;
}
.col-suggest {
  background: #ebf0fe;
  padding: 0.3rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.col-power1,
.col-power2,
.col-power3,
.col-power4,
.col-power5,
.col-power6,
.col-power7,
.col-power8,
.col-power9 {
  border-radius: 10px;
  padding: 10px 15px;
}
.col-power1 {
  background: url("../assets/cerealBck.png") no-repeat;
  background-size: 100% 100%;
}
.col-power2 {
  background: url("../assets/potatoBck.png") no-repeat;
  background-size: 100% 100%;
}
.col-power3 {
  background: url("../assets/vetBck.png") no-repeat;
  background-size: 100% 100%;
}
.col-power4 {
  background: url("../assets/fruitBck.png") no-repeat;
  background-size: 100% 100%;
}
.col-power5 {
  background: url("../assets/meatBck.png") no-repeat;
  background-size: 100% 100%;
}
.col-power6 {
  background: url("../assets/eggBck.png") no-repeat;
  background-size: 100% 100%;
}
.col-power7 {
  background: url("../assets/nutBck.png") no-repeat;
  background-size: 100% 100%;
}
.col-power8 {
  background: url("../assets/milkBck.png") no-repeat;
  background-size: 100% 100%;
}
.col-power9 {
  background: url("../assets/oilBck.png") no-repeat;
  background-size: 100% 100%;
}
.p-txt {
  font-size: 0.3rem;
  color: #4e4c82;
}
.p-num {
  font-size: 0.28rem;
}
.m-assess {
  background: url("../assets/debate.png") no-repeat;
  background-size: 100% 100%;
}
.ox-sports {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 2px #ebf0fe solid;
}
.s-title {
  color: #4285f4;
  /* font-weight: bolder; */
  background-color: #ebf0fe;
  padding: 10px;
  border-radius: 10px;
  font-size: 0.35rem;
  text-align: center;
}
.smok-title,
.drink-title {
  padding: 30px;
  /* font-weight: bolder; */
  color: #1d1d1d;
  font-size: 0.5rem;
}
.smok-title {
  background: url("../assets/smok.png") no-repeat;
  background-size: 100% 100%;
}
.drink-title {
  background: url("../assets/drink.png") no-repeat;
  background-size: 100% 100%;
}
.grade {
  padding: 10px;
  border-right: 1px solid white;
}
.refers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.r-txt {
  background-color: #7dcc78;
  padding: 2px 5px;
  font-size: 0.2rem;
  border-radius: 10px;
  color: white;
}
.green {
  border-radius: 20px;
  overflow: hidden;
  width: 96%;
  margin: 0 auto;
  margin-bottom: 15px;
  background: linear-gradient(90deg, #ddf0d6, #7bcb77);
}
.pagoda {
  margin-top: 10px;
  position: relative;
}
.saltLess {
  width: 0;
  height: 0;
  border-left: 0.4rem solid transparent;
  border-right: 0.4rem solid transparent;
  border-bottom: 0.8rem solid #ede2b5;
  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -0.4rem;
}
.saltJust {
  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-bottom: 0.8rem solid #abe9e7;
  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -0.5rem;
}
.saltBig {
  width: 0;
  height: 0;
  border-left: 0.9rem solid transparent;
  border-right: 0.9rem solid transparent;
  border-bottom: 0.8rem solid #f9ccab;
  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -0.9rem;
}
.milkLess {
  width: 0.8rem;
  height: 0;
  border-width: 0 0.4rem 0.8rem 0.4rem;
  border-style: none solid solid;
  border-color: transparent transparent #ede2b5;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -0.8rem;
}
.milkJust {
  width: 1.2rem;
  height: 0;
  border-width: 0 0.6rem 1rem 0.6rem;
  border-style: none solid solid;
  border-color: transparent transparent #abe9e7;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1.2rem;
}
.milkBig {
  width: 1.8rem;
  height: 0;
  border-width: 0 0.8rem 1rem 0.8rem;
  border-style: none solid solid;
  border-color: transparent transparent #f9ccab;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1.7rem;
}
.meatLess {
  width: 1.6rem;
  height: 0;
  border-width: 0 0.2rem 0.6rem 0.2rem;
  border-style: none solid solid;
  border-color: transparent transparent #ede2b5;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1rem;
}
.meatJust {
  width: 2.4rem;
  height: 0;
  border-width: 0 0.4rem 0.6rem 0.4rem;
  border-style: none solid solid;
  border-color: transparent transparent #abe9e7;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1.6rem;
}
.meatBig {
  width: 3.5rem;
  height: 0;
  border-width: 0 0.4rem 0.6rem 0.4rem;
  border-style: none solid solid;
  border-color: transparent transparent #f9ccab;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2.2rem;
}
.fruitLess {
  width: 2rem;
  height: 0;
  border-width: 0 0.4rem 0.8rem 0.4rem;
  border-style: none solid solid;
  border-color: transparent transparent #ede2b5;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1.4rem;
}
.fruitJust {
  width: 3.35rem;
  height: 0;
  border-width: 0 0.55rem 0.8rem 0.5rem;
  border-style: none solid solid;
  border-color: transparent transparent #abe9e7;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2.2rem;
}
.fruitBig {
  width: 4.4rem;
  height: 0;
  border-width: 0 0.65rem 0.8rem 0.65rem;
  border-style: none solid solid;
  border-color: transparent transparent #f9ccab;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2.8rem;
}
.cerealLess {
  width: 2.8rem;
  height: 0;
  border-width: 0 0.4rem 0.8rem 0.4rem;
  border-style: none solid solid;
  border-color: transparent transparent #ede2b5;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1.8rem;
}
.cerealJust {
  width: 4.4rem;
  height: 0;
  border-width: 0 0.6rem 0.8rem 0.6rem;
  border-style: none solid solid;
  border-color: transparent transparent #abe9e7;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2.8rem;
}
.cerealBig {
  width: 5.7rem;
  height: 0;
  border-width: 0 0.6rem 0.8rem 0.6rem;
  border-style: none solid solid;
  border-color: transparent transparent #f9ccab;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -3.4rem;
}
.waterLess {
  width: 3.8rem;
  height: 0;
  border-width: 0 0.3rem 0.4rem 0.3rem;
  border-style: none solid solid;
  border-color: transparent transparent #ede2b5;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2.2rem;
}
.waterJust {
  width: 5.5rem;
  height: 0;
  border-width: 0 0.3rem 0.4rem 0.3rem;
  border-style: none solid solid;
  border-color: transparent transparent #abe9e7;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -3.05rem;
}
.waterBig {
  width: 7rem;
  height: 0;
  border-width: 0 0.3rem 0.4rem 0.3rem;
  border-style: none solid solid;
  border-color: transparent transparent #f9ccab;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -3.8rem;
}

.border {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 3.1rem 5.1rem;
  border-color: transparent transparent #abe9e7;
  position: relative;
  margin: 0 auto;
}
.border:after {
  content: "";
  border-style: solid;
  border-width: 0 3rem 5rem;
  border-color: transparent transparent #f3f6fe;
  position: absolute;
  top: 0.05rem;
  left: -3rem;
}
.milkTop {
  position: absolute;
  top: 1rem;
  width: 100%;
}
.meatTop {
  position: absolute;
  top: 2.05rem;
  width: 100%;
}
.vetTop {
  position: absolute;
  top: 2.7rem;
  width: 100%;
}
.cerealTop {
  position: absolute;
  top: 3.7rem;
  width: 100%;
  overflow-y: hidden;
}
.waterTop {
  width: 100%;
  position: absolute;
  top: 4.65rem;
  overflow-y: hidden;
}
.life-title {
  padding: 15px 10px;
  font-size: 0.35rem;
}
.v-grid {
  min-width: 2.45rem;
}
</style>