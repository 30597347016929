<template>
    <!-- 基础问卷 -->
  <div class="basic-box">
    <!-- 导航条 -->
    <fitNav :title="title"></fitNav>
    <div class="main">
      <!-- 体格检查、血液生化检查 -->
      <physiqueInfo></physiqueInfo>
    </div>
  </div>
</template>

<script>
import fitNav from "@/components/fitNav.vue";
import physiqueInfo from "@/components/physique-info.vue";
export default {
  name: "basicQuest",
  data() {
    return {
      title: "基础问卷",
    };
  },
  components: {
    fitNav,
    physiqueInfo,
  },
};
</script>

<style scoped>
*{
    padding: 0;
    margin: 0;
  }
.basic-box {
  width: 100%;
  overflow: hidden;
  background: url(../assets/bkg.png) no-repeat;
  background-size: 100% 100%;
  padding-top: 2rem;
}
.main {
  padding: 20px 15px 15px 15px;
  overflow: hidden;
  background: #dbe3f9;
  border-radius: 32px 32px 0px 2px;
  font-family: PingFang SC ExtraLight;
  /* font-weight: 550; */
  color: #1d1d1d;
  line-height: 0.6rem;
  font-size: 0.4rem;
}
</style>