<template>
    <!-- 运动月报 -->
    <div class="box">
      <fitNav title=""></fitNav>
      <van-row v-if="noData" >
        无用户记录
    </van-row>
    <van-row v-else>
      <van-row class="main">
        <van-row style="padding: 10px" type="flex" align="center">
          <div>干得好,您在一月中记录了{{ monthly.recordDay }}天</div>
          <!-- <div style="padding-left: 10px">
            <van-button
              color="#005DFF"
              size="mini"
              round
              style="width: 60px; height: 30px"
              >详情</van-button
            >
          </div> -->
        </van-row>
  
        <van-row class="m-block" type="flex" justify="space-between">
          <van-col span="10" class="b-item">
            <!-- 日能量目标 -->
            <van-row type="flex" style="padding: 5px 0px">
              <van-row
                type="flex"
                justify="center"
                align="center"
                style="padding: 0px 10px"
              >
                <van-image
                  width="30px"
                  height="30px"
                  :src="require('../assets/thunder.png')"
                ></van-image>
              </van-row>
              <van-row>
                <div style="color: #005dff; font-size: 20px">{{ monthly.avgEnergy }}</div>
                <div style="font-size: 12px; color: #666">本月日均：千卡</div>
              </van-row>
            </van-row>
  
            <van-row type="flex" style="padding: 5px 0px">
              <van-row
                type="flex"
                justify="center"
                align="center"
                style="padding: 0px 10px"
              >
                <van-image
                  width="30px"
                  height="30px"
                  :src="require('../assets/shoes.png')"
                ></van-image>
              </van-row>
              <van-row>
                <div style="color: #005dff; font-size: 20px">{{ monthly.avgWalk }}</div>
                <div style="font-size: 12px; color: #666">本月日均：步</div>
              </van-row>
            </van-row>
            <van-row type="flex" style="padding: 5px 0px">
              <van-row
                type="flex"
                justify="center"
                align="center"
                style="padding: 0px 10px"
              >
                <van-image
                  width="30px"
                  height="30px"
                  :src="require('../assets/clock.png')"
                ></van-image>
              </van-row>
              <van-row>
                <div style="color: #005dff; font-size: 20px">{{ monthly.avgSportsTime }}</div>
                <div style="font-size: 12px; color: #666">本月日均：分钟</div>
              </van-row>
            </van-row>
          </van-col>
          <!-- 散点图 -->
          <van-col span="13" style="color: #ebf0fe">
            <div id="scatter"></div>
          </van-col>
        </van-row>

        <van-row class="m-sport">
          <van-row type="flex" align="center">
            <van-image
              width="18"
              height="18"
              :src="require('../assets/pace.png')"
            ></van-image>
            <div class="b-title">本周运动情况</div>
            <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
          </van-row>
          <van-row style="padding: 10px;">
            <div>运动时间：<span style="color: #A6A9B3;">{{ monthly.sumSportsTime}}分钟 </span></div>
            <!-- <div>抗阻运动：<span style="color: #A6A9B3;">100分钟 不达标</span></div>
            <div>拉伸运动：<span style="color: #A6A9B3;">100分钟 不达标</span></div> -->
          </van-row>
        </van-row>
        <!-- 下个月运动建议 -->
        <van-row class="m-next">
          <van-row type="flex" align="center">
            <van-image
              width="18"
              height="18"
              :src="require('../assets/pace.png')"
            ></van-image>
            <div class="b-title">下个月运动建议</div>
            <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
          </van-row>
          <van-row style="padding: 10px;">
            <div v-for="(item, index) in monthly.mySports?.list"
              :key="'s' + index">{{ item.aim?.des }}：
              <span style="color: #A6A9B3;">{{ item?.freq?.details }}</span>
            </div>
          </van-row>
        </van-row>
      </van-row>
      </van-row>
    </div>
  </template>
  
  <script>
  import fitNav from "@/components/fitNav.vue";
  import * as echarts from "echarts";
  import { sportsMonthlyReport } from "../api/report"

  var myChart

  export default {
    name: "dietMonthly",
    components: { fitNav },
    data() {
      return {
        currentRate: 0,
        gradientColor: {
          "50%": "#FBA55B",
          "100%": "#FFC88D",
        },
        monthly: {
          recordDay: 0,
          sumEnergy: 0,
          avgEnergy: 0,
          avgSportsTime: 0,
          avgWalk: 0,
          mySports: {},
      },
      noData: false
      };
    },
    mounted() {
      myChart = echarts.init(document.getElementById("scatter"));
    },
    created() {
    let id = this.$route.query.id;
    this.getSportsMonthlyReport(id);
  },
  methods: {
    getSportsMonthlyReport(id) {
      sportsMonthlyReport(id).then((res) => {  
        this.monthly = res.data
        if (JSON.stringify(this.monthly) == "{}") {
          this.noData = true
        }
        myChart.setOption({
        xAxis: {
        },
        yAxis: {},
        series: [
          {
            type: "scatter",
            data: this.monthly.chartData,
          },
        ],
      });
      })
    },
  }
  };
  </script>
  
  <style scoped>
  * {
    padding: 0;
    margin: 0;
  }
  .box {
    width: 100%;
    overflow: hidden;
    background: url(../assets/sportMonthly.png) no-repeat;
    background-size: 100%;
    padding-top: 5.5rem;
  }
  .main {
    padding: 10px;
    background: #dbe3f9;
    border-radius: 32px 32px 0px 2px;
    font-family: PingFang SC ExtraLight;
    /* line-height: 0.6rem; */
  }
  .m-block {
    background-color: #f3f6fe;
    border-radius: 28px;
    padding: 10px;
    margin-bottom: 15px;
  }
  .b-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  #scatter {
    height: 5rem;
    width: 5rem;
  }
  .b-title {
    padding: 5px 10px;
    font-size: 0.35rem;
  }
  .m-sport,.m-next{
    padding: 10px;
    border-radius: 32px;
    margin-bottom: 10px;
    font-size: 0.35rem;
    line-height: 0.6rem;
  }
  .m-sport{
    background: url('../assets/debate.png') no-repeat;
    background-size: 100%;
    background-color: #f3f6fe;
  }
  .m-next{
    background: url('../assets/nextMon.png') no-repeat;
    background-size: 100%;
    background-color: #f3f6fe;
  }
  </style>