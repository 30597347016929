<template>
  <div class="drink-box">
    <van-row class="m-title">饮酒</van-row>
    <van-row style="padding: 5px"> 1 您喝酒吗？ </van-row>
    <van-row class="m-form">
      <van-radio-group v-model="form.drink" @change="drinkChange">
        <van-radio name="1" shape="square" style="margin: 5px 15px 0px 0px"
          >从来不喝</van-radio
        >
        <van-radio name="2" shape="square" style="margin: 5px 15px 0px 0px"
          >以前喝，现在已戒酒</van-radio
        >
        <van-radio name="3" shape="square" style="margin: 5px 15px 0px 0px"
          >每天喝</van-radio
        >
        <van-radio name="4" shape="square" style="margin: 5px 15px 0px 0px"
          >每周喝</van-radio
        >
        <van-radio name="5" shape="square" style="margin: 5px 15px 0px 0px"
          >每月喝</van-radio
        >
      </van-radio-group>
    </van-row>
	<van-row v-show="!isNoDrink">
		<van-row style="padding: 5px"> 2 请选择以下类型的酒及饮酒量(多选) </van-row>
		<van-row class="m-form">
		  <van-row class="f-con" type="flex" align="center">
		    <div style="padding-right: 10px">低度白酒（＞38度)</div>
		    <van-stepper min="" v-model="form.highAlcohol" :disabled="isNoDrink"/>
		    <div style="padding-left: 10px">两</div>
		  </van-row>
		  <van-row class="f-con" type="flex" align="center"
		    ><div style="padding-right: 10px">高度白酒（≤38度)</div>
		    <van-stepper min="" v-model="form.lowAlcohol" :disabled="isNoDrink"/>
		    <div style="padding-left: 10px">两</div>
		  </van-row>
		  <van-row class="f-con" type="flex" align="center"
		    ><div style="padding-right: 10px">啤 酒</div>
		    <van-stepper min="" v-model="form.beer" :disabled="isNoDrink"/>
		    <div style="padding-left: 10px">毫升</div>
		  </van-row>
		  <van-row class="f-con" type="flex" align="center"
		    ><div style="padding-right: 10px">葡萄酒或黄酒</div>
		    <van-stepper min="" v-model="form.wine" :disabled="isNoDrink"/>
		    <div style="padding-left: 10px">毫升</div>
		  </van-row>
		</van-row>
	</van-row>
    
    <van-row style="padding: 30px 5px 10px 5px">
      <van-button
        style="border-radius: 16px"
        type="primary"
        size="large"
        color="#005DFF"
        @click="getSmok('smok')"
        >下一步</van-button
      >
    </van-row>
  </div>
</template>

<script>
import { Toast } from 'vant';
  import { addQuestionnaire } from "../api/index";
export default {
  name: "drinking",
  data() {
    return {
      form: {
        step: 3,
        drink: "",
        highAlcohol: "",
        lowAlcohol: "",
        beer: "",
        wine: "",
      },
      isNoDrink:true,
    };
  },
  methods: {
    drinkChange(val){
      if (val == 1 || val == 2) {
        this.isNoDrink = true;
        this.form.highAlcohol = "";
        this.form.lowAlcohol = "";
        this.form.beer = "";
        this.form.wine = "";
      } else {
        this.isNoDrink = false;
      }
    },
    getSmok(value) {
      addQuestionnaire(this.form).then((res) => {
        if (res.code == 200) {
          Toast.success("提交成功！");
          setTimeout(() => {
            this.$emit("getSmok", value);
           },1000);
        } else {
          Toast.fail("提交失败！");
        }
      });
      
    },
  },
};
</script>

<style scoped>
.drink-box {
  overflow: hidden;
  width: 100%;
  font-family: PingFang SC ExtraLight;
}

.m-title {
  font-size: 0.6rem;
  padding: 10px 0px 10px 0px;
}

.m-form {
  font-size: 0.35rem;
  border-radius: 10px;
  background-color: #f3f6fe;
  padding: 15px;
  margin: 5px 0px 10px 0px;
}

/deep/ .van-stepper__input {
  background-color: #f3f6fe;
  border-bottom: 1px #0b9aff solid;
  margin: 0px 5px 0px 5px;
  width: 80px;
  color: #1D1D1D;
  height: 20px;
}
/deep/ .van-stepper__minus{
  background-color: #0B9AFF;
  color: white;
  height: 20px;
  width: 20px;
}
/deep/ .van-stepper__plus{
  background-color: #0B9AFF;
  color: white;
  height: 20px;
  width: 20px;
}
.f-con {
  padding-top: 5px;
}
</style>