import request from "../utils/request"

// 获取文章分类
export function getKepuType() {
    return request({
      url: '/web/hemHealthSubject/getTypes',
      method: 'get',
    })
  }

  //获取文章
  export function getKepu(data) {
    return request({
      url: '/web/hemHealthSubject/getSubject',
      method: 'get',
      params: data
    })
  }
  //获取文章详情
  export function getKepuInfosById(id) {
    return request({
      url: '/web/hemHealthSubject/'+id,
      method: 'get',
      params: id
    })
  }

  
  
