<template>
  <!-- 科普详情 -->
  <div class="box">
    <fit-nav title="详情"></fit-nav>
    <van-row class="b-main">
      <van-row class="title">
        {{ data.title }}
      </van-row>
      <van-row>
        <span v-html="data.content"></span>
      </van-row>
    </van-row>
  </div>
</template>

<script>
import fitNav from "@/components/fitNav.vue";
import { getKepuInfosById } from "../api/kepu";
import { Toast } from "vant";
export default {
  name: "kepuDetail",
  data() {
    return {
      data: {},
    };
  },
  components: {
    fitNav,
  },
  mounted() {
    getKepuInfosById(this.$route.query.id).then((res) => {
      if (res.code == "200") {
        this.data = res.data;
      } else {
        Toast.fail("查询失败");
      }
    });
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.box {
  width: 100%;
  overflow: hidden;
  background: url("../assets/kepuBck2.png") no-repeat;
  background-size: 100%;
  padding-top: 2rem;
}
.b-main {
  padding: 0.5rem 10px;
  overflow: hidden;
  background: #dbe3f9;
  font-family: PingFang SC ExtraLight;
  border-radius: 32px 32px 0px 2px;
  min-height: calc(100vh - 3rem);
  line-height: 0.7rem;
}
.title {
  font-size: 0.6rem;
  color: black;
  padding: 10px 0px;
}
</style>