//导入axios
import axios from "axios";
import { tansParams } from '../utils/paramDis'
import {
  getCookie
} from '@/utils/cookie'
import Vue from 'vue';
import { Toast } from 'vant'
Vue.use(Toast);

axios.defaults.headers['Conntent-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: "http://192.168.31.177:8080",
  // baseURL: "http://8.131.88.181:8082",
  baseURL: "https://is.admin.mapnew.cn/prod-api",
  // 超时
  timeout: 30000
})

// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  // const isToken = (config.headers || {}).isToken === false
  if (getCookie('token')) {
    config.headers['Authorization'] = 'Bearer ' + getCookie('token')
  }

  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200;
  // 获取错误信息
  const msg = res.data.msg
  // 二进制数据则直接返回
  if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
    return res.data
  }
  // if(code === 200){
  //   console.log("成功");
  // }else if (code === 500) {
  //   console.log("失败")
  //  }
  if (code === 401) {
    // MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
    //   confirmButtonText: '重新登录',
    //   cancelButtonText: '取消',
    //   type: 'warning'
    // }).then(() => {
    //   store.dispatch('LogOut').then(() => {
    //     location.href = '/index';
    //   })
    // }).catch(() => {});
    location.href = '/login';
    return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
  } else if (code === 500) {
    Toast({
      message: msg,
      type: 'fail'
    })
    return Promise.reject(new Error(msg))
  } else if (code !== 200) {
    Toast({
      message: msg,
      type: 'fail'
    })
    return Promise.reject('error')
  } else {
    return res.data
  }
},
  error => {
    console.log('err' + error)
    let {
      message
    } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Toast({
      message: message,
      type: 'fail',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)
export default service


