<template>
  <!-- 膳食 -->
  <div class="diet-box">
    <van-row class="m-title">膳食</van-row>
    <van-row style="padding: 5px">
      1
      请回忆您平常的用餐习惯，是否吃过以下食物，并估计该食物的平均食用量和次数.
    </van-row>
    <!-- title -->
    <!-- 精致谷类（精致大米和面粉) -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type"> 精致谷类（精致大米和面粉）： </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group
          v-model="from.refinedGrainCycle"
          direction="horizontal"
        >
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="from.refinedGrainTimes" />
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="from.refinedGrainEdible" />
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
    </van-row>
    <!-- 全谷物杂豆（全麦、糙米、小米、玉米、燕麦、红小豆、豌豆等)： -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type">
        全谷物杂豆（全麦、糙米、小米、玉米、燕麦、红小豆、豌豆等）：
      </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="from.wholeGrainCycle"  direction="horizontal">
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="from.wholeGrainTimes" />
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="from.wholeGrainEdible" />
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
    </van-row>
    <!-- 薯类（土豆、红薯、芋头、山药等)： -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type">
        薯类（土豆、红薯、芋头、山药等）：
      </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="from.tubersCycle"   direction="horizontal">
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="from.tubersTimes" />
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="from.tubersEdible" />
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
    </van-row>
    <!-- 新鲜蔬菜： -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type"> 新鲜蔬菜： </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="from.vegetablesCycle"  direction="horizontal">
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="from.vegetablesTimes" />
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="from.vegetablesEdible" />
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
    </van-row>
    <!-- 新鲜水果： -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type"> 新鲜水果： </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="from.fruitCycle"  direction="horizontal">
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="from.fruitTimes" />
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="from.fruitEdible" />
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
    </van-row>
    <!-- 畜禽肉类食物（猪、牛、羊、禽类)： -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type">
        畜禽肉类食物（猪、牛、羊、禽类）：
      </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="from.meatCycle"  direction="horizontal">
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="from.meatTimes" />
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="from.meatEdible" />
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
    </van-row>
    <!-- 水产品（鱼虾)： -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type"> 水产品（鱼虾）： </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="from.aquaticCycle"  direction="horizontal">
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="from.aquaticTimes" />
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="from.aquaticEdible" />
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
    </van-row>
    <!-- 蛋类及制品： -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type"> 蛋类及制品： </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="from.eggsCycle"  direction="horizontal">
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="from.eggsTimes" />
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="from.eggsEdible" />
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
    </van-row>
    <!-- 奶及奶制品（鲜奶、奶粉、酸奶、奶酪等）： -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type">
        奶及奶制品（鲜奶、奶粉、酸奶、奶酪等）：
      </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="from.milchigsCycle"  direction="horizontal">
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="from.milchigsTimes" />
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="from.milchigsEdible" />
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
      <van-row class="m-btm"> （一袋200毫升纯牛奶约为206克） </van-row>
    </van-row>
    <!-- 大豆及豆制品（黄豆、绿豆、豆腐、豆腐皮等）： -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type">
        大豆及豆制品（黄豆、绿豆、豆腐、豆腐皮等）：
      </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="from.beansCycle"  direction="horizontal">
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="from.beansTimes" />
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="from.beansEdible" />
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
    </van-row>
    <!-- 坚果（瓜子仁、杏仁等）： -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type"> 坚果（瓜子仁、杏仁等）： </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="from.nutCycle"  direction="horizontal">
          <van-radio name="1" style="margin-right: 15px">每天</van-radio>
          <van-radio name="2" style="margin-right: 15px">每周</van-radio>
          <van-radio name="3" style="margin-right: 15px">每月</van-radio>
        </van-radio-group>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <van-stepper min="" v-model="from.nutTimes" />
          <div style="padding-left: 10px">次数</div>
        </van-row>
        <van-row style="padding-top: 10px" type="flex" align="center">
          <div style="padding-right: 10px">平均每次</div>
          <van-stepper min="" v-model="from.nutEdible" />
          <div style="padding-left: 10px">克</div>
        </van-row>
      </van-row>
    </van-row>

    <!-- 烹调油 -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type"> 烹调油： </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top" type="flex" align="center">
        <div style="padding-right: 10px">每天</div>
        <van-stepper min="" v-model="from.oilEdible" />
        <div style="padding-left: 10px">勺（家用搪瓷勺）</div>
      </van-row>
    </van-row>
    <!-- 食用盐 -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type"> 食用盐： </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top" type="flex" align="center">
        <div style="padding-right: 10px">每天</div>
        <van-stepper min="" v-model="from.saltEdible" />
        <div style="padding-left: 10px">茶勺（一小平勺）</div>
      </van-row>
    </van-row>
    <!-- 饮水 -->
    <van-row type="flex" align="center" style="padding: 10px 0px">
      <van-col span="1" style="padding-left: 10px">
        <div class="m-round"></div>
      </van-col>
      <van-col span="22" class="m-type">
        饮水（白开水、矿泉水、纯净水)：
      </van-col>
    </van-row>
    <!-- form -->
    <van-row class="t-form">
      <van-row class="m-top" type="flex" align="center">
        <div style="padding-right: 10px">每天</div>
        <van-stepper min="" v-model="from.waterEdible" />
        <div style="padding-left: 10px">杯（1杯≈240毫升）</div>
      </van-row>
    </van-row>
    <!-- 第二个问题 -->
    <van-row style="padding: 10px 5px 10px 5px">
      2 您更偏爱于哪种饮食口味
    </van-row>
    <van-row class="t-form">
      <van-row class="m-top">
        <van-radio-group v-model="from.taste" direction="horizontal">
          <van-radio name="1" style="margin-right: 12px">清淡</van-radio>
          <van-radio name="2" style="margin-right: 12px">甜</van-radio>
          <van-radio name="3" style="margin-right: 12px">麻辣</van-radio>
          <van-radio name="4" style="margin-right: 12px">咸</van-radio>
          <van-radio name="5">多调料</van-radio>
        </van-radio-group>
      </van-row>
    </van-row>
    <!-- 第三个问题 -->
    <van-row style="padding: 10px 5px 10px 5px">
      3 您有下列哪些饮食习惯（每周3次以上）
    </van-row>
    <van-row class="t-form">
      <van-row class="m-top">
        <van-checkbox-group v-model="from.aihao" direction="horizontal">
          <van-checkbox name="1" shape="square" style="margin-top: 5px"
            >熏制/腌制类</van-checkbox
          >
          <van-checkbox name="2" shape="square" style="margin-top: 5px"
            >甜点</van-checkbox
          >
          <van-checkbox name="3" shape="square" style="margin-top: 5px"
            >油炸食品</van-checkbox
          >
          <van-checkbox name="4" shape="square" style="margin-top: 5px"
            >肥肉/动物内脏</van-checkbox
          >
          <van-checkbox name="5" shape="square" style="margin-top: 5px"
            >吃零食（适量坚果除外)</van-checkbox
          >
          <van-checkbox name="6" shape="square" style="margin-top: 5px"
            >吃快餐</van-checkbox
          >
          <van-checkbox name="7" shape="square" style="margin-top: 5px"
            >订外卖</van-checkbox
          >
          <van-checkbox name="8" shape="square" style="margin-top: 5px"
            >喝粥（≥2次/天)</van-checkbox
          >
          <van-checkbox name="9" shape="square" style="margin-top: 5px"
            >吃夜宵</van-checkbox
          >
          <van-checkbox name="10" shape="square" style="margin-top: 5px"
            >三餐不规律</van-checkbox
          >
          <van-checkbox name="0" shape="square" style="margin-top: 5px"
            >以上均无</van-checkbox
          >
        </van-checkbox-group>
      </van-row>
    </van-row>
    <van-row style="padding: 30px 5px 10px 5px">
      <van-button
        style="border-radius: 16px"
        type="primary"
        size="large"
        color="#005DFF"
        @click="getDrink('drink')"
        >下一步</van-button
      >
    </van-row>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { addQuestionnaire } from "../api/index";
export default {
  name: "life-diet",
  data() {
    return {
      from: {
        step: 2,
        refinedGrainCycle: "",
        refinedGrainTimes: "",
        refinedGrainEdible: "",
        wholeGrainCycle: "",
        wholeGrainTimes: "",
        wholeGrainEdible: "",
        tubersCycle: "",
        tubersTimes: "",
        tubersEdible: "",
        vegetablesCycle: "",
        vegetablesTimes: "",
        vegetablesEdible: "",
        fruitCycle: "",
        fruitTimes: "",
        fruitEdible: "",
        meatCycle: "",
        meatTimes: "",
        meatEdible: "",
        aquaticCycle: "",
        aquaticTimes: "",
        aquaticEdible: "",
        eggsCycle: "",
        eggsTimes: "",
        eggsEdible: "",
        milchigsCycle: "",
        milchigsTimes: "",
        milchigsEdible: "",
        beansCycle: "",
        beansTimes: "",
        beansEdible: "",
        nutCycle: "",
        nutTimes: "",
        nutEdible: "",
        oilEdible: "",
        saltEdible: "",
        waterEdible: "",
        taste: "",
        habits: "",
        aihao:[],
      },
    };
  },
  methods: {
    getDrink(value) {
      this.from.habits = this.from.aihao.toString(",");
        addQuestionnaire(this.from).then((res) => {
          if (res.code == 200) {
            Toast.success("提交成功！");
            setTimeout(() => {
              this.$emit("getDrink", value);
           },1000);
            
          } else {
            Toast.fail("提交失败！");
          }
        });
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
.diet-box {
  width: 100%;
  overflow: hidden;
  font-family: PingFang SC ExtraLight;
}
.m-title {
  font-size: 0.6rem;
  padding: 10px 0px 10px 0px;
}
.m-round {
  height: 0.1rem;
  width: 0.1rem;
  border: 2px solid #f43140;
  border-radius: 50%;
  display: inline-block;
  line-height: 0.4rem;
}
.m-type {
  display: inline-block;
  line-height: 0.6rem;
  margin-left: 0.25rem;
}
.t-form {
  background-color: #ecf0fc;
  border-radius: 10px;
  overflow: hidden;
  font-size: 0.35rem;
}

.m-top {
  background-color: #f3f6fe;
  padding: 15px 10px 15px 15px;
}
.m-btm {
  padding: 10px;
  text-align: center;
  color: #666666;
  font-size: 0.3rem;
}
/deep/ .van-stepper__input {
  background-color: #f3f6fe;
  border-bottom: 1px #0b9aff solid;
  margin: 0px 5px 0px 5px;
  width: 80px;
  color: #1D1D1D;
  height: 20px;
}
/deep/ .van-stepper__minus{
  background-color: #0B9AFF;
  color: white;
  height: 20px;
  width: 20px;
}
/deep/ .van-stepper__plus{
  background-color: #0B9AFF;
  color: white;
  height: 20px;
  width: 20px;
}
</style>