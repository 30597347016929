<template>
  <!-- 膳食日记 -->
  <div class="box">
    <!-- 膳食日记 -->
    <van-row v-if="isDiary">
      <fitNav title="膳食日记"></fitNav>
      <van-row type="flex" class="b-title">
        <div style="padding: 10px">
          实际摄入量<span style="color: #4285f4">{{ energyKcal }}</span
          >千卡
        </div>
      </van-row>
      <van-row class="main">
        <el-date-picker
          v-model="date"
          align="right"
          type="date"
          :picker-options="pickerOptions"
          style="margin: 10px"
          @change="uptDate"
        >
        </el-date-picker>
        <!-- tabs -->
        <van-row class="m-tab">
          <van-tabs
            v-model="flag"
            background="#dbe3f9"
            title-active-color="#4285F4"
          >
            <van-tab
              :title="item.title"
              v-for="(item, index) in tabs"
              :key="index"
            >
                <van-row class="tab-con" v-if="(list.filter((r) => r.flag == flag)).length != 0">
                <van-row
                  type="flex"
                  justify="space-between"
                  align="center"
                  v-for="(list, index) in list.filter((r) => r.flag == flag)"
                  :key="index"
                  style="padding: 5px"
                >
                  <van-col span="14" style="text-align: left">
                    <span class="title">{{ GetFoodName(list.code) }}</span>
                    <span class="num">{{ list.weight }}克</span>
                  </van-col>
                  <van-col
                    span="10"
                    style="text-align: right; padding-right: 5px"
                  >
                    <van-button
                      @click="handleUpt(list)"
                      size="mini"
                      color="#4285F4"
                      plain
                      >更改克数</van-button
                    >
                    <van-button
                      @click="handleSwap(list.code)"
                      size="mini"
                      color="#FFA044"
                      plain
                      >交换食物</van-button
                    >
                  </van-col>
                </van-row>
              </van-row>
            </van-tab>
          </van-tabs>
        </van-row>
        <van-row style="padding: 15px 5px 5px 5px">
          <van-button
            size="large"
            @click="getAddFood"
            color="#00C0FF"
            round
            style="height: 40px"
          >
            新增食物
          </van-button>
        </van-row>
        <van-row style="padding: 5px">
          <van-button
            @click="handleSave"
            size="large"
            color="#005DFF"
            round
            style="height: 40px"
          >
            保存日记
          </van-button>
        </van-row>
      </van-row>
    </van-row>
    <!-- 食物选择 -->
    <van-row v-if="isFoodList">
      <van-row class="foodNav">
        <van-icon name="arrow-left" size="24px" @click="goBack"></van-icon>
      </van-row>
      <van-row class="main">
        <!-- 查询 -->
        <van-row
          style="margin-bottom: 10px"
          type="flex"
          justify="space-between"
          align="center"
        >
          <van-field
            class="nameCell"
            v-model="foodName"
            clearable
            placeholder="请输入食物名"
          />
          <van-button
            @click="getByName"
            class="btn"
            size="mini"
            round
            color="#005DFF"
            >查询</van-button
          >
        </van-row>
        <van-row
          v-for="(item, index) in searchData"
          :key="index"
          class="con"
          type="flex"
          justify="space-between"
          align="center"
        >
          <van-row class="title">{{ item.foodName }}</van-row>
          <van-button
            size="mini"
            round
            style="padding: 10px 15px"
            color="#005DFF"
            @click="getSel(item.foodName, item.foodCode)"
            >选择</van-button
          >
        </van-row>
      </van-row>
    </van-row>
    <van-popup v-model="showPopup">
      <van-field v-model="newWeight" placeholder="请输入克数"></van-field>
      <van-row type="flex" justify="space-between" style="padding: 10px">
        <van-button size="small" @click="getUpt(newWeight)" color="#4285F4"
          >确定</van-button
        >
        <van-button size="small" @click="getQx">取消</van-button>
      </van-row>
    </van-popup>
  </div>
</template>
  
  <script>
import fitNav from "@/components/fitNav.vue";
import {
  getFoodByDate,
  getFoodByName,
  getFoodList,
  saveFoodDiary,
} from "../api/diary";
import { Toast } from "vant";
export default {
  name: "dietDiary",
  data() {
    return {
      //日记是否显示
      isDiary: true,
      // 食物选择是否显示
      isFoodList: false,
      //是否交换
      isSwap: false,
      //日期
      date: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      flag: 0,
      tabs: [
        {
          title: "早餐",
        },
        {
          title: "加餐",
        },
        {
          title: "午餐",
        },
        {
          title: "晚餐",
        },
      ],
      list: [],
      //所有食物
      data: [],
      //
      searchData: [],
      //模糊查询
      foodName: "",
      //克数
      weight: 100,
      //
      foodList: [],
      //
      energyKcal: "",
      swapItem: {},
      //更改克数
      showPopup: false,
      newWeight: "",
      currentItem: {},
    };
  },
  components: {
    fitNav,
  },
  mounted() {
    //获取所有食物
    getFoodList().then((res) => {
      this.data = res.data;
    });
    //获取当日日期并查询
    let date = new Date(),
      year = date.getFullYear(), //获取完整的年份(4位)
      month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
      strDate = date.getDate(); // 获取当前日(1-31)
    if (month < 10) month = `0${month}`; // 如果月份是个位数，在前面补0
    if (strDate < 10) strDate = `0${strDate}`;
    this.date = `${year}-${month}-${strDate}`;
    this.getByDate();
  },

  methods: {
    // 模糊查询
    getByName() {
      getFoodByName(this.foodName).then((res) => {
        this.searchData = res.data;
      });
    },
    //选择食物
    getSel(foodName, foodCode) {
      this.isDiary = true;
      this.isFoodList = false;
      let from = {
        flag: this.flag,
        code: foodCode,
        name: foodName,
        weight: this.weight,
      };
      if ((this.isSwap = true)) {
        this.list = this.list.filter((item) => item != this.swapItem);
        this.swapItem = {};
      }
      this.list.push(from);
    },
    //根据日期查看
    getByDate() {
      getFoodByDate(this.date).then((res) => {
        if (!res.data) {
          this.list = []
        } else {
          this.GetArrayByFelements(res.data.fElements).forEach((item) => {
            this.list.push({
              ...item,
            });
          });
          this.energyKcal = res.data.energyKcal;
        }
      });
    },
    //新增食物
    getAddFood() {
      this.isDiary = false;
      this.isFoodList = true;
      this.isSwap = false;
      this.searchData = this.data;
      this.foodName = "";
    },
    GetArrayByFelements(felements) {
      let res = [];
      let array = felements.split(";");
      array.forEach((str) => {
        if (str) {
          let item = this.DeserializeItem(str);
          res.push({
            ...item,
          });
        }
      });
      return res;
    },
    DeserializeItem(str) {
      let array = str.split("_");
      let res = {
        flag: array[0],
        code: array[1],
        weight: array[2],
      };
      return res;
    },
    //保存日记
    handleSave() {
      let array = [];
      let error = "";
      this.list.forEach((item) => {
        if (!item.weight) {
          let foodName = this.GetFoodName(item.code);
          let flagName = this.flagList.find((r) => r.flag == item.flag).name;
          error = `${flagName}中【${foodName}】未输入克数`;
        }
        if (item.weight != "0") {
          array.push(this.SerializeItem(item));
        }
      });

      if (error) {
        Toast.fail("失败！", error);
        return;
      }
      let from = {
        cTime: this.date,
        felements: array.join(";"),
      };
      saveFoodDiary(from).then((r) => {
        if (r.code == 200) {
          Toast.success("保存成功！");
        } else {
          Toast.fail("保存失败！");
        }
      });
    },
    //对象序列化成字符串{flag:'1',code:'061101','weight':'200'}=>1_061101_200
    SerializeItem(item) {
      return `${item.flag}_${item.code}_${item.weight}`;
    },
    GetFoodName(code) {
      let item = this.data.find((r) => r.foodCode == code);
      if (item) {
        return item.foodName;
      } else {
        return "-";
      }
    },
    //
    goBack() {
      this.isDiary = true;
      this.isFoodList = false;
    },
    //交换食物
    handleSwap(code) {
      this.swapItem = {};
      this.isDiary = false;
      this.isFoodList = true;
      this.isSwap = true;
      this.searchData = this.data;
      this.foodName = "";
      let item = this.list.find((res) => res.code == code);
      this.swapItem = item;
    },
    //更改克数
    handleUpt(item) {
      this.showPopup = true;
      this.currentItem = item;
    },
    getUpt(newWeight) {
      if (isNaN(Number(newWeight))) {
        Toast.fail("请输入克数！");
        return;
      }
      let item = this.list.find(
        (r) =>
          r.flag == this.currentItem.flag && r.code == this.currentItem.code
      );
      item.weight = newWeight;
      this.showPopup = false;
    },
    getQx() {
      this.showPopup = false;
    },
    //
    uptDate(val) {
      let date = new Date(val),
        year = date.getFullYear(), //获取完整的年份(4位)
        month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
        strDate = date.getDate(); // 获取当前日(1-31)
      if (month < 10) month = `0${month}`; // 如果月份是个位数，在前面补0
      if (strDate < 10) strDate = `0${strDate}`;
      this.date = `${year}-${month}-${strDate}`;
      this.getByDate();
    },
  },
};
</script>
  
  <style scoped>
* {
  padding: 0;
  margin: 0;
}
.box {
  background: url("../assets/bkg.png") no-repeat;
  background-size: 100%;
  width: 100%;
  overflow: hidden;
  padding-top: 2rem;
}
.b-title {
  padding: 10px;
  font-size: 0.5rem;
}
.main {
  background: #dbe3f9;
  border-radius: 32px 32px 0px 2px;
  font-family: PingFang SC ExtraLight;
  color: #1d1d1d;
  padding: 1rem 10px 0.5rem 10px;
  min-height: calc(100vh - 5.25rem);
  line-height: 0.6rem;
}
.date {
  background-color: #f3f6fe;
  padding: 5px 10px;
  border-radius: 32px;
}
.dataCell {
  width: 3rem;
  height: 30px;
  background-color: #f3f6fe;
  padding-top: 2px;
}
/deep/ .van-field__control {
  text-align: center;
  color: #4285f4;
}

.tab-con {
  border-radius: 32px;
  padding: 10px;
  overflow: hidden;
  background-color: #f3f6fe;
  margin-top: 10px;
}
.title,
.num {
  display: inline-block;
  padding: 5px;
}
.title {
  color: #4285f4;
  font-size: 0.5rem;
}
.num {
  color: #a6a9b3;
}
/deep/ .van-tab__text--ellipsis {
  font-size: 0.4rem;
}
.con {
  background-color: #f3f6fe;
  border-radius: 32px;
  padding: 5px;
  margin-bottom: 10px;
}
.title {
  font-size: 0.4rem;
  padding-left: 15px;
  color: #4285f4;
}

.nameCell {
  width: 5rem;
  border-radius: 32px;
  height: 1rem;
  margin-left: 10px;
  line-height: 20px;
  padding-top: 8px;
}
.btn {
  margin-right: 10px;
  font-size: 0.45rem;
  padding: 15px 20px;
}
.foodNav {
  position: fixed;
  top: 0px;
  padding: 10px 0px;
  width: 100%;
  background-color: white;
}
/deep/ .van-tabs__nav {
  background-color: #dbe3f9;
}
/deep/ .van-tabs__line{
  background-color: #4285F4;
}
</style>