<template>
  <!-- 首页 -->
  <div class="box">
    <!-- logo、图像 -->
    <publicNav :text="['4in1']" :isOpen="isOpen"></publicNav>
    <!-- 主体 -->
    <van-row class="b-main">
      <!-- 知情同意书 -->
      <info-consent></info-consent>
    </van-row>
  </div>
</template>

<script>
import infoConsent from "../components/info-Consent.vue";
import publicNav from "@/components/publicNav.vue";
import { getCookie } from "@/utils/cookie";
export default {
  name: "index",
  data() {
    return {
      text: "亲爱的小伙伴，欢迎来到上海君石生命科学研究院的“企业员工慢病健康管理“”服务项目！我们的目标是帮助您管理主要慢性病危险因素，包括血压、血脂、血糖、超重和肥胖。通过提供个性化的生活方式指导，以健康体重为核心，特别关注膳食和身体活动，帮助您科学管理这些危险因素。我叫爱思小助，在这里，我会为您提供专业的健康管理服务帮助，远离慢性病从改变生活方式开始，让您拥有更健康的生活！由于本项目的特殊性质，患有进行性疾病、活动障碍、严重的精神疾病、重度肝病或肾病、恶性肿瘤、年龄18岁以下、60岁以上及孕产妇的个人，不能参加本项目，详情请参阅《知情同意书》中列出的不适宜参加本项目的疾病。我深切地关心您的健康状况，希望您能选择适合自身的健康计划。您可以阅读下面的知情同意书，了解项目详情",
      isOpen: "",
    };
  },
  components: {
    infoConsent,
    publicNav,
  },
  mounted() {
    this.isOpen = getCookie("token");
  },
};
</script>

<style scoped>
* {
  padding: 0px;
  margin: 0px;
}
.box {
  width: 100%;
  overflow: hidden;
  background: url("../assets/bkg.png") no-repeat;
  background-size: 100%;
  padding: 0;
}
.b-main {
  margin-top: 6.5rem;
  padding: 0.6rem 10px 0.55rem 10px;
  overflow: hidden;
  background-color: #dbe3f9;
  border-radius: 32px 32px 0px 0px;
  font-size: 0.4rem;
  font-family: PingFang SC ExtraLight;
  color: #1d1d1d;
  line-height: 0.6rem;
}
</style>