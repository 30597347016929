<template>
  <div>
    <van-field
      :value="value"
      :name="label"
      :label="label"
      :placeholder="label"
      readonly
      @click="show = true"
      :rules="rules"
    />
    <van-popup v-model="show" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        :min-date="minDate"
        @confirm="confirm"
        @cancel="cancel"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  props: ["value", "label", "rules"],
  data() {
    return {
      currentDate: null,
      minDate: new Date(1970, 1, 1),
      show: false,
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.currentDate = new Date(v);
      }
    },
  },
  mounted() {},
  methods: {
    confirm(value) {
      let date = this.$dateformat(value);
      this.$emit("input", date);
      this.show = false;
    },
    cancel() {
      this.show = false;
    },
  },
};
</script>

<style>
</style>