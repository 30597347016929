var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('fitNav',{attrs:{"title":"个人中心"}}),_c('div',{staticClass:"main"},[_c('van-row',{staticClass:"m-form",attrs:{"type":"flex","justify":"center"}},[_c('van-form',{ref:"form"},[_c('van-field',{attrs:{"name":"姓名","label":"姓名","placeholder":"姓名","rules":[{ required: true, message: '请填写姓名' }]},model:{value:(_vm.userForm.name),callback:function ($$v) {_vm.$set(_vm.userForm, "name", $$v)},expression:"userForm.name"}}),_c('van-field',{attrs:{"name":"身份证","label":"身份证","placeholder":"身份证","rules":[{ required: true, message: '请填写身份证' }]},model:{value:(_vm.userForm.idNo),callback:function ($$v) {_vm.$set(_vm.userForm, "idNo", $$v)},expression:"userForm.idNo"}}),_c('MyDatepicker',{attrs:{"label":"出生日期","rules":[{ required: true, message: '请填写出生日期' }]},model:{value:(_vm.userForm.birthday),callback:function ($$v) {_vm.$set(_vm.userForm, "birthday", $$v)},expression:"userForm.birthday"}}),_c('van-field',{attrs:{"name":"radio","label":"性别"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-radio-group',{attrs:{"direction":"horizontal"},model:{value:(_vm.userForm.sex),callback:function ($$v) {_vm.$set(_vm.userForm, "sex", $$v)},expression:"userForm.sex"}},[_c('van-radio',{staticStyle:{"margin-right":"15px"},attrs:{"name":"1"}},[_vm._v("男")]),_c('van-radio',{attrs:{"name":"2"}},[_vm._v("女")])],1)]},proxy:true}])}),_c('van-field',{attrs:{"name":"手机号","label":"手机号","placeholder":"手机号","rules":[
            { required: true, message: '请填写手机号' },
            {
              pattern: /^1[3456789]\d{9}$/,
              message: '手机号码格式错误！',
            },
          ]},model:{value:(_vm.userForm.phone),callback:function ($$v) {_vm.$set(_vm.userForm, "phone", $$v)},expression:"userForm.phone"}})],1)],1),_c('div',{staticStyle:{"margin-top":"15px"}},[_c('van-button',{staticStyle:{"height":"40px"},attrs:{"type":"primary","block":"","round":"","color":"#005DFF","native-type":"submit"},on:{"click":_vm.onSubmit}},[_vm._v("完成")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }