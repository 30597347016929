import request from "../utils/request"

// 日记

//膳食日记

//获取选择食物列表
export function getFoodList() {
    return request({
        url: '/web/food/searchElements',
        method: 'get',
    })
}

//根据食物名字查询
export function getFoodByName(foodName) {
    return request({
        url: '/web/food/searchElements/' + foodName,
        method: 'get'
    })
}
//根据日期获取日记
export function getFoodByDate(date) {
    return request({
        url: '/web/food/getFoodDiary/' + date,
        method: 'get'
    })
}
//保存日记
export function saveFoodDiary(data) {
    return request({
        url: '/web/food/saveFoodDiary',
        method: 'post',
        data:data
    })
}

//运动日记

//获取各类运动
export function getSportList() {
    return request({
        url: '/web/sport/dict',
        method: 'get',
    })
}

//根据日期获取运动日记
export function getSportByDate(date) {
    return request({
        url: '/web/sport/' + date,
        method: 'get'
    })
}

//保存运动日记

export function saveSportDiary(data) {
    return request({
        url: '/web/sport/save',
        method: 'post',
        data:data
    })
}

//获取用户体重
export function getUserWeight() {
    return request({
        url: '/web/member/getMemberRecordInfo',
        method: 'get',
    })
}