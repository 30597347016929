<template>
  <div class="smok-box">
    <van-row class="m-title">吸烟</van-row>
    <van-row style="padding: 5px"> 1 近30天内您吸烟吗？ </van-row>
    <van-row class="m-form">
      <van-radio-group v-model="form.smoking" @change="smokChange">
        <van-radio name="1" shape="square" style="margin: 5px 15px 0px 0px"
          >是</van-radio
        >
        <van-radio name="0" shape="square" style="margin: 5px 15px 0px 0px"
          >否</van-radio
        >
      </van-radio-group>
    </van-row>
	<van-row v-show="form.smoking != '' && form.smoking == 0">
		<van-row style="padding: 5px">2 是否已戒烟</van-row>
		<van-row class="m-form">
		  <van-radio-group v-model="form.quitSmoking" @change="quitSmokChange">
		    <van-radio name="1" shape="square" style="margin: 5px 15px 0px 0px"
		      >是
		    </van-radio>
		    <van-radio name="0" shape="square" style="margin: 5px 15px 0px 0px"
		      >否</van-radio
		    >
		  </van-radio-group>
		</van-row>
	</van-row>
	
	<van-row v-show="form.quitSmoking != '' && form.quitSmoking == 1">
		<van-row style="padding: 5px"> 3 您的戒烟年龄？ </van-row>
		<van-row class="m-form">
		  <van-row type="flex" align="center">
		    <van-stepper min="" v-model="form.quitSmokingAge"/>
		    <div style="padding-left: 10px">岁 )</div>
		  </van-row>
		</van-row>
	</van-row>
	
	<van-row v-show="(form.smoking != '' && form.smoking == 1) || (form.quitSmoking != '' && form.quitSmoking == 0)">
		<van-row style="padding: 5px"> {{ smokeCountNum }} 您平均每天抽多少支香烟？ </van-row>
		<van-row class="m-form">
		  <van-row type="flex" align="center">
		    <van-stepper min="" v-model="form.smokeCount"/>
		    <div style="padding-left: 10px">支</div>
		  </van-row>
		</van-row>
	</van-row>
    
	<van-row v-show="(form.smoking != '' && form.smoking == 1) || (form.quitSmoking != '' && form.quitSmoking == 0)">
		<van-row style="padding: 5px"> {{ smokeYearNum }} 您已经吸烟多少年了？ </van-row>
		<van-row class="m-form">
		  <van-row type="flex" align="center">
		    <van-stepper v-model="form.smokeYear" min="" />
		    <div style="padding-left: 10px">年</div>
		  </van-row>
		</van-row>
	</van-row>
    
    <van-row style="padding: 5px">
      {{ aroundSmokeNum }} 和您一起工作的同事或一起生活的家人中是否有人经常吸烟？
    </van-row>
    <van-row class="m-form">
      <van-radio-group v-model="form.aroundSmoke" direction="horizontal">
        <van-radio name="1" shape="square" style="margin-right: 15px"
          >是</van-radio
        >
        <van-radio name="0" shape="square">否</van-radio>
      </van-radio-group>
    </van-row>
    <van-row style="padding: 30px 5px 10px 5px">
      <van-button
        style="border-radius: 16px"
        type="primary"
        size="large"
        color="#005DFF"
        @click="getSports('sports')"
        >下一步</van-button
      >
    </van-row>
  </div>
</template>

<script>
import { Toast } from "vant";
import { addQuestionnaire } from "../api/index";
export default {
  name: "smoking",
  data() {
    return {
      form: {
        step: 4,
        smoking: "",
        quitSmoking: "",
        quitSmokingAge: "",
        smokeCount: "",
        smokeYear: "",
        aroundSmoke: "",
      },
	  smokeCountNum: 0,
	  smokeYearNum: 0,
	  aroundSmokeNum: 2,
    };
  },
  methods:{ 
    smokChange(val){
      if(val == 1){
        this.form.quitSmoking = "";
        this.form.quitSmokingAge = "";
		this.smokeCountNum = 2;
		this.smokeYearNum = 3;
		this.aroundSmokeNum = 4;
      } else if(val == 0){
        this.form.quitSmoking = "";
        this.form.quitSmokingAge = "";
        this.form.smokeCount="",
        this.form.smokeYear = "";
		this.aroundSmokeNum = 3
      }
    },
	quitSmokChange(val) {
		if (val == 1) {
			this.aroundSmokeNum = 4
			this.form.quitSmokingAge = "18";
			this.form.smokeCount="",
			this.form.smokeYear = "";
		} else if(val == 0) {
			this.smokeCountNum = 3;
			this.smokeYearNum = 4;
			this.aroundSmokeNum = 5;
			this.form.quitSmokingAge = "";
			this.form.smokeCount="1",
			this.form.smokeYear = "1";
		}
	},
    getSports(value){
      addQuestionnaire(this.form).then((res) => {
        if (res.code == 200) {
          Toast.success("提交成功！");
          setTimeout(() => {
            this.$emit("getSports", value);
           },1000)
        } else {
          Toast.fail("提交失败！");
        }
      });
    }
  }
};
</script>

<style scoped>
.smok-box {
  overflow: hidden;
  width: 100%;
  font-family: PingFang SC ExtraLight;
}
.m-title {
  font-size: 0.6rem;
  padding: 10px 0px 10px 0px;
}
.m-form {
  font-size: 0.35rem;
  border-radius: 10px;
  background-color: #f3f6fe;
  padding: 15px;
  margin: 5px 0px 10px 0px;
}
/deep/ .van-stepper__input {
  background-color: #f3f6fe;
  border-bottom: 1px #0b9aff solid;
  margin: 0px 5px 0px 5px;
  width: 80px;
  color: #1D1D1D;
  height: 20px;
}
/deep/ .van-stepper__minus{
  background-color: #0B9AFF;
  color: white;
  height: 20px;
  width: 20px;
}
/deep/ .van-stepper__plus{
  background-color: #0B9AFF;
  color: white;
  height: 20px;
  width: 20px;
}
</style>