<template>
  <div class="sports-box">
    <van-row class="m-title">身体活动</van-row>
    <!-- 第一个问题 -->
    <van-row style="padding: 5px">
      1 请您回忆过去一周，累积做了多少分钟的中等强度有氧身体活动？
      <span class="t-des">
        （有氧身体活动是指躯干、四肢等大肌肉群参与为主的、有节律、时间较长、能够维持在一个稳定状态的身体活动，如跑步、快走、游泳、滑冰、跳绳、骑车、跳舞、打篮球、踢足球等。中等强度身体活动是指感觉有点累，心跳和呼吸都加快，轻微出汗，可以轻松说话的身体活动）
      </span>
    </van-row>
    <van-row class="m-form">
      <van-row type="flex" align="center">
        <van-stepper min="" v-model="form.moderateSweat" />
        <div style="padding-left: 10px">分钟</div>
      </van-row>
    </van-row>
    <!-- 第二个问题 -->
    <van-row style="padding: 5px">
      2 过去一周，您累计做了多少分钟的高强度有氧身体活动？
      <span class="t-des">
        高强度身体活动是指我感觉很累，全身出了很多汗，说话较费力或停止运动才能说话，呼吸急促，大口喘气，心跳很快的身体活动）
      </span>
    </van-row>
    <van-row class="m-form">
      <van-row type="flex" align="center">
        <van-stepper min="" v-model="form.highSweat" />
        <div style="padding-left: 10px">分钟</div>
      </van-row>
    </van-row>
    <!-- 第三个问题 -->
    <van-row style="padding: 5px">
      3 过去一周，您主动锻炼累计多长时间？
      <span class="t-des"> （主动锻炼是指以锻炼健身为目的的身体活动） </span>
    </van-row>
    <van-row class="m-form">
      <van-row type="flex" align="center">
        <van-stepper min="" v-model="form.totalTime" />
        <div style="padding-left: 10px">分钟</div>
      </van-row>
    </van-row>
    <!-- 第四个问题 -->
    <van-row style="padding: 5px">
      4 过去一周，您最主要的出行方式是什么？
    </van-row>
    <van-row class="m-form">
      <van-row style="margin-bottom: 5px">
        <div>A 交通方式</div>
        <van-radio-group v-model="form.travelMode" direction="horizontal">
          <van-radio name="1" shape="square" style="margin: 5px 15px 0px 0px"
            >走路</van-radio
          >
          <van-radio name="2" shape="square" style="margin: 5px 15px 0px 0px"
            >骑自行车</van-radio
          >
          <van-radio name="3" shape="square" style="margin: 5px 15px 0px 0px"
            >坐车</van-radio
          >
          <van-radio name="4" shape="square" style="margin: 5px 15px 0px 0px"
            >开车</van-radio
          >
        </van-radio-group>
      </van-row>
      <van-row style="margin-bottom: 5px">
        <div>B 强度</div>
        <van-radio-group v-model="form.travelIntensity" direction="horizontal">
          <van-radio name="1" shape="square" style="margin: 5px 15px 0px 0px"
            >低强度</van-radio
          >
          <van-radio name="2" shape="square" style="margin: 5px 15px 0px 0px"
            >中等强度</van-radio
          >
          <van-radio name="3" shape="square" style="margin: 5px 15px 0px 0px"
            >高强度</van-radio
          >
          <van-radio name="4" shape="square" style="margin: 5px 15px 0px 0px"
            >静态行为（坐着、靠着、躺着，不包括睡觉）</van-radio
          >
        </van-radio-group>
      </van-row>
      <van-row>
        <div>C 累计持续时间</div>
        <van-row type="flex" align="center">
          <van-stepper min="" v-model="form.travelTime" />
          <div style="padding-left: 10px">分钟</div>
        </van-row>
      </van-row>
    </van-row>
    <!-- 后四个问题 -->
    <van-row style="padding: 5px">
      5 过去一周，您累计总静态行为时间是多少小时？
      <span class="t-des">
        （坐着、靠着或躺着做的活动，包括工作时间、闲暇时间的静态行为）
      </span>
    </van-row>
    <van-row class="m-form">
      <van-row type="flex" align="center">
        <van-stepper min="" v-model="form.staticTotalTime" />
        <div style="padding-left: 10px">小时</div>
      </van-row>
    </van-row>
    <van-row style="padding: 5px">
      6 过去一周，您累计闲暇静态行为时间是多少小时？
      <span class="t-des">
        （闲暇时间坐着、靠着或躺着做的活动，不包括工作时间的静态行为）
      </span>
    </van-row>
    <van-row class="m-form">
      <van-row type="flex" align="center">
        <van-stepper min="" v-model="form.staticLeisureTime" />
        <div style="padding-left: 10px">小时</div>
      </van-row>
    </van-row>
    <van-row style="padding: 5px">
      7 过去一周，您累计视屏时间是多少小时？
      <span class="t-des"> （如：看电视/手机/电脑/平板电脑等） </span>
    </van-row>
    <van-row class="m-form">
      <van-row type="flex" align="center">
        <van-stepper min="" v-model="form.screenTime" />
        <div style="padding-left: 10px">小时</div>
      </van-row>
    </van-row>
    <van-row style="padding: 5px">
      8 过去一周，您累计睡眠时间是多少小时？
      <span class="t-des"> （不包括在床上没有睡着的时间） </span>
    </van-row>
    <van-row class="m-form">
      <van-row type="flex" align="center">
        <van-stepper min="" v-model="form.bedtime" />
        <div style="padding-left: 10px">小时</div>
      </van-row>
    </van-row>
    <van-row style="padding: 30px 5px 10px 5px">
      <van-button
        style="border-radius: 16px"
        type="primary"
        size="large"
        color="#005DFF"
        @click="getOver"
        >完成</van-button
      >
    </van-row>
  </div>
</template>
  
  <script>
import { Toast } from "vant";
import { addQuestionnaire } from "../api/index";
export default {
  name: "life-diet",

  data() {
    return {
      form: {
        step: 5,
        moderateSweat: "",
        highSweat: "",
        totalTime: "",
        travelMode: "",
        travelIntensity: "",
        travelTime: "",
        staticTotalTime: "",
        staticLeisureTime: "",
        screenTime: "",
        bedtime: "",
      },
    };
  },
  methods: {
    getOver() {
      addQuestionnaire(this.form).then((res) => {
        if (res.code == 200) {
          Toast.success("提交成功！");
          setTimeout(() => {
            this.$router.push({
              path: "/reports",
              query: {
                title: "lifeOver",
              },
            });
          }, 1000);
        } else {
          Toast.fail("提交失败！");
        }
      });
    },
  },
};
</script>
  
  <style scoped>
* {
  padding: 0;
  margin: 0;
}
.sports-box {
  width: 100%;
  overflow: hidden;
  font-family: PingFang SC ExtraLight;
}
.m-title {
  font-size: 0.6rem;
  padding: 10px 0px 10px 0px;
}
.m-form {
  font-size: 0.35rem;
  border-radius: 10px;
  background-color: #f3f6fe;
  padding: 15px;
  margin: 5px 0px 10px 0px;
}
.t-des {
  color: #666666;
  font-size: 0.4rem;
  font-family: PingFang SC ExtraLight;
}
/deep/ .van-stepper__input {
  background-color: #f3f6fe;
  border-bottom: 1px #0b9aff solid;
  margin: 0px 5px 0px 5px;
  width: 80px;
  color: #1D1D1D;
  height: 20px;
}
/deep/ .van-stepper__minus{
  background-color: #0B9AFF;
  color: white;
  height: 20px;
  width: 20px;
}
/deep/ .van-stepper__plus{
  background-color: #0B9AFF;
  color: white;
  height: 20px;
  width: 20px;
}
</style>