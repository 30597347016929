import Vue from 'vue'
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");

/**
 * 格式化日期
 */
Vue.prototype.$dayjs = dayjs;

function dateformat(value, format = 'YYYY-MM-DD') {
    if (!value) {
        return '-';
    }
    if (value == '-') {
        return '-'
    }
    return dayjs(value).format(format);
}
Vue.prototype.$dateformat = dateformat
Vue.filter('datetime', dateformat);