<template>
  <!-- 体格检查 -->
  <div class="phy-box">
    <van-row class="title">体格检查</van-row>
    <van-row class="p-label"> 1 身高 </van-row>
    <van-row type="flex" align="center" class="phy-form">
      <van-stepper min="" v-model="form.height" />
      <div style="padding-left: 10px">厘米（cm）</div>
    </van-row>
    <van-row class="p-label"> 2 体重 </van-row>
    <van-row type="flex" align="center" class="phy-form">
      <van-stepper min="" v-model="form.weight" />
      <div style="padding-left: 10px">公斤（kg）</div>
    </van-row>
    <van-row class="p-label"> 3 腰围 </van-row>
    <van-row type="flex" align="center" class="phy-form">
      <van-stepper min="" v-model="form.waist" />
      <div style="padding-left: 10px">厘米（cm）</div>
    </van-row>
    <van-row class="p-label"> 4 收缩压 </van-row>
    <van-row type="flex" align="center" class="phy-form">
      <van-stepper min="" v-model="form.highPressure" />
      <div style="padding-left: 10px">mmHg</div>
    </van-row>
    <van-row class="p-label"> 5 舒张压 </van-row>
    <van-row type="flex" align="center" class="phy-form">
      <van-stepper min="" v-model="form.lowPressure" />
      <div style="padding-left: 10px">mmHg</div>
    </van-row>
    <van-row class="title"> 血液生化检查 </van-row>
    <van-row class="p-label"> 1 空腹血糖（GLU） </van-row>
    <van-row type="flex" align="center" class="phy-form">
      <van-stepper min="" step="0.1" v-model="form.bloodGlucose" />
      <div style="padding-left: 10px">mmol/L</div>
    </van-row>
    <van-row class="p-label"> 2 总胆固醇（TC） </van-row>
    <van-row type="flex" align="center" class="phy-form">
      <van-stepper min="" step="0.1" v-model="form.tc" />
      <div style="padding-left: 10px">mmol/L</div>
    </van-row>
    <van-row class="p-label"> 3 甘油三酯（TG） </van-row>
    <van-row type="flex" align="center" class="phy-form">
      <van-stepper min="" step="0.1" v-model="form.tg" />
      <div style="padding-left: 10px">mmol/L</div>
    </van-row>
    <van-row class="p-label"> 4 高密度脂蛋白胆固醇（HDL） </van-row>
    <van-row type="flex" align="center" class="phy-form">
      <van-stepper min="" step="0.1" v-model="form.hdl" />
      <div style="padding-left: 10px">mmol/L</div>
    </van-row>
    <van-row class="p-label"> 5 低密度脂蛋白胆固醇（HDL） </van-row>
    <van-row type="flex" align="center" class="phy-form">
      <van-stepper min="" step="0.1" v-model="form.ldl" />
      <div style="padding-left: 10px">mmol/L</div>
    </van-row>
    <van-row style="padding: 30px 5px 10px 5px">
      <van-button
      v-if="isWan"
        style="border-radius: 16px"
        type="primary"
        size="large"
        color="#005DFF"
        @click="getSubmit"
        >完成</van-button
      >
      <van-button
      v-if="isOver"
        style="border-radius: 16px"
        type="primary"
        plain
        size="large"
        color="#666"
        disabled
        >已完成</van-button
      >
    </van-row>
  </div>
</template>

<script>
import { addQuestionnaire } from "../api/index"
import { Toast } from 'vant'
export default {
  name: "physique-info",
  data() {
    return {
      form: {
        step:1,
        height: "170",
        weight: "60",
        waist: "80",
        highPressure: "120",
        lowPressure: "80",
        bloodGlucose: "5.0",
        tc: "5.0",
        tg: "1.5",
        hdl: "1.5",
        ldl: "2.6",
      },
      isWan:true,
      isOver:false,
    };
  },
  methods:{ 
    getSubmit(){
      addQuestionnaire(this.form).then(res=>{
        if(res.code == 200){
          Toast.success('提交成功！');
          this.isOver = true;
          this.isWan = false;
          // localStorage.setItem('recordId',res.data.recordId)
          this.$setCookie("recordId",res.data.recordId,1);
          setTimeout(()=>{
            this.$router.push({
              path:"/reports",
              query:{
                title:"basicOver"
              }
            })
          })
        }else{
          Toast.fail('提交失败！');
        }
      })
    }
  }
};
</script>

<style scoped>
.phy-box {
  width: 100%;
  overflow: hidden;
  font-family: PingFang SC ExtraLight;
}
.title {
  font-size: 0.5rem;
  padding: 10px 0px 10px 0px;
}
.phy-form {
  padding: 10px;
  background-color: #f3f6fe;
  border-radius: 10px;
  font-size: 0.35rem;
  /* margin-bottom: 10px; */
}
.p-label {
  padding: 10px 0px 10px 0px;
}
/deep/ .van-stepper__input {
  background-color: #f3f6fe;
  border-bottom: 1px #0b9aff solid;
  margin: 0px 5px 0px 5px;
  width: 80px;
  color: #1D1D1D;
  height: 20px;
}
/deep/ .van-stepper__minus{
  background-color: #0B9AFF;
  color: white;
  height: 20px;
  width: 20px;
}
/deep/ .van-stepper__plus{
  background-color: #0B9AFF;
  color: white;
  height: 20px;
  width: 20px;
}
</style>