<template>
  <!-- 今日任务总结 -->
  <div class="box">
    <!-- 导航条 -->
    <fit-nav title=""></fit-nav>
    <van-row v-if="noData" >
        无用户记录
      </van-row>
      <van-row v-else>
    <van-row class="main">
      <!-- 能量 -->
      <van-row class="m-kcal">
        <van-row type="flex" align="center" justify="space-between">
          <van-col span="8" style="text-align: center">
            <div style="padding: 5px 0px">
              <span style="color: #4285f4">摄入</span>（千卡）
            </div>
            <div style="font-size: 35px">{{ summary?.intakeEnergy }}</div>
          </van-col>
          <van-col span="8" style="text-align: center">
            <van-circle
              v-model="currentRate"
              :rate="0"
              :speed="100"
              :text="'目标总能量\n' + summary?.energyLevel"
              layer-color="linear-gradient(38deg, #4285F4, #FBA55B, #FFC88D)"
            />
          </van-col>
          <van-col span="8" style="text-align: center">
            <div><span style="color: #4285f4">燃烧</span>（千卡）</div>
            <div style="font-size: 35px">{{ summary?.useupEnergy }}</div>
          </van-col>
        </van-row>
        <!-- <van-row class="kacl-txt">
          <span class="t-t">多动了</span>1000卡
        </van-row> -->
      </van-row>
      <van-row class="m-kcal">
        <van-row class="m-txt">
          {{ summary?.feedback }}
        </van-row>
      </van-row>
      <van-row class="m-kcal">
        <van-row class="k-title"> 
          <div v-if="summary?.dietStandard == 1">膳食达标</div>
          <div v-else>膳食不达标</div>
        </van-row>
        <van-row gutter="20" class="k-tab">
          <van-col span="12" class="t-title">主要营养素分析</van-col>
          <van-col span="12" class="t-title">推荐量</van-col>
        </van-row>
        <van-row gutter="20" class="k-tab">
          <van-col span="12" class="t-con">
              <div>碳水：{{ summary?.energy?.currentCho }}</div>
              <div>脂肪：{{ summary?.energy?.currentFat }}</div>
              <div>蛋白质：{{ summary?.energy?.currentPro }}</div>
          </van-col>
          <van-col span="12" class="t-con">
              <div>碳水：{{ summary?.energy?.choMemo }}</div>
              <div>脂肪：{{ summary?.energy?.fatMemo }}</div>
              <div>蛋白质：{{ summary?.energy?.proMemo }}</div>
          </van-col>
        </van-row>
      </van-row>
      <van-row class="m-kcal">
        <van-row class="s-title"> 
          <div>身体活动达标</div>
        </van-row>
        <van-row gutter="20" class="k-tab">
          <van-col span="12" class="t-title">活动量</van-col>
          <van-col span="12" class="t-title">推荐量</van-col>
        </van-row>
        <van-row gutter="20" class="k-tab">
          <van-col span="12" class="t-con">
              <div>运动时间：{{ summary?.sportsTime }}分</div>
          </van-col>
          <van-col span="12" class="t-con">
              <div  v-for="(item, index) in mySports?.list"
          :key="'s' + index">
          {{ item.aim?.des }} {{ item?.freq?.details }}</div>
          </van-col>
        </van-row>
      </van-row>
    </van-row>
  </van-row>
  </div>
</template>

<script>
import fitNav from "@/components/fitNav.vue";
import { dailyReport } from "../api/report"
export default {
  components: { fitNav },
  name: "taskSum",
  data() {
    return {
      currentRate: 0,
      text: "目标总能量",
      summary: {
        energyLevel: 0,
        sportsTime: 0
      },
      mySports: {},
      noData: false
    };
  },
  created() {
    let id = this.$route.query.id;
    this.getdailyReport(id);
  },
  methods: {
    getdailyReport(id) {
      dailyReport(id).then((res) => {
        console.log(res)
        this.summary = res.data
        if (JSON.stringify(this.summary) == "{}") {
          this.noData = true
        }
        this.mySports = res.data.mySports;
      })
    }
  }
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
.box {
  width: 100%;
  overflow: hidden;
  background: url(../assets/sumBck.png) no-repeat;
  background-size: 100%;
  padding-top: 5.5rem;
}
.main {
  padding: 1rem 10px 10px 10px;
  background: #dbe3f9;
  border-radius: 32px 32px 0px 2px;
  font-family: PingFang SC ExtraLight;
  line-height: 0.6rem;
}
.m-kcal {
  padding: 15px;
  background-color: #f3f6fe;
  border-radius: 32px;
  color: #666;
  margin-bottom: 15px;
}
.kacl-txt {
  background: #ebf0fe;
  border-radius: 28px;
  padding: 10px 15px;
}
.t-t {
  color: #4285f4;
  display: inline-block;
  margin-right: 20px;
}
.m-txt {
  background: #ebf0fe;
  color: black;
  padding: 15px;
  border-radius: 28px;
}
.k-title{
  background: url('../assets/taskSum.png') no-repeat;
  background-size: 100% 100%;
}
.k-title,.s-title {
  padding: 0.8rem;
  color: black;
  font-size: 0.5rem;
}
.s-title{
  background: url('../assets/debate.png') no-repeat;
  background-size: 100% 100%;
  text-align: left;
}
.k-tab{
  padding: 10px;
}
.t-title{
  background-color:#EBF0FE;
  padding: 5px;
  text-align: center;
  font-size: 0.35rem;
  color: #1D1D1D;
}
.t-con{
  text-align: center;
  font-size: 0.35rem;
  color: #666666;
}
</style>