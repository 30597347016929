<template>
  <!-- 心血管疾病报告 -->
  <div class="box">
    <!-- 导航条 -->
    <fitNav :title="title"></fitNav>
    <van-row class="main">
      <!-- 我的相关疾病 -->
      <van-row style="padding: 10px" v-show="myDiseaseVisible"
        >我的相关疾病<van-icon
          name="like-o"
          color="#4285F4"
          style="padding-left: 5px"
        ></van-icon
      ></van-row>
      <!-- 1相关疾病 -->
      <van-row class="m-collapse" v-show="myDiseaseVisible" 
	    v-for="(item, index) in myDisease"
	    :key="'md' + index" >
        <van-row type="flex" align="center">
          <van-image
            width="18px"
            height="18px"
            :src="require('../assets/steps.png')"
          ></van-image>
          <div class="life-title">{{ item.title }}</div>
          <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
        </van-row>
        <van-row class="col-con">
          {{ item?.details }}
        </van-row>
      </van-row>
      <!-- 我的动脉粥样硬化性心血管病风险评估结果 -->
      <van-row
        style="padding: 10px"
        v-show="
          ascvdDangerVisible || ascvdDanger10Visible || ascvdYsDangerVisible
        "
        >我的动脉粥样硬化性心血管病风险评估结果<van-icon
          name="like-o"
          color="#4285F4"
          style="padding-left: 5px"
        ></van-icon
      ></van-row>
      <!-- ASCVD风险评估结果 -->
      <van-row class="m-collapse" v-show="ascvdDangerVisible">
        <van-row type="flex" align="center">
          <van-image
            width="18px"
            height="18px"
            :src="require('../assets/steps.png')"
          ></van-image>
          <div class="life-title">ASCVD风险评估结果</div>
          <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
        </van-row>
        <van-row class="col-con">
          {{ ascvd?.ascvdDanger?.details }}
        </van-row>
      </van-row>
      <!-- 10年ASCVD发病危险评估结果 -->
      <van-row class="m-collapse" v-show="ascvdDanger10Visible">
        <van-row type="flex" align="center">
          <van-image
            width="18px"
            height="18px"
            :src="require('../assets/steps.png')"
          ></van-image>
          <div class="life-title">10年ASCVD发病危险评估结果</div>
          <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
        </van-row>
        <van-row>
          <van-row style="padding: 10px 0px" v-show="ascvd?.ascvdDanger10Level != ''">
            <van-col
              v-if="ascvd?.ascvdDanger10Level == 'L1'"
              span="8"
              class="col-step"
            >
              <div class="fx">低风险</div>
              <van-icon name="diamond" color="#FFCD0D" size="12"></van-icon>
            </van-col>
            <van-col v-else span="8"></van-col>

            <van-col
              v-if="ascvd?.ascvdDanger10Level == 'L2'"
              span="8"
              class="col-step"
            >
              <div class="fx">中风险</div>
              <van-icon name="diamond" color="#FFCD0D" size="12"></van-icon>
            </van-col>
            <van-col v-else span="8"></van-col>

            <van-col
              v-if="ascvd?.ascvdDanger10Level == 'L3'"
              span="8"
              class="col-step"
            >
              <div class="fx">高风险</div>
              <van-icon name="diamond" color="#FFCD0D" size="12"></van-icon>
            </van-col>
            <van-col v-else span="8"></van-col>
          </van-row>
          <van-row class="setps" v-show="ascvd?.ascvdDanger10Level != ''">
            <van-col
              span="8"
              style="padding: 10px; border-right: 1px solid white"
            ></van-col>
            <van-col
              span="8"
              style="padding: 10px; border-right: 1px solid white"
            ></van-col>
            <van-col span="8"></van-col>
          </van-row>
        </van-row>
        <van-row class="col-con" style="margin-top: 10px">
          {{ ascvd?.ascvdDanger10?.details }}
        </van-row>
      </van-row>
      <!-- ASCVD余生风险结果 -->
      <van-row class="m-collapse" v-show="ascvdYsDangerVisible">
        <van-row type="flex" align="center">
          <van-image
            width="18px"
            height="18px"
            :src="require('../assets/steps.png')"
          ></van-image>
          <div class="life-title">ASCVD余生风险结果</div>
          <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
        </van-row>
        <van-row class="col-con">
          {{ ascvd?.ascvdYsDanger?.details }}
        </van-row>
      </van-row>

      <!-- 我的心血管病危险因素及合适水平 -->
      <van-row style="padding: 10px" v-show="cardiovascularVisible"
        >我的心血管病危险因素及合适水平<van-icon
          name="like-o"
          color="#4285F4"
          style="padding-left: 5px"
        ></van-icon
      ></van-row>
      <van-row class="m-collapse" v-show="cardiovascularVisible">
        <van-row
          class="col-con"
          v-for="(item, index) in cardiovascular"
          :key="'c' + index"
        >
          <van-row class="con-title"> {{ item.title }} </van-row>
          <van-row type="flex" align="center" style="padding-bottom: 5px">
            <div class="con-con">当前水平</div>
            <div class="con-num">{{ item.current }}</div>
          </van-row>
          <van-row type="flex" align="center">
            <div class="con-con">正常水平</div>
            <div class="con-num">{{ item.normal }}</div>
          </van-row>
        </van-row>
      </van-row>
      <van-row class="m-collapse">
        <van-row type="flex" align="center">
          <van-image
            width="18px"
            height="18px"
            :src="require('../assets/steps.png')"
          ></van-image>
          <div class="life-title">生活方式建议</div>
          <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
        </van-row>
        <van-row
          class="col-con"
          style="color: #666666;"
          v-for="(item, index) in lifeStyle"
          :key="'l' + index"
        >
          {{ item.details }}
          <van-row type="flex" align="center" style="padding-top: 5px" @click="handleTask">
            <div style="color: #4285f4; padding-right: 5px">
              {{ item.normal }}
            </div>
            <div><van-icon name="chat-o" color="#4285F4" /></div>
          </van-row>
        </van-row>
      </van-row>
    </van-row>
  </div>
</template>
  
<script>
import fitNav from "../components/fitNav.vue";
import { ascvdReport } from "../api/report";
export default {
  name: "cdRisk-card",
  data() {
    return {
      title: "",
      myDiseaseVisible: false,
      cardiovascularVisible: false,
      ascvdDangerVisible: false,
      ascvdDanger10Visible: false,
      ascvdYsDangerVisible: false,
      lifeStyleVisible: false,
      ascvd: {
        ascvdDanger: {},
        ascvdDanger10: {},
        ascvdYsDanger: {},
        ascvdDanger10Level: "",
        ascvdDangerYsLevel: "",
      },
      myDisease: [],
      cardiovascular: [],
      lifeStyle: [],
      activeName: ["1"],
    };
  },
  components: {
    fitNav,
  },
  created() {
    let recordId = this.$route.query.recordId;
    this.getReport(recordId);
  },
  methods: {
    getReport(recordId) {
      ascvdReport(recordId).then((res) => {
        if (res.data.hasOwnProperty("myDisease") == true && res.data.myDisease.length > 0) {
          this.myDiseaseVisible = true;
          this.myDisease = res.data.myDisease;
        }

        this.ascvd = res.data.ascvd;
        if (this.ascvd != null) {
          if (this.ascvd.hasOwnProperty("ascvdDanger") == true) {
            this.ascvdDangerVisible = true;
          }
          if (this.ascvd.hasOwnProperty("ascvdDanger10") == true) {
            this.ascvdDanger10Visible = true;
          }
          if (this.ascvd.hasOwnProperty("ascvdYsDanger") == true) {
            this.ascvdYsDangerVisible = true;
          }
        }

        this.cardiovascular = res.data.cardiovascular;
        this.lifeStyle = res.data.lifeStyle;
        if (this.cardiovascular.length > 0) {
          this.cardiovascularVisible = true;
        }

        if (this.lifeStyle.length > 0) {
          this.lifeStyleVisible = true;
        }
      });
    },

    getStatus(value) {
      console.log(value);
    },
    handleTask() {
      this.$router.push({
        path: "/task",
      });
    }
  },
};
</script>
  
  
  <style scoped>
* {
  padding: 0;
  margin: 0;
}
.box {
  width: 100%;
  overflow: hidden;
  background: url("../assets/cardiovascular.png") no-repeat;
  background-size: 100%;
}
.main {
  padding: 20px 10px 10px 10px;
  overflow: hidden;
  background: #dbe3f9;
  border-radius: 32px 32px 0px 2px;
  font-family: PingFang SC ExtraLight;
  color: #1d1d1d;
  line-height: 0.6rem;
  font-size: 0.38rem;
}
.m-collapse {
  background-color: #f3f6fe;
  border-radius: 32px;
  overflow: hidden;
  margin-bottom: 0.3rem;
  padding: 10px 10px 0px 10px;
}
.col-title {
  color: #4285f4;
}
.col-conten,
.should-content {
  color: #666666;
  font-size: 0.3rem;
}
.should-content {
  display: inline-block;
  padding: 0px 15px 0px 15px;
}
.nc-title {
  font-size: 0.3rem;
  color: #666666;
}

.col-con {
  background: #ebf0fe;
  border-radius: 24px;
  padding: 15px;
  margin-bottom: 10px;
  color: #666666;
  /* font-weight: 400; */
}
.con-title {
  color: #4285f4;
  font-size: 0.38rem;
  padding-bottom: 5px;
}
.con-con {
  padding-right: 10px;
  color: #1d1d1d;
  /* font-weight: 400; */
}
.con-num {
  color: #666666;
  /* font-weight: 400; */
}
/deep/ .van-cell {
  background-color: #f3f6fe;
}
.col-step {
  /* display: inline-block; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fx {
  background-color: #ffcd0d;
  padding: 0px 5px;
  width: 40px;
  font-size: 0.3rem;
  color: white;
  text-align: center;
  border-radius: 21px;
}
.setps {
  border-radius: 20px;
  background: linear-gradient(90deg, #b41f24, #f06024, #ffd00d, #7bcb77);
}
.life-title {
  padding: 15px 10px;
  font-size: 0.35rem;
}
</style>