<template>
  <div>
    <van-row class="b-top">
      <van-row
        type="flex"
        align="center"
        justify="center"
        style="position: relative"
      >
        <div class="b-logo"></div>
        <div class="b-title">企业员工健康管理</div>
      </van-row>
      <van-row class="imgCanvas" style="position: relative">
        <!-- <canvas id="canvas"></canvas> -->
        <video
          @ended="endVideo"
          :src="videoUrl[index]"
          style="width: 100%; height: 100%"
          id="videoplay"
          webkit-playsinline="true"
          x-webkit-airplay="true"
          playsinline="true"
        ></video>
        <div class="b-option">
          <div class="o-item1" @click="getPersonal"></div>
          <div class="o-item2" @click="getMySport"></div>
          <div class="o-item3" @click="getMyTask"></div>
        </div>
      </van-row>
    </van-row>
    <van-popup
      v-model="show"
      @click="video"
      @click-overlay="video"
      :style="{ 'background-color': 'transparent' }"
    >
      <div>
        <img src="../assets/bg.jpg" width="100%" style="border-radius: 10px" />
        <div
          style="
            font-size: 30px;
            color: #fff;
            margin-top: 10px;
            text-align: center;
          "
        >
          <van-icon name="close" />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import parseAPNG from "apng-js";
import { Toast } from "vant";
import { getCookie } from "@/utils/cookie";
export default {
  name: "publicNav",
  props: ["text", "isOpen"],
  data() {
    return {
      show: !this.$store.state.hasPopup,
      index: 0,
    };
  },
  computed: {
    videoUrl() {
      var list = [];
      (this.text || []).forEach((item) => {
        list.push(require(`../assets/mp4/${item}.mp4`));
      });
      return list;
    },
  },
  mounted() {
    if (this.$store.state.hasPopup) {
      this.$nextTick(() => {
        let videoplay = document.getElementById("videoplay");
        let playPromise = videoplay.play();
        playPromise.then(() => {
          if (this.$store.state.playVideo.indexOf(this.text[0]) >= 0) {
            videoplay.pause();
          }
        });
      });
    }

    // this.play();
    // this.imgPlay();
  },
  methods: {
    video() {
      this.$store.commit("setShowPopup");
      this.show = false;
      this.$nextTick(() => {
        document.getElementById("videoplay").play();
      });
    },
    endVideo() {
      if (this.videoUrl.length > this.index + 1) {
        this.index++;
        this.$nextTick(() => {
          document.getElementById("videoplay").play();
        });
      }
    },
    imgPlay() {
      // 播放器
      var player = null;
      // 播放速率
      var playbackRate = Number(1);
      // 获取图片资源
      var chip = require("../assets/wxUser.png");
      fetch(chip).then(function (response) {
        response.arrayBuffer().then(function (buffer) {
          var apng = parseAPNG(buffer);
          var canvas = document.querySelector("#canvas");
          canvas.width = 600;
          canvas.height = 565;
          canvas.style.backgroundSize = "cover";
          // 执行播放
          apng.getPlayer(canvas.getContext("2d")).then(function (p) {
            player = p;
            player.playbackRate = playbackRate;
            if (localStorage.getItem("isAction") == "no") {
              player.stop();
            } else {
              setTimeout(() => {
                player.play();
              }, 1000);
              var si = setInterval((res) => {
                if (localStorage.getItem("isAction") != "yes") {
                  player.stop();
                  clearInterval(si);
                  return;
                }
              }, 1000);
            }
          });
        });
      });
    },
    play() {
      setTimeout(() => {
        this.$ttsRecorder.stop();
        //要合成的文本
        this.$ttsRecorder.setParams({
          // 文本内容
          text: this.text,
          // 角色
          //  voiceName: '',
          // 语速
          speed: 50,
          // 音量
          voice: 50,
        });
        this.$ttsRecorder.start();
      }, 500);
    },
    //语音合成停止
    pause() {
      this.$ttsRecorder.stop();
    },
    //个人中心
    getPersonal() {
      if (this.isOpen != "") {
        this.$router.push({
          path: "/personalCenter",
        });
      } else {
        Toast.fail("请登录！");
      }
    },
    //我的报告
    getMySport() {
      if (this.isOpen != "") {
        this.$router.push({
          path: "/report",
        });
      } else {
        Toast.fail("请登录！");
      }
    },
    //
    getMyTask() {
      if (this.isOpen != "") {
        this.$router.push({
          path: "/task",
        });
      } else {
        Toast.fail("请登录！");
      }
    },
  },
  destroyed() {
    // window.removeEventListener("beforeunload", this.pause());
  },
};
</script>

<style scoped lang="less">
* {
  padding: 0px;
  margin: 0;
}

.b-top {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: url("../assets/bkg.png") no-repeat;
}

.b-logo {
  width: 1.4rem;
  height: 1.2rem;
  background: url("../assets/indexLogo.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 10px;
  left: 0px;
}

#canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}

.imgCanvas {
  height: 6rem;
  width: 100%;
}

.b-option {
  display: flex;
  height: 4.5rem;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  top: 0.8rem;
  right: 10px;
}

.o-item1,
.o-item2,
.o-item3 {
  width: 1.4rem;
  height: 1.4rem;
}

.o-item1 {
  background: url("../assets/userLogo.png") no-repeat;
  background-size: 100% 100%;
}

.o-item2 {
  background: url("../assets/report.png") no-repeat;
  background-size: 100% 100%;
}

.o-item3 {
  background: url("../assets/task.png") no-repeat;
  background-size: 100% 100%;
}

.b-title {
  font-size: 0.5rem;
  padding: 20px 0px;
}
// /deep/ .van-overlay {
//   background-color: red;
// }
/deep/ .van-popup--center {
  top: 40%;
}
</style>