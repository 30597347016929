<template>
  <!-- 个人中心 -->
  <div class="box">
    <fitNav title="个人中心"></fitNav>
    <div class="main">
      <van-row type="flex" justify="center" class="m-form">
        <van-form ref="form">
          <van-field
            v-model="userForm.name"
            name="姓名"
            label="姓名"
            placeholder="姓名"
            :rules="[{ required: true, message: '请填写姓名' }]"
          />
          <van-field
            v-model="userForm.idNo"
            name="身份证"
            label="身份证"
            placeholder="身份证"
            :rules="[{ required: true, message: '请填写身份证' }]"
          />
          <MyDatepicker
            v-model="userForm.birthday"
            label="出生日期"
            :rules="[{ required: true, message: '请填写出生日期' }]"
          ></MyDatepicker>
          <!-- <van-field
            v-model="userForm.birthday"
            name="出生日期"
            label="出生日期"
            placeholder="出生日期"
            readonly
            @click="showDate"
            :rules="[{ required: true, message: '请填写出生日期' }]"
          /> -->

          <van-field name="radio" label="性别">
            <template #input>
              <van-radio-group v-model="userForm.sex" direction="horizontal">
                <van-radio name="1" style="margin-right: 15px">男</van-radio>
                <van-radio name="2">女</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            v-model="userForm.phone"
            name="手机号"
            label="手机号"
            placeholder="手机号"
            :rules="[
              { required: true, message: '请填写手机号' },
              {
                pattern: /^1[3456789]\d{9}$/,
                message: '手机号码格式错误！',
              },
            ]"
          />
          <!-- <van-field
            v-model="userForm.age"
            name="年龄"
            label="年龄"
            placeholder="年龄"
            :rules="[{ required: true, message: '请填写年龄' }]"
          /> -->
        </van-form>
      </van-row>
      <div style="margin-top: 15px">
        <van-button
          type="primary"
          @click="onSubmit"
          block
          round
          color="#005DFF"
          native-type="submit"
          style="height: 40px"
          >完成</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import fitNav from "@/components/fitNav.vue";
import { getUserInfos, addUserInfos } from "../api/index";
import { Toast } from "vant";
import MyDatepicker from "@/components/MyDatepicker.vue";
export default {
  name: "personalCenter",
  data() {
    return {
      userForm: {
        name: "",
        phone: "",
        sex: "1",
        birthday: "",
        idNo: "",
      },
    };
  },
  components: {
    fitNav,
    MyDatepicker,
  },
  created() {
    this.getInfos();
  },
  methods: {
    getInfos() {
      getUserInfos().then((res) => {
        if (res.code == 200) {
          this.userForm.name = res.data.user.name;
          this.userForm.phone = res.data.user.phone;
          this.userForm.birthday = res.data.user.birthday;
          this.userForm.sex = JSON.stringify(res.data.user.sex);
          this.userForm.idNo = res.data.user.idNo;
        }
      });
    },
    onSubmit() {
      this.$refs.form
        .validate()
        .then(() => {
          addUserInfos(this.userForm).then((res) => {
            if (res.code == 200) {
              Toast.success("填写成功！");
              // setTimeout(() => {
              //   this.$router.push({
              //     path: "/reports",
              //   });
              // }, 1000);
            } else {
              Toast.fail("提交失败！");
            }
          });
        })
        .catch(() => {
          this.$toast.fail("请填写完整！");
        });
    },
  },
};
</script>

<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
}
.box {
  width: 100%;
  background: url("../assets/center.png") no-repeat;
  background-size: 100%;
  padding-top: 5rem;
}
.main {
  background: #dbe3f9;
  border-radius: 32px 32px 0px 2px;
  padding: 1rem 15px 1rem 15px;
  height: calc(100vh - 7rem);
  font-family: PingFang SC ExtraLight;
  line-height: 0.6rem;
}
.m-form {
  padding: 30px 0px;
  background: #f3f6fe;
  border-radius: 32px;
}
/deep/ .van-cell {
  background-color: #f3f6fe;
}
/deep/ .van-field__control {
  border-bottom: 1px solid #0b9aff;
}
/deep/ .van-field__control--custom {
  border: none;
}
</style>