<template>
  <!-- 膳食周报 -->
  <div class="box">
    <fit-nav title=""></fit-nav>
    <van-row v-if="noData" >
        无用户记录
    </van-row>
    <van-row v-else>
    <van-row class="main">
      <!-- 达标  -->
      <van-row class="m-block">
        <van-col span="8">
          <div style="padding: 10px">
            达标 <span style="color: #666666">(天)</span>
          </div>
          <div class="b-num">{{ weekly.standardDay }}</div>
        </van-col>
        <van-col span="16" class="b-txt">
          {{ weekly.feedback }}
        </van-col>
      </van-row>
      <!-- 记录 -->
      <van-row class="m-block">
        <van-row>
          <van-col span="8" style="padding-top: 20px">
            <div style="padding: 10px 10px 0px 10px">记录：</div>
            <div class="v-num">{{ weekly.recordDay}}次</div>
            <div style="padding: 10px 10px 0px 10px">总摄入：</div>
            <div class="v-num">{{ weekly.sumIntake }}</div>
          </van-col>
          <van-col span="16" class="b-ver">
            <div id="vertical" style="height: 200px; width: 100%"></div>
          </van-col>
        </van-row>
        <van-row
          type="flex"
          justify="space-between"
          style="padding: 25px 15px 10px 15px"
        >
          <van-col span="12">
            本周日均：<span style="color: #666">{{ weekly.avgIntake }}</span>
          </van-col>
          <!-- <van-col span="12">
            较上周：<span style="color: #666">1000</span>
          </van-col> -->
        </van-row>
      </van-row>
      <van-row class="m-block">
        <van-row type="flex" align="center">
          <van-image
            width="18"
            height="18"
            :src="require('../assets/pace.png')"
          ></van-image>
          <div class="b-title">日均摄入比推荐目标</div>
          <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
        </van-row>
        <van-row type="flex" align="center" justify="center">
          <van-circle
            v-model="currentRate"
            :rate="weekly.intakeRate"
            :stroke-width="100"
            :text="weekly.intakeMemo"
            :color="gradientColor"
          />
        </van-row>
        <!-- <van-row style="padding: 20px 10px 10px 10px;">
            本周体重变化：0.5公斤
        </van-row> -->
      </van-row>
      <van-row class="m-block">
        <van-row type="flex" align="center">
          <van-image
            width="18"
            height="18"
            :src="require('../assets/pace.png')"
          ></van-image>
          <div class="b-title">本周膳食情况</div>
          <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
        </van-row>
        <!-- 需改善 -->
        <van-row
          style="margin-bottom: 5px;"
          :gutter="2"
          type="flex"
          justify="space-between"
        >
          <van-col span="4" style="width: 1.5rem;">
            <van-row class="col-warn">
              <div>需改善</div>
              <div>
                <van-image
                  width="14px"
                  height="14px"
                  :src="require('../assets/warn.png')"
                ></van-image>
              </div>
            </van-row>
          </van-col>
          <van-col span="20" style="height: auto;flex: 1;">
            <van-grid :gutter="2" direction="horizontal">
              <van-grid-item
                v-for="(item, index) in needImprove"
                :key="'i' + index"
                :icon="item.icon"
                :text="item.memo"
                class="v-grid"
              />
            </van-grid>
          </van-col>
        </van-row>
        
        <!-- 需保持 -->
        <van-row :gutter="2" type="flex" justify="space-between">
          <van-col span="4" style="width:1.5rem">
            <van-row class="col-keep">
              <div>需保持</div>
              <div>
                <van-image
                  width="14px"
                  height="14px"
                  :src="require('../assets/try.png')"
                ></van-image>
              </div>
            </van-row>
          </van-col>
          <van-col span="20" style="height: auto;flex: 1;">
            <van-grid :gutter="2" direction="horizontal">
              <van-grid-item
                v-for="(item, index) in needKeep"
                :key="'k' + index"
                :icon="item.icon"
                :text="item.memo"
                class="v-grid"
              />
            </van-grid>
          </van-col>
        </van-row>
      </van-row>
      </van-row>
    </van-row>
    <!-- </van-row> -->
  </div>
</template>

<script>
import fitNav from "@/components/fitNav.vue";
import * as echarts from "echarts";
import { dietWeeklyReport } from "../api/report";
var myChart;
export default {
  components: { fitNav },
  name: "dietWeekly",
  data() {
    return {
      currentRate: 0,
      gradientColor: {
        "50%": "#FBA55B",
        "100%": "#FFC88D",
      },
      weekly: {
        intakeRate: 0,
        standardDay: 0,
        avgIntake: 0,
        recordDay: 0,
        sumIntake: 0,
        intakeMemo: "",
        feedback: "",
      },
      //需要改善
      needImproveVisivle: false,
      //需要保持
      needKeepVisible: false,
      //需要改善
      needImprove: [],
      //需要保持
      needKeep: [],
      noData: false,
    };
  },
  mounted() {
    myChart = echarts.init(document.getElementById("vertical"));
  },
  created() {
    let id = this.$route.query.id;
    this.getDietWeeklyReport(id);
  },
  methods: {
    getDietWeeklyReport(id) {
      dietWeeklyReport(id).then((res) => {
        this.weekly = res.data;
        if (JSON.stringify(this.monthly) == "{}") {
          this.noData = true;
        }
        this.needImprove = this.weekly.needImprove;
        this.needKeep = this.weekly.needKeep;
        this.addIcon(this.needImprove);
        this.addIcon(this.needKeep);
        console.log(res);
        myChart.setOption({
          xAxis: {
            data: ["一", "二", "三", "四", "五", "六", "日"],
          },
          yAxis: {},
          series: [
            {
              data: this.weekly.zaoData,
              type: "bar",
              stack: "x",
            },
            {
              data: this.weekly.jiaData,
              type: "bar",
              stack: "x",
            },
            {
              data: this.weekly.wuData,
              type: "bar",
              stack: "x",
            },
            {
              data: this.weekly.wanData,
              type: "bar",
              stack: "x",
            },
          ],
        });
      });
    },
    addIcon(arr) {
      for (var item of arr) {
        if (item["type"] == "grain") {
          item["icon"] = require("../assets/cereal.png");
        } else if (item["type"] == "tubers") {
          item["icon"] = require("../assets/potato.png");
        } else if (item["type"] == "vegetables") {
          item["icon"] = require("../assets/vegetable.png");
        } else if (item["type"] == "fruit") {
          item["icon"] = require("../assets/fruit.png");
        } else if (item["type"] == "meat") {
          item["icon"] = require("../assets/meat.png");
        } else if (item["type"] == "aquatic") {
          item["icon"] = require("../assets/aquatic.png");
        } else if (item["type"] == "eggs") {
          item["icon"] = require("../assets/eggs.png");
        } else if (item["type"] == "milk") {
          item["icon"] = require("../assets/milk.png");
        } else if (item["type"] == "beans") {
          item["icon"] = require("../assets/beans.png");
        } else if (item["type"] == "nut") {
          item["icon"] = require("../assets/nut.png");
        } else if (item["type"] == "oil") {
          item["icon"] = require("../assets/oil.png");
        } else if (item["type"] == "salt") {
          item["icon"] = require("../assets/salt.png");
        } else if (item["type"] == "water") {
          item["icon"] = require("../assets/water.png");
        } else if (item["type"] == "taste") {
          item["icon"] = require("../assets/taste.png");
        } else if (item["type"] == "drink") {
          item["icon"] = require("../assets/drinkIcon.png");
        } else if (item["type"] == "habits") {
          item["icon"] = require("../assets/habits.png");
        }
      }
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
.box {
  background: url("../assets/weekly.png") no-repeat;
  background-size: 100%;
  padding-top: 5.5rem;
  overflow: hidden;
}
.main {
  padding: 1rem 10px 10px 10px;
  background: #dbe3f9;
  border-radius: 32px 32px 0px 2px;
  font-family: PingFang SC ExtraLight;
  line-height: 0.6rem;
}
.m-block {
  padding: 10px;
  background-color: #f3f6fe;
  border-radius: 32px;
  margin-bottom: 10px;
}
.b-num {
  color: #f0843a;
  font-size: 1.3rem;
  text-align: center;
  padding: 25px 0px;
}
.b-txt {
  background-color: #ebf0fe;
  padding: 15px;
  border-radius: 28px;
}
.b-ver {
  background-color: #ebf0fe;
  padding: 0px 15px;
  border-radius: 28px;
}
#vertical {
  /* height: 4rem; */
  /* height: 200px; */
}
.v-num {
  padding: 10px;
  font-size: 0.6rem;
  color: #666;
}
.b-title {
  padding: 15px 10px;
  font-size: 0.35rem;
}
.col-warn,
.col-keep {
  font-size: 0.35rem;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 1.5rem;
}
.col-warn {
  background: #f7f1e6;
  color: #a3552d;
}
.col-keep {
  background: #e4ebf7;
  color: #4f6070;
}
.v-grid{
  min-width: 2.45rem;
}
</style>