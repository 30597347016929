<template>
  <!-- 首页第三步 -->
  <div class="reports-box">
    <!-- logo、图像 -->
    <!-- <van-row class="b-top">
      <van-row
        type="flex"
        align="center"
        justify="center"
        style="position: relative"
      >
        <div class="b-logo"></div>
        <div class="b-title">企业员工健康管理</div>
      </van-row>
      <van-row class="imgCanvas" style="position: relative">
        <canvas id="canvas"></canvas>
        <div class="b-option">
          <div class="o-item1" @click="getPersonal"></div>
          <div class="o-item2" @click="getMySport"></div>
          <div class="o-item3" @click="getMyTask"></div>
        </div>
      </van-row>
    </van-row> -->
    <publicNav :text="video"></publicNav>

    <!-- 主体 -->
    <van-row class="main">
      <!-- 介绍 -->
      <van-row>
        <van-col span="4">
          <div class="m-img"></div>
        </van-col>
        <van-col span="20">
          <div class="m-txt">非常好，您已经成功完成了第一步，继续前进吧！</div>
        </van-col>
      </van-row>
      <!-- 基本信息 -->
      <van-row>
        <van-col span="4">
          <div class="m-img"></div>
        </van-col>
        <van-col span="20">
          <div class="m-txt">
            <van-form label-width="1.5rem" ref="form">
              <van-field
                v-model="userForm.name"
                name="input"
                label="姓名"
                placeholder="姓名"
                :rules="[{ required: true, message: '请填写姓名' }]"
              />
              <van-field name="radio" label="性别">
                <template #input>
                  <van-radio-group
                    v-model="userForm.sex"
                    direction="horizontal"
                    :rules="[{ required: true, message: '请选择性别' }]"
                  >
                    <van-radio name="1" style="margin-right: 15px"
                      >男</van-radio
                    >
                    <van-radio name="2">女</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
              <MyDatepicker
                v-model="userForm.birthday"
                label="出生日期"
                :rules="[{ required: true, message: '请填写出生日期' }]"
              ></MyDatepicker>
              <van-field
                v-model="userForm.phone"
                name="input"
                label="手机号"
                placeholder="手机号"
                :rules="[
                  { required: true, message: '请填写手机号' },
                  {
                    pattern: /^1[3456789]\d{9}$/,
                    message: '手机号码格式错误！',
                  },
                ]"
              />
            </van-form>
            <div class="u-btn">
              <van-button
                round
                size="large"
                native-type="submit"
                type="primary"
                color="#005DFF"
                class="b-btn"
                @click="handleUpdate"
                >修改</van-button
              >
            </div>
          </div>
        </van-col>
      </van-row>
      <!-- 第二步 -->
      <van-row>
        <van-col span="4">
          <div class="m-img"></div>
        </van-col>
        <van-col span="20">
          <div class="m-txt">
            第二步，请继续填报基础问卷，请如实的提供您的检测指标信息，如血压、血糖、血脂等。您能够得到个性化的慢病风险报告卡及相应的健康改善指导。
            <van-row type="flex" align="center" class="b-base">
              <van-col span="15" class="b-text">
                <span>1、基础问卷</span>
              </van-col>
              <van-col span="9">
                <van-button
                  round
                  v-if="isFirst"
                  size="small"
                  type="info"
                  color="#005DFF"
                  class="base-btn"
                  @click="getBasicQuest"
                  >立即完成</van-button
                >
                <van-button
                  v-if="isFirstOver"
                  round
                  plain
                  size="small"
                  type="info"
                  color="#666"
                  class="base-btn"
                  disabled
                  >已完成</van-button
                >
              </van-col>
            </van-row>
          </div>
        </van-col>
      </van-row>
      <!-- 第一份报告 -->
      <van-row v-if="oneCard">
        <van-col span="4">
          <div class="m-img"></div>
        </van-col>
        <van-col span="20">
          <div class="m-txt">恭喜您！完成第一份报告，以下奉上您的报告卡</div>
          <van-row class="m-card" style="margin-top: 15px" type="flex">
            <van-col span="15">
              <div class="card-title">慢病风险报告卡</div>
              <van-row type="flex" align="center" class="card-list">
                <div style="padding: 0px 5px 0px 5px">我的健康风险</div>
                <div style="padding: 0px 5px 0px 5px">我能改变</div>
                <div style="padding: 0px 5px 0px 5px">不可改变</div>
                <div style="padding: 0px 5px 0px 5px">我应如何做</div>
              </van-row>
            </van-col>
            <van-col span="9" class="card-right">
              <van-button
                round
                size="small"
                type="info"
                color="#005DFF"
                class="card-btn"
                @click="getCdcard"
                >查看报告</van-button
              >
            </van-col>
          </van-row>
        </van-col>
      </van-row>
      <!-- 第三步 -->
      <van-row v-if="stepThree">
        <van-col span="4">
          <div class="m-img"></div>
        </van-col>
        <van-col span="20">
          <div class="m-txt">
            第三步，请继续填报基础问卷，请如实的提供您的生活方式信息，如日常膳食、运动、吸烟、饮酒状况等。您能够得到个性化的生活方式报告卡及相应的健康改善处方。
            <van-row type="flex" align="center" class="b-base">
              <van-col span="15" class="b-text">
                <span>1、生活方式问卷</span>
              </van-col>
              <van-col span="9">
                <van-button
                  round
                  v-if="isSecond"
                  size="small"
                  type="info"
                  color="#005DFF"
                  class="base-btn"
                  @click="getLifeQuest"
                  >立即完成</van-button
                >
                <van-button
                  round
                  plain
                  v-if="isSecondOver"
                  size="small"
                  type="info"
                  color="#666"
                  class="base-btn"
                  disabled
                  >已完成</van-button
                >
              </van-col>
            </van-row>
          </div>
        </van-col>
      </van-row>
      <!-- 第二份报告 -->
      <van-row v-if="towCard">
        <van-col span="4">
          <div class="m-img"></div>
        </van-col>
        <van-col span="20">
          <div class="m-txt">
            恭喜您!完成第二份报告,以下奉上您的 生活方式报告卡
          </div>
          <van-row class="m-card" style="margin-top: 15px" type="flex">
            <van-col span="15">
              <div class="card-title">生活方式报告</div>
              <van-row type="flex" align="center" class="card-list">
                <div>我的膳食评估</div>
                <div style="padding-left: 5px">我的膳食处方</div>
                <div>我的运动评估</div>
                <div style="padding-left: 5px">我的运动处方</div>
                <div style="padding-left: 5px">...</div>
              </van-row>
            </van-col>
            <van-col span="9" class="card-right">
              <van-button
                round
                size="small"
                type="info"
                color="#005DFF"
                class="card-btn"
                @click="getLifeStyle"
                >查看报告</van-button
              >
            </van-col>
          </van-row>
        </van-col>
      </van-row>
      <!-- 第四步 -->
      <van-row v-if="stepFour">
        <van-col span="4">
          <div class="m-img"></div>
        </van-col>
        <van-col span="20">
          <div class="m-txt">
            第四步，请继续填报基础问卷，请如实的提供您的患病史。您能够得到个性化的动脉粥样硬化性心血管疾病报告卡及相应的风险提示。
            <van-row type="flex" align="bottom" class="b-base">
              <van-col span="15" class="b-text">
                <span>3、心血管疾病相关病史问卷</span>
              </van-col>
              <van-col span="9">
                <van-button
                  round
                  v-if="isThree"
                  size="small"
                  type="info"
                  color="#005DFF"
                  class="base-btn"
                  @click="getCardQuest"
                  >立即完成</van-button
                >
                <van-button
                  round
                  plain
                  v-if="isThreeOver"
                  size="small"
                  disabled
                  type="info"
                  color="#666"
                  class="base-btn"
                  >已完成</van-button
                >
              </van-col>
            </van-row>
          </div>
        </van-col>
      </van-row>
      <!-- 第三份报告 -->
      <van-row v-if="threeCard">
        <van-col span="4">
          <div class="m-img"></div>
        </van-col>
        <van-col span="20">
          <div class="m-txt">
            恭喜您!完成第三份报告,以下奉上您的动脉粥样硬化性心血管疾病风险报告卡
          </div>
          <van-row class="m-card" style="margin-top: 15px" type="flex">
            <van-col span="15">
              <div class="card-title">动脉粥样硬化性心血管疾病风险报告卡</div>
              <van-row type="flex" align="center" class="card-list">
                <div>我的相关疾病</div>
                <div>我的动脉粥样硬化性心...</div>
              </van-row>
            </van-col>
            <van-col span="9" class="card-right">
              <van-button
                round
                size="small"
                type="info"
                color="#005DFF"
                class="card-btn"
                @click="getCardiova"
                >查看报告</van-button
              >
            </van-col>
          </van-row>
        </van-col>
      </van-row>
    </van-row>
  </div>
</template>
    <script>
import parseAPNG from "apng-js";
import { Toast } from "vant";
import { getUserInfos, updateUserInfos } from "../api/index";
import publicNav from "@/components/publicNav.vue";
import MyDatepicker from "@/components/MyDatepicker.vue";
export default {
  name: "questionnaires",
  components: {
    MyDatepicker,
    publicNav,
  },
  data() {
    return {
      video: ["reports0"],
      text: "非常好，您已经成功完成了第一步，继续前进吧！第二步，请继续填报基础问卷，请如实的提供您的检测指标信息，如血压、血糖、血脂等。您能够得到个性化的慢病风险报告卡及相应的健康改善指导。",
      userForm: {
        name: "",
        phone: "",
        sex: "",
        birthday: "",
      },
      //第一步是否显示
      isFirst: true,
      isFirstOver: false,
      //第二步是否显示
      isSecond: true,
      isSecondOver: false,
      // 第三步是否显示
      isThree: true,
      isThreeOver: false,

      //
      oneCard: false,
      stepThree: false,
      towCard: false,
      stepFour: false,
      threeCard: false,
      isShow: "",
    };
  },
  mounted() {
    this.isShow = this.$route.query.title;
    this.show();
    this.getInfos();
    if (
      this.oneCard == false ||
      this.towCard == false ||
      this.threeCard == false
    ) {
      setTimeout(() => {
        window.scrollTo(0, 50000);
      }, 200);
    }
  },
  methods: {
    imgPlay() {
      // 播放器
      var player = null;
      // 播放速率
      var playbackRate = Number(1);
      // 获取图片资源
      var chip = require("../assets/wxUser.png");
      fetch(chip).then(function (response) {
        response.arrayBuffer().then(function (buffer) {
          var apng = parseAPNG(buffer);
          var canvas = document.querySelector("#canvas");
          canvas.width = 600;
          canvas.height = 565;
          // 执行播放
          apng.getPlayer(canvas.getContext("2d")).then(function (p) {
            player = p;
            player.playbackRate = playbackRate;
            if (localStorage.getItem("isAction") == "no") {
              player.stop();
            } else {
              setTimeout(() => {
                player.play();
              }, 1000);
              var si = setInterval((res) => {
                if (localStorage.getItem("isAction") == "no") {
                  player.stop();
                  clearInterval(si);
                  return;
                }
              }, 1000);
            }
          });
        });
      });
    },
    play() {
      setTimeout(() => {
        this.$ttsRecorder.stop();
        //要合成的文本
        this.$ttsRecorder.setParams({
          // 文本内容
          text: this.text,
          // 角色
          //  voiceName: '',
          // 语速
          speed: 50,
          // 音量
          voice: 50,
        });
        this.$ttsRecorder.start();
      }, 500);
    },
    //语音合成停止
    pause() {
      this.$ttsRecorder.stop();
    },
    //个人中心
    getPersonal() {
      if (this.isOpen != "") {
        this.$router.push({
          path: "/personalCenter",
        });
      } else {
        Toast.fail("请登录！");
      }
    },
    //我的报告
    getMySport() {
      if (this.isOpen != "") {
        this.$router.push({
          path: "/report",
        });
      } else {
        Toast.fail("请登录！");
      }
    },
    //
    getMyTask() {
      if (this.isOpen != "") {
        this.$router.push({
          path: "/task",
        });
      } else {
        Toast.fail("请登录！");
      }
    },
    //
    getInfos() {
      getUserInfos().then((res) => {
        if (res.code == 200) {
          this.userForm.name = res.data.user.name;
          this.userForm.phone = res.data.user.phone;
          this.userForm.birthday = res.data.user.birthday;
          this.userForm.sex = JSON.stringify(res.data.user.sex);
        }
      });
    },
    //
    show() {
      if (this.isShow == "basicOver") {
        this.oneCard = true;
        this.stepThree = true;
        this.towCard = false;
        this.stepFour = false;
        this.threeCard = false;
        this.isFirst = false;
        this.isFirstOver = true;
        // this.pause();
        this.video = ["reports1"];
        this.text =
          "恭喜您！完成第一份报告，以下奉上您的报告卡。第三步，请继续填报基础问卷，请如实的提供您的生活方式信息，如日常膳食、运动、吸烟、饮酒状况等。您能够得到个性化的生活方式报告卡及相应的健康改善处方。";
        localStorage.setItem("isAction", "yes");
        // this.play();
        // this.imgPlay();
      } else if (this.isShow == "lifeOver") {
        this.oneCard = true;
        this.stepThree = true;
        this.towCard = true;
        this.stepFour = true;
        this.threeCard = false;
        this.isFirst = false;
        this.isFirstOver = true;
        this.isSecond = false;
        this.isSecondOver = true;
        // this.pause();
        this.video = ["reports2"];
        this.text =
          "恭喜您!完成第二份报告,以下奉上您的 生活方式报告卡。第四步，请继续填报基础问卷，请如实的提供您的患病史。您能够得到个性化的动脉粥样硬化性心血管疾病报告卡及相应的风险提示。";
        localStorage.setItem("isAction", "yes");
        // this.play();
        // this.imgPlay();
      } else if (this.isShow == "ascvdOver") {
        this.oneCard = true;
        this.stepThree = true;
        this.towCard = true;
        this.stepFour = true;
        this.threeCard = true;
        this.isFirst = false;
        this.isFirstOver = true;
        this.isSecond = false;
        this.isSecondOver = true;
        this.isThree = false;
        this.isThreeOver = true;
        // this.pause();
        this.video = ["reports3"];
        this.text =
          "恭喜您!完成第三份报告,以下奉上您的动脉粥样硬化性心血管疾病风险报告卡";
        localStorage.setItem("isAction", "yes");
        setTimeout(() => {
          this.$store.commit("setPlayVideo", "reports3");
        }, 5000);
        // this.play();
        // this.imgPlay();
      } else {
        this.video = ["reports0"];
        // this.play();
        // this.imgPlay();
      }
    },
    //
    handleUpdate() {
      this.$refs.form
        .validate()
        .then(() => {
          updateUserInfos(this.userForm).then((res) => {
            if (res.code == 200) {
              Toast.success("修改成功！");
            }
          });
        })
        .catch(() => {
          this.$toast.fail("请填写完整！");
        });
    },
    //基础问卷
    getBasicQuest() {
      this.pause();
      this.$router.push({
        path: "/basicQuest",
      });
    },
    //慢病风险报告
    getCdcard() {
      this.pause();
      this.$router.push({
        path: "/cdRiskCard",
        query: {
          recordId: this.$getCookie("recordId"),
        },
      });
    },
    //生活问卷
    getLifeQuest() {
      this.pause();
      this.$router.push({
        path: "/lifestyleEpq",
      });
    },
    //生活方式报告
    getLifeStyle() {
      this.pause();
      this.$router.push({
        path: "/lifestyleCard",
        query: {
          recordId: this.$getCookie("recordId"),
        },
      });
    },
    // 心血管
    getCardQuest() {
      this.pause();
      this.$router.push({
        path: "/ascvdEpq",
      });
    },
    //心血管报告
    getCardiova() {
      this.pause();
      this.$router.push({
        path: "/cardiovascular",
        query: {
          recordId: this.$getCookie("recordId"),
        },
      });
    },
  },
  destroyed() {
    // window.removeEventListener("beforeunload", this.pause());
  },
};
</script>
    
    <style scoped lang="less">
* {
  padding: 0;
  margin: 0;
}
.reports-box {
  width: 100%;
  overflow: hidden;
  background: url("../assets/bkg.png") no-repeat;
  background-size: 100%;
}
.main {
  margin-top: 6.5rem;
  padding: 0.5rem 10px 10px 10px;
  overflow: hidden;
  background-color: #dbe3f9;
  border-radius: 32px 32px 0px 0px;
  font-size: 0.4rem;
  font-family: PingFang SC ExtraLight;
  color: #1d1d1d;
  line-height: 0.6rem;
}
.m-img {
  width: 1.4rem;
  height: 1.4rem;
  background: url("../assets/user.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 0.5rem;
}
.m-txt,
.m-card {
  padding: 15px;
  background: #f3f6fe;
  border-radius: 2px 32px 32px 32px;
  margin-top: 1rem;
}
.m-card {
  background: url("../assets/card-bck.png") no-repeat;
  background-size: 100% 100%;
}
.card-list {
  color: #666666;
  font-size: 0.33rem;
  line-height: 0.45rem;
}
.card-title {
  padding: 10px 0px 10px 0px;
  font-size: 0.4rem;
  color: #005dff;
}
.card-right {
  padding-top: 1.8rem;
}
.card-btn {
  float: right;
}
.b-base {
  margin-top: 10px;
}
.b-text {
  color: #f8051c;
}
.base-btn {
  float: right;
}
.base-btn,
.card-btn {
  width: 2.5rem;
  height: 1rem;
}
.t-title {
  padding-bottom: 5px;
}
.t-txt {
  /* height: 0.5rem; */
  font-size: 0.37rem;
}
.t-img {
  width: 0.5rem;
  background: url("../assets/details.png") no-repeat;
  background-size: 100% 100%;
  border: 1px solid red;
}
.b-btn {
  height: 1rem;
  font-size: 0.35rem;
  margin-top: 10px;
}
/deep/ .van-cell {
  background-color: #f3f6fe;
}
/deep/ .van-field__control {
  border-bottom: 1px solid #0b9aff;
}
/deep/ .van-field__control--custom {
  border: none;
}
.b-top {
  padding: 10px;
}
.b-logo {
  width: 1.4rem;
  height: 1.2rem;
  background: url("../assets/indexLogo.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 10px;
  left: 0px;
}
#canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}
.imgCanvas {
  height: 6rem;
  width: 100%;
}
.b-option {
  display: flex;
  height: 4.5rem;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  top: 0.8rem;
  right: 10px;
}
.o-item1,
.o-item2,
.o-item3 {
  width: 1.4rem;
  height: 1.4rem;
}
.o-item1 {
  background: url("../assets/userLogo.png") no-repeat;
  background-size: 100% 100%;
}
.o-item2 {
  background: url("../assets/report.png") no-repeat;
  background-size: 100% 100%;
}
.o-item3 {
  background: url("../assets/task.png") no-repeat;
  background-size: 100% 100%;
}
.b-title {
  font-size: 0.5rem;
  padding: 20px 0px;
}
</style>