<template>
  <!-- 运动日记 -->
  <div class="box">
    <!-- 运动日记 -->
    <van-row v-if="isDiary">
      <fitNav title="运动日记"></fitNav>
      <van-row type="flex" class="b-title">
        <div style="padding: 10px">
          实际摄入量<span style="color: #4285f4">{{ energyTotalConsume }}</span
          >千卡
        </div>
      </van-row>
      <van-row class="main">
        <el-date-picker
          v-model="date"
          align="right"
          type="date"
          :picker-options="pickerOptions"
          style="margin: 10px"
          @change="uptDate"
        >
        </el-date-picker>
        <van-row class="tab-con" v-if="list.length != '0'">
          <van-row
            type="flex"
            justify="space-between"
            align="center"
            style="padding: 5px"
            v-for="(item, index) in list"
            :key="index"
          >
            <van-row>
              <van-row class="t-title">{{ getByID(item.kcalId) }}</van-row>
              <van-row>
                <span class="type">{{ item.sportTime }}分钟</span>
                <span class="type" style="margin-left: 10px"
                  >{{ item.energyConsume }}千卡</span
                >
              </van-row>
            </van-row>
            <van-button @click="handleUpt(item)" size="mini" color="#FFA044" plain
              >设置时长</van-button
            >
          </van-row>
        </van-row>
        <van-row style="padding: 15px 5px 5px 5px">
          <van-button
            size="large"
            @click="getAddFood"
            color="#00C0FF"
            round
            style="height: 40px"
          >
            新增运动
          </van-button>
        </van-row>
        <van-row style="padding: 5px">
          <van-button
            @click="handleSave"
            size="large"
            color="#005DFF"
            round
            style="height: 40px"
          >
            保存日记
          </van-button>
        </van-row>
      </van-row>
    </van-row>
    <!-- 运动选择 -->
    <van-row v-if="isFoodList">
      <van-row class="foodNav">
        <van-icon name="arrow-left" size="24px" @click="goBack"></van-icon>
      </van-row>
      <van-row class="main">
        <!-- 查询 -->
        <van-row
          style="margin-bottom: 10px"
          type="flex"
          justify="space-between"
          align="center"
        >
          <van-field
            class="nameCell"
            v-model="foodName"
            clearable
            placeholder="请输入运动名称"
          />
          <van-button class="btn" size="mini" round color="#005DFF"
            >查询</van-button
          >
        </van-row>
        <van-row
          v-for="(item, index) in data"
          :key="index"
          class="con"
          type="flex"
          justify="space-between"
          align="center"
        >
          <van-row class="title">{{ item.sportName }}</van-row>
          <van-button
            size="mini"
            round
            style="padding: 10px 15px"
            color="#005DFF"
            @click="getSel(item)"
            >选择</van-button
          >
        </van-row>
      </van-row>
    </van-row>
    <van-popup v-model="showPopup">
      <van-field v-model="newTime" placeholder="请输入时长（分钟）"></van-field>
      <van-row type="flex" justify="space-between" style="padding: 10px">
        <van-button size="small" @click="getUpt(newTime)" color="#4285F4"
          >确定</van-button
        >
        <van-button size="small" @click="getQx">取消</van-button>
      </van-row>
    </van-popup>
  </div>
</template>
  
  <script>
import fitNav from "@/components/fitNav.vue";
import {
  getSportByDate,
  getSportList,
  saveSportDiary,
  getUserWeight,
} from "../api/diary";
import { Toast } from "vant";
export default {
  name: "dietDiary",
  data() {
    return {
      //日记是否显示
      isDiary: true,
      // 选择是否显示
      isFoodList: false,
      //是否交换
      //日期
      date: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      list: [],
      //所有运动
      data: [],
      //模糊查询
      foodName: "",
      //体重
      weight:0,
      //更改时间
      showPopup: false,
      newTime: "",
      currentItem: {},

      //运动时间
      sportTime: 30,
      energyTotalConsume:""
    };
  },
  components: {
    fitNav,
  },
  mounted() {
    //获取所有运动
    getSportList().then((res) => {
      this.data = res.data;
    });
    //获取用户体重
    getUserWeight().then((res) => {
      if (!res.data) {
        this.weight = "100";
      } else {
        this.weight = res.data.weight;
      }
    });
    //获取当日日期并查询
    let date = new Date(),
      year = date.getFullYear(), //获取完整的年份(4位)
      month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
      strDate = date.getDate(); // 获取当前日(1-31)
    if (month < 10) month = `0${month}`; // 如果月份是个位数，在前面补0
    if (strDate < 10) strDate = `0${strDate}`;
    this.date = `${year}-${month}-${strDate}`;
    this.getByDate();
  },

  methods: {
    //选择运动
    getSel(item) {
      this.isDiary = true;
      this.isFoodList = false;
      let from = {
        kcal:item.kcal,
        kcalId: item.skcalId,
        sportTime: this.sportTime,
        energyConsume: Number((this.sportTime * item.kcal * this.weight).toFixed(1))
      };
      this.list.push(from);
    },
    //根据日期查看
    getByDate() {
      getSportByDate(this.date).then((res) => {
        if (res.code == 200) {
          if (!res.data) {
            this.list = [];
          } else {
            this.list = res.data.wellUserSportKcals;
            this.energyTotalConsume = res.data.energyTotalConsume;
          }
        }
      });
    },
    //新增运动
    getAddFood() {
      this.isDiary = false;
      this.isFoodList = true;
      this.foodName = "";
    },
    //根据id获取name
    getByID(id) {
      if (id != undefined) {
        let item = this.data.find((res) => res.skcalId == id);
        let name = item.sportName;
        return name;
      }
    },
    //保存日记
    handleSave() {
      let data = {
        entryTime: this.date,
        wellUserSportKcals: this.list,
      };
      saveSportDiary(data).then((r) => {
        if (r.code == 200) {
          Toast.success("保存成功！");
        } else {
          Toast.fail("保存失败！");
        }
      });
    },
    //返回日记
    goBack() {
      this.isDiary = true;
      this.isFoodList = false;
    },

    //更改时长
    handleUpt(item) {
      this.showPopup = true;
      this.currentItem = item;
    },
    getUpt(newTime) {
      if (isNaN(Number(newTime))) {
        Toast.fail("请输入分钟！");
        return;
      }
      let item = this.list.find(
        (r) =>
          r.kcalId == this.currentItem.kcalId
      );
      item.sportTime = newTime;
      let kcalItem = this.data.find((res) => res.skcalId == item.kcalId);
      item.energyConsume = Number((newTime * kcalItem.kcal * this.weight).toFixed(1))
      this.showPopup = false;
    },
    getQx() {
      this.showPopup = false;
    },
    //切换时间
    uptDate(val) {
      let date = new Date(val),
        year = date.getFullYear(), //获取完整的年份(4位)
        month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
        strDate = date.getDate(); // 获取当前日(1-31)
      if (month < 10) month = `0${month}`; // 如果月份是个位数，在前面补0
      if (strDate < 10) strDate = `0${strDate}`;
      this.date = `${year}-${month}-${strDate}`;
      this.getByDate();
    },
  },
};
</script>
  
  <style scoped>
* {
  padding: 0;
  margin: 0;
}
.box {
  background: url("../assets/bkg.png") no-repeat;
  background-size: 100%;
  width: 100%;
  overflow: hidden;
  padding-top: 2rem;
}
.b-title {
  padding: 10px;
  font-size: 0.5rem;
}
.main {
  background: #dbe3f9;
  border-radius: 32px 32px 0px 2px;
  font-family: PingFang SC ExtraLight;
  color: #1d1d1d;
  padding: 1rem 10px 0.5rem 10px;
  min-height: calc(100vh - 5.25rem);
  line-height: 0.6rem;
}
.date {
  background-color: #f3f6fe;
  padding: 5px 10px;
  border-radius: 32px;
}
.dataCell {
  width: 3rem;
  height: 30px;
  background-color: #f3f6fe;
  padding-top: 2px;
}
/deep/ .van-field__control {
  text-align: center;
  color: #4285f4;
}

.tab-con {
  border-radius: 32px;
  padding: 10px;
  overflow: hidden;
  background-color: #f3f6fe;
  margin-top: 10px;
}
.t-title {
  display: inline-block;
  /* padding: 5px; */
  color: #4285f4;
  font-size: 0.4rem;
}
/* /deep/ .van-tab__text--ellipsis {
  font-size: 0.4rem;
} */
.con {
  background-color: #f3f6fe;
  border-radius: 32px;
  padding: 5px;
  margin-bottom: 10px;
}
.title {
  font-size: 0.4rem;
  padding-left: 15px;
  color: #4285f4;
}

.nameCell {
  width: 5rem;
  border-radius: 32px;
  height: 1rem;
  margin-left: 10px;
  line-height: 20px;
  padding-top: 8px;
}
.btn {
  margin-right: 10px;
  font-size: 0.45rem;
  padding: 15px 20px;
}
.foodNav {
  position: fixed;
  top: 0px;
  padding: 10px 0px;
  width: 100%;
  background-color: white;
}
.type {
  font-size: 0.35rem;
  color: #999999;
  margin-top: 5px;
  display: inline-block;
}
</style>