<template>
    <!-- 运动周报 -->
    <div class="box">
      <fit-nav title=""></fit-nav>
      <van-row v-if="noData" >
        无用户记录
    </van-row>
    <van-row v-else>
      <van-row class="main">
        <!-- 达标  -->
        <van-row class="m-block">
          <van-col span="8">
            <div style="padding: 10px">
              达标 <span style="color: #666666">(天)</span>
            </div>
            <div class="b-num">{{ weekly.validDays }}</div>
          </van-col>
          <van-col span="16" class="b-txt">
            {{ weekly.feedback }}
          </van-col>
        </van-row>
        <!-- 记录 -->
        <van-row class="m-block">
          <van-row>
            <van-col span="8" style="padding-top: 20px">
              <div style="padding: 10px 10px 0px 10px">记录：</div>
              <div class="v-num">{{ weekly.recordDay }}次</div>
              <div style="padding: 10px 10px 0px 10px">总摄入：</div>
              <div class="v-num">{{ weekly.sumEnergy}}</div>
            </van-col>
            <van-col span="16" class="b-ver">
              <div id="vertical" style="height: 200px; width: 100%"></div>
            </van-col>
          </van-row>
          <van-row
            type="flex"
            justify="space-between"
            style="padding: 25px 15px 10px 15px"
          >
            <van-col span="12">
              本周日均：<span style="color: #666">{{ weekly.avgEnergy }}</span>
            </van-col>
            <!-- <van-col span="12">
              较上周：<span style="color: #666">1000</span>
            </van-col> -->
          </van-row>
        </van-row>
        <van-row class="m-block">
          <van-row type="flex" align="center">
            <van-image
              width="18"
              height="18"
              :src="require('../assets/pace.png')"
            ></van-image>
            <div class="b-title">日均耗能相当于</div>
            <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
          </van-row>
          <van-row type="flex" align="center" justify="center">
            <van-circle
              v-model="currentRate"
              :rate="weekly.riceRate"
              :stroke-width="100"
              :text="weekly.energyMemo"
              :color="gradientColor"
            />
          </van-row>
        </van-row>
        <!-- 本周运动情况 -->
        <van-row class="m-sport">
          <van-row type="flex" align="center">
            <van-image
              width="18"
              height="18"
              :src="require('../assets/pace.png')"
            ></van-image>
            <div class="b-title">本周运动情况</div>
            <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
          </van-row>
          <van-row style="padding: 10px;">
            <div>运动时间：<span style="color: #A6A9B3;">{{ weekly.sportsTime }}分钟</span></div>
            <!-- <div>抗阻运动：<span style="color: #A6A9B3;">100分钟 不达标</span></div>
            <div>拉伸运动：<span style="color: #A6A9B3;">100分钟 不达标</span></div> -->
          </van-row>
        </van-row>
      </van-row>
      </van-row>
    </div>
  </template>
  
  <script>
  import fitNav from "@/components/fitNav.vue";
  import * as echarts from "echarts";
  import { sportsWeeklyReport } from "../api/report"

  var myChart

  export default {
    components: { fitNav },
    name: "dietWeekly",
    data() {
      return {
        currentRate: 0,
        gradientColor:{
          "50%":"#FBA55B",
          "100%" :  "#FFC88D"
        },
        weekly: {
          feedback:'',
          riceRate: 0,
          sumEnergy: 0,
          avgEnergy: 0,
          recordDay: 0,
          sumIntake: 0,
          validDays: 0,
          energyMemo: "",
          sportsTime: 0
      },
      noData: false
      };
    },
    mounted() {
      myChart = echarts.init(document.getElementById("vertical"));
    },
    created() {
    let id = this.$route.query.id;
    this.getSportsWeeklyReport(id);
  },
  methods: {
    getSportsWeeklyReport(id) {
      sportsWeeklyReport(id).then((res) => {  
        this.weekly = res.data
        if (JSON.stringify(this.weekly) == "{}") {
          this.noData = true
        }
        myChart.setOption({
        xAxis: {
          data: ["一", "二", "三", "四", "五", "六", "日"],
        },
        yAxis: {},
        series: [
          {
            data: this.weekly.weekSports,
            type: "bar",
            stack: "x",
          },
        ],
      });
      })
    },
  }
  };
  </script>
  
  <style scoped>
  * {
    padding: 0;
    margin: 0;
  }
  .box {
    background: url("../assets/spWeekly.png") no-repeat;
    background-size: 100%;
    padding-top: 5.5rem;
    overflow: hidden;
  }
  .main {
    padding: 1rem 10px 10px 10px;
    background: #dbe3f9;
    border-radius: 32px 32px 0px 2px;
    font-family: PingFang SC ExtraLight;
    line-height: 0.6rem;
  }
  .m-block {
    padding: 10px;
    background-color: #f3f6fe;
    border-radius: 32px;
    margin-bottom: 10px;
  }
  .b-num {
    color: #f0843a;
    font-size: 1.3rem;
    text-align: center;
    padding: 25px 0px;
  }
  .b-txt {
    background-color: #ebf0fe;
    padding: 15px;
    border-radius: 28px;
  }
  .b-ver {
    background-color: #ebf0fe;
    padding: 0px 15px;
    border-radius: 28px;
  }
  #vertical {
    /* height: 4rem; */
    /* height: 200px; */
  }
  .v-num {
    padding: 10px;
    font-size: 0.6rem;
    color: #666;
  }
  .b-title {
    padding: 5px 10px;
    font-size: 0.35rem;
  }
  .m-sport{
    background: url('../assets/debate.png') no-repeat;
    background-size: 100%;
    padding: 10px;
    border-radius: 32px;
    margin-bottom: 10px;
    font-size: 0.35rem;
    background-color: #f3f6fe;
  }
  </style>