import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "lib-flexible";
import '../src/utils/vant' //引入vant
import 'amfe-flexible'
import 'vant/lib/index.less'
import TtsRecorder from "@/assets/js/audio";
import '@/utils/filter.js'
import {
  setCookie,
  getCookie,
  checkCookie,
  clearCookie
} from '@/utils/cookie';
import {
  calendar,button,datePicker
} from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';

Vue.use(calendar);
Vue.use(button);
Vue.use(datePicker);

Vue.config.productionTip = false;

Vue.prototype.$setCookie = setCookie;
Vue.prototype.$getCookie = getCookie;
Vue.prototype.$checkCookie = checkCookie;
Vue.prototype.$clearCookie = clearCookie;
Vue.prototype.$ttsRecorder = new TtsRecorder();



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')