<template>
  <!-- 我的报告 -->
  <div class="r-box">
    <fitNav title="我的报告"></fitNav>
    <van-row class="r-main">
      <van-tabs
        v-model="active"
        title-active-color="#4285F4"
        @click="onTabClick"
      >
        <van-tab title="问卷">
          <van-row
            class="m-step"
            v-for="(item, index) in list"
            :key="'i' + index"
          >
            <van-row type="flex" align="center" style="margin: 10px 0px">
              <van-image
                width="20px"
                height="15px"
                :src="require('../assets/step.png')"
              >
              </van-image>
              <div style="padding: 10px">{{ item.finishTime }}</div>
            </van-row>
            <van-row class="m-info" type="flex" align="center">
              <van-row
                type="flex"
                justify="space-between"
                align="center"
                class="in-btn"
                @click="handleHistory(item.id)"
              >
                <van-image
                  height="30px"
                  width="30px"
                  :src="require('../assets/step1.png')"
                ></van-image>
                <div style="padding: 0px 10px; color: #4285f4">问卷信息</div>
              </van-row>
              <van-row
                type="flex"
                justify="space-between"
                align="center"
                class="in-btn"
                @click="handleReport(item.id)"
              >
                <van-image
                  height="30px"
                  width="30px"
                  :src="require('../assets/step2.png')"
                ></van-image>
                <div style="padding: 0px 10px; color: #07bc7b">查看报告</div>
              </van-row>
            </van-row>
          </van-row>
        </van-tab>
        <van-tab title="日报">
          <van-pull-refresh v-model="isLoading" @refresh="pullDown">
            <van-list
              v-model="loading"
              :finished="completed"
              :immediate-check="false"
              finished-text="没有更多了"
              @load="pullUp"
            >
              <van-row
                class="m-step"
                v-for="(item, index) in dailyList"
                :key="'d' + index"
              >
                <van-row type="flex" align="center" style="margin: 10px 0px">
                  <van-image
                    width="20px"
                    height="15px"
                    :src="require('../assets/step.png')"
                  >
                  </van-image>
                  <div style="padding: 10px">{{ item.startTime }}</div>
                </van-row>
                <van-row class="m-info" @click="handleDaily(item.id)">
                  <van-row class="m-card" style="margin-top: 8px" type="flex">
                    <van-col span="17" style="padding: 10px">
                      <div class="card-title">日报</div>
                      <van-row type="flex" align="center" class="card-list">
                        <div style="padding: 5px 0px">{{ item.startTime }}</div>
                      </van-row>
                    </van-col>
                    <van-col span="7" class="card-right">
                      <van-button round size="small" type="info" color="#005DFF"
                        >查看报告</van-button
                      >
                    </van-col>
                  </van-row>
                </van-row>
              </van-row>
            </van-list>
          </van-pull-refresh>
        </van-tab>
        <van-tab title="周报">
          <van-pull-refresh v-model="isLoading" @refresh="pullDown">
            <van-list
              v-model="loading"
              :finished="completed"
              :immediate-check="false"
              finished-text="没有更多了"
              @load="pullUp"
            >
              <van-row
                class="m-step"
                v-for="(item, index) in weeklyList"
                :key="'d' + index"
              >
                <van-row type="flex" align="center" style="margin: 10px 0px">
                  <van-image
                    width="20px"
                    height="15px"
                    :src="require('../assets/step.png')"
                  >
                  </van-image>
                  <div style="padding: 10px">{{ item.endTime }}</div>
                </van-row>
                <van-row class="m-info">
                  <van-row
                    class="m-card"
                    style="margin-top: 8px"
                    type="flex"
                    @click="handleDietWeekly(item.id)"
                  >
                    <van-col span="17" style="padding: 10px">
                      <div class="card-title">膳食周报</div>
                      <van-row type="flex" align="center" class="card-list">
                        <div style="padding: 5px 0px">
                          {{ item.startTime }}
                        </div>
                      </van-row>
                    </van-col>
                    <van-col span="7" class="card-right">
                      <van-button round size="small" type="info" color="#005DFF"
                        >查看报告</van-button
                      >
                    </van-col>
                  </van-row>

                  <van-row
                    class="m-card"
                    style="margin-top: 8px"
                    type="flex"
                    @click="handleSportsWeekly(item.id)"
                  >
                    <van-col span="17" style="padding: 10px">
                      <div class="card-title">运动周报</div>
                      <van-row type="flex" align="center" class="card-list">
                        <div style="padding: 5px 0px">
                          {{ item.startTime }}-{{ item.endTime }}
                        </div>
                      </van-row>
                    </van-col>
                    <van-col span="7" class="card-right">
                      <van-button round size="small" type="info" color="#005DFF"
                        >查看报告</van-button
                      >
                    </van-col>
                  </van-row>

                  <van-row
                    class="m-card"
                    style="margin-top: 8px"
                    type="flex"
                    @click="handleClockWeekly(item.id)"
                  >
                    <van-col span="17" style="padding: 10px">
                      <div class="card-title">打卡周报</div>
                      <van-row type="flex" align="center" class="card-list">
                        <div style="padding: 5px 0px">
                          {{ item.startTime }}-{{ item.endTime }}
                        </div>
                      </van-row>
                    </van-col>
                    <van-col span="7" class="card-right">
                      <van-button round size="small" type="info" color="#005DFF"
                        >查看报告</van-button
                      >
                    </van-col>
                  </van-row>
                </van-row>
              </van-row>
            </van-list>
          </van-pull-refresh>
        </van-tab>
        <van-tab title="月报">
          <van-pull-refresh v-model="isLoading" @refresh="pullDown">
            <van-list
              v-model="loading"
              :finished="completed"
              :immediate-check="false"
              finished-text="没有更多了"
              @load="pullUp"
            >
              <van-row
                class="m-step"
                v-for="(item, index) in monthlyList"
                :key="'d' + index"
              >
                <van-row type="flex" align="center" style="margin: 10px 0px">
                  <van-image
                    width="20px"
                    height="15px"
                    :src="require('../assets/step.png')"
                  >
                  </van-image>
                  <div style="padding: 10px">{{ item.endTime }}</div>
                </van-row>
                <van-row class="m-info">
                  <van-row
                    class="m-card"
                    style="margin-top: 8px"
                    type="flex"
                    @click="handleDietMonthly(item.id)"
                  >
                    <van-col span="17" style="padding: 10px">
                      <div class="card-title">膳食月报</div>
                      <van-row type="flex" align="center" class="card-list">
                        <div style="padding: 5px 0px">
                          {{ item.startTime }}
                        </div>
                      </van-row>
                    </van-col>
                    <van-col span="7" class="card-right">
                      <van-button round size="small" type="info" color="#005DFF"
                        >查看报告</van-button
                      >
                    </van-col>
                  </van-row>

                  <van-row
                    class="m-card"
                    style="margin-top: 8px"
                    type="flex"
                    @click="handleSportsMonthly(item.id)"
                  >
                    <van-col span="17" style="padding: 10px">
                      <div class="card-title">运动月报</div>
                      <van-row type="flex" align="center" class="card-list">
                        <div style="padding: 5px 0px">
                          {{ item.startTime }}-{{ item.endTime }}
                        </div>
                      </van-row>
                    </van-col>
                    <van-col span="7" class="card-right">
                      <van-button round size="small" type="info" color="#005DFF"
                        >查看报告</van-button
                      >
                    </van-col>
                  </van-row>

                  <van-row
                    class="m-card"
                    style="margin-top: 8px"
                    type="flex"
                    @click="handleClockMonthly(item.id)"
                  >
                    <van-col span="17" style="padding: 10px">
                      <div class="card-title">打卡月报</div>
                      <van-row type="flex" align="center" class="card-list">
                        <div style="padding: 5px 0px">
                          {{ item.startTime }}-{{ item.endTime }}
                        </div>
                      </van-row>
                    </van-col>
                    <van-col span="7" class="card-right">
                      <van-button round size="small" type="info" color="#005DFF"
                        >查看报告</van-button
                      >
                    </van-col>
                  </van-row>
                </van-row>
              </van-row>
            </van-list>
          </van-pull-refresh>
        </van-tab>
      </van-tabs>
    </van-row>
  </div>
</template>
  
  <script>
import fitNav from "@/components/fitNav.vue";
import { getQuestionList } from "../api/index";
import { cycleReportList } from "../api/report";

export default {
  components: { fitNav },
  name: "report",
  data() {
    return {
      loading: false,
      isLoading: false,
      completed: false,
      finished: false,
      loadingTip: "拼命加载中...",

      list: [],
      //tabs默认选项
      active: 0,
      currentTab: 0,
      // 日报
      dailyParams: {
        pageNum: 1,
        pageSize: 10,
        type: 3,
      },
      // 周报
      weeklyParams: {
        pageNum: 1,
        pageSize: 10,
        type: 2,
      },
      // 月报
      monthLyParams: {
        pageNum: 1,
        pageSize: 10,
        type: 1,
      },
      // 日
      dailyList: [],
      // 周
      weeklyList: [],
      // 月
      monthlyList: [],
      //上一级路由
      fromPath: "",
    };
  },
  created() {
    this.getInfos();
  },
  //上个页面的路由地址
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log(from);
      vm.fromPath = from.path;
    });
  },
  mounted() {
    //问卷
    if (
      this.fromPath == "/questionnaireHistory" ||
      this.fromPath == "/totalReport"
    ) {
      this.active = 0;
      //日报
    } else if (this.fromPath == "/taskSum") {
      this.active = 1;
      this.onTabClick();
      //周报
    } else if (
      this.fromPath == "/dietWeekly" ||
      this.fromPath == "/sportWeekly" ||
      this.fromPath == "/clockWeekly"
    ) {
      this.active = 2;
      this.onTabClick();
      //月报
    } else if (
      this.fromPath == "/dietMonthly" ||
      this.fromPath == "/sportMonthly" ||
      this.fromPath == "/clockMonthly"
    ) {
      this.active = 3;
      this.onTabClick();
    }
  },
  methods: {
    // 下拉刷新，回到第一页
    pullDown() {
      this.completed = false;
      this.isLoading = true;
      if (this.active == 1) {
        setTimeout((res) => {
          this.dailyParams.pageNum = 1;
          this.geteReportList(this.dailyParams);
          this.isLoading = false;
        }, 500);
      } else if (this.active == 2) {
        setTimeout((res) => {
          this.weeklyParams.pageNum = 1;
          this.geteReportList(this.weeklyParams);
          this.isLoading = false;
        }, 500);
      } else if (this.active == 3) {
        setTimeout((res) => {
          this.monthLyParams.pageNum = 1;
          this.geteReportList(this.monthLyParams);
          this.isLoading = false;
        }, 500);
      }
    },
    //加载下一页
    pullUp() {
      if (this.completed) {
        return;
      }
      if (this.active == 1) {
        this.dailyParams.pageNum++;
        setTimeout((res) => {
          this.geteReportList(this.dailyParams);
          window.scrollTo(0, 0);
        }, 1500);
      } else if (this.active == 2) {
        this.weeklyParams.pageNum++;
        setTimeout((res) => {
          this.geteReportList(this.weeklyParams);
          window.scrollTo(0, 0);
        }, 1500);
      } else if (this.active == 3) {
        this.monthLyParams.pageNum++;
        setTimeout((res) => {
          this.geteReportList(this.monthLyParams);
          window.scrollTo(0, 0);
        }, 1000);
      }
    },

    //获取问卷信息
    getInfos() {
      getQuestionList().then((res) => {
        if (res.code == 200) {
          this.list = res.data;
        }
      });
    },
    // tabs切换获取信息
    onTabClick(name, title) {
      this.currentTab = this.active;
      this.pullDown();
    },
    //获取日报，周报，月报
    geteReportList(data) {
      if (this.completed) return;
      this.loading = true;
      cycleReportList(data).then((res) => {
        if (res.code == 200) {
          if (this.currentTab == 1) {
            this.dailyList = [];
            this.dailyList.push(...res.rows);
            this.loading = false;
            if (
              this.dailyParams.pageNum * this.dailyParams.pageSize >
              res.total
            ) {
              this.completed = true;
            }
          } else if (this.currentTab == 2) {
            this.weeklyList = [];
            this.weeklyList.push(...res.rows);
            this.loading = false;
            if (
              this.weeklyParams.pageNum * this.weeklyParams.pageSize >
              res.total
            ) {
              this.completed = true;
            }
          } else if (this.currentTab == 3) {
            this.monthlyList = [];
            this.monthlyList.push(...res.rows);
            this.loading = false;
            if (
              this.monthLyParams.pageNum * this.monthLyParams.pageSize >
              res.total
            ) {
              this.completed = true;
            }
          } else {
            this.loading = false;
          }
        } else {
          this.isLoading = false;
        }
      });
    },
    //问卷
    handleHistory(id) {
      this.$router.push({
        path: "/questionnaireHistory",
        query: { recordId: id },
      });
    },
    //问卷
    handleReport(id) {
      this.$router.push({
        path: "/totalReport",
        query: { recordId: id },
      });
    },
    //日报
    handleDaily(id) {
      this.$router.push({
        path: "/taskSum",
        query: { id: id },
      });
    },
    //膳食月报
    handleDietMonthly(id) {
      this.$router.push({
        path: "/dietMonthly",
        query: { id: id },
      });
    },
    //膳食周报
    handleDietWeekly(id) {
      this.$router.push({
        path: "/dietWeekly",
        query: { id: id },
      });
    },
    //运动月报
    handleSportsMonthly(id) {
      this.$router.push({
        path: "/sportMonthly",
        query: { id: id },
      });
    },
    //运动周报
    handleSportsWeekly(id) {
      this.$router.push({
        path: "/sportWeekly",
        query: { id: id },
      });
    },
    //打卡周报
    handleClockWeekly(id) {
      this.$router.push({
        path: "/clockWeekly",
        query: { id: id },
      });
    },
    //打卡月报
    handleClockMonthly(id) {
      this.$router.push({
        path: "/clockMonthly",
        query: { id: id },
      });
    },
  },
};
</script>
  
  <style scoped lang="less">
* {
  padding: 0px;
  margin: 0px;
}
.r-box {
  width: 100%;
  overflow: hidden;
  padding-top: 5.5rem;
  background: url("../assets/reportBck.png") no-repeat;
  background-size: 100%;
}
.r-main {
  padding: 1rem 10px 1rem 10px;
  background: #dbe3f9;
  border-radius: 32px 32px 0px 2px;
  font-family: PingFang SC ExtraLight;
  line-height: 0.6rem;
  min-height: calc(100vh - 7.5rem);
}
.m-step {
  padding: 5px;
  color: #666666;
}
.m-info {
  border-left: 2px dashed #005dff;
  padding: 10px 0px;
  margin-left: 10px;
}
.in-btn {
  padding: 5px 10px;
  background-color: #f3f6fe;
  border-radius: 33px;
  margin-left: 15px;
}

.m-card {
  padding: 15px 25px;
  background: #f3f6fe;
  border-radius: 2px 32px 32px 32px;
  width: 70%;
  margin-left: 15px;
}
.m-card {
  background: url("../assets/card-report.png") no-repeat;
  background-size: 100% 100%;
}
.card-list {
  color: #666666;
  font-size: 0.33rem;
  line-height: 0.45rem;
}
.card-title {
  /* padding: 10px 0px 0px 0px; */
  font-size: 0.4rem;
  color: #005dff;
}
.card-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
/deep/ .van-tabs__nav {
  background-color: #dbe3f9;
}
/deep/ .van-tabs__line {
  background-color: #4285f4;
}
</style>