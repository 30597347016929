import Vue from 'vue'
import VueRouter from 'vue-router'
import { getCookie } from '@/utils/cookie'
import index from '../views/index.vue'
import lifestyleEpq from '../views/lifestyle-epq.vue'
import cdRiskCard from '../views/cdRisk-card.vue'
import basicQuest from '../views/basicQuest.vue'
import reports from '../views/reports.vue'
import login from '../views/login.vue'
import lifestyleCard from '../views/lifestyle-card.vue'
import task from '../views/task.vue'
import report from '../views/report.vue'
import dietDiary from '../views/dietDiary.vue'
import personalCenter from '../views/personalCenter.vue'
import fitKepu from '../views/fitKepu.vue'
import cardiovascular from '../views/cardiovascular.vue'
import ascvdEpq from '../views/ascvd-epq.vue'
import baseInfo from '../views/base-info.vue'
import kepuDetail from '../views/kepuDetail.vue'
import addSwap from '../views/add-swap.vue'
import totalReport from '../views/totalReport.vue'
import taskSum from '../views/taskSum.vue'
import dietWeekly from '../views/dietWeekly.vue'
import sportWeekly from '../views/sportWeekly.vue'
import dietMonthly from '../views/dietMonthly.vue'
import sportMonthly from '../views/sportMonthly.vue'
import clockWeekly from '../views/clockWeekly.vue'
import clockMonthly from '../views/clockMonthly.vue'
import sportDiary from '../views/sportDiary.vue'
import questionnaireHistory from '../views/questionnaire-history.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: login
  },
  {
    path: '/',
    component: index
  },
  {
    path: '/index',
    component: index
  },
  {
    path: '/baseInfo',
    component: baseInfo
  },
  {
    path: '/lifestyleEpq',
    component: lifestyleEpq
  },
  {
    path: '/cdRiskCard',
    component: cdRiskCard
  },
  {
    path: '/basicQuest',
    component: basicQuest
  },
  {
    path: '/reports',
    component: reports
  },
  {
    path: '/lifestyleCard',
    component: lifestyleCard
  },
  {
    path: "/report",
    component: report
  },
  {
    path: "/task",
    component: task
  },
  {
    path: "/dietDiary",
    component: dietDiary,
  },
  {
    path: "/personalCenter",
    component: personalCenter
  },
  {
    path: "/fitKepu",
    component: fitKepu
  },
  {
    path: "/cardiovascular",
    component: cardiovascular
  },
  {
    path: "/ascvdEpq",
    component: ascvdEpq
  },
  {
    path: "/kepuDetail",
    component: kepuDetail
  },
  {
    path: "/addSwap",
    component: addSwap
  },
  {
    path: "/totalReport",
    component: totalReport
  },
  {
    path: "/taskSum",
    component: taskSum
  },
  {
    path: "/dietWeekly",
    component: dietWeekly
  },
  {
    path: "/sportWeekly",
    component: sportWeekly
  },
  {
    path: "/dietMonthly",
    component: dietMonthly
  },
  {
    path: "/sportMonthly",
    component: sportMonthly
  },
  {
    path: "/clockWeekly",
    component: clockWeekly
  },
  {
    path: "/clockMonthly",
    component: clockMonthly
  },
  {
    path: "/sportDiary",
    component: sportDiary
  },
  {
    path: "/questionnaireHistory",
    component: questionnaireHistory
  }, {
    path: '/serviceterms',
    component: () => import('@/views/serviceterms.vue')
  }, {
    path: '/privacypolicy',
    component: () => import('@/views/privacypolicy.vue')
  }
]

const router = new VueRouter({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes
})

router.beforeEach((to, from, next) => {
  if (getCookie('token')) {
    /* has token*/
    if ((to.path === '/')) {
      if (getCookie('userName') != "") {
        next({ path: '/reports' })
      } else {
        next({ path: '/baseInfo' })
      }
    } else {
      next()
    }
  } else {
    // 没有token
    if (to.path === '/' || to.path === '/login' || to.path === '/kepuDetail' || to.path === '/fitKepu' || to.path == '/serviceterms' || to.path == '/privacypolicy') {
      next()
    } else {
      next('/')
    }
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export default router