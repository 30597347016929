<template>
  <!-- 慢病风险报告 -->
  <div class="box">
    <!-- 导航条 -->
    <fitNav :title="title"></fitNav>
    <van-row class="main">
      <!-- 我的健康风险 -->
      <van-row style="padding: 10px" v-show="riskVisible"
        >我的健康风险<van-icon
          name="like-o"
          color="#4285F4"
          style="padding-left: 5px"
        ></van-icon
      ></van-row>
      <!-- 慢病风险 -->
      <van-row
        v-show="riskVisible"
        v-for="(item, index) in riskList"
        :key="'m' + index"
      >
        <van-row class="m-collapse">
          <van-row type="flex" align="center">
            <van-image
              width="18px"
              height="18px"
              :src="require('../assets/steps.png')"
            ></van-image>
            <div class="life-title">{{ item.riskTitle }}</div>
            <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
          </van-row>
          <van-row
            class="col-con"
            v-for="(colItem, index) in item.colLists"
            :key="index"
          >
            <van-row class="con-title"> {{ colItem.title }} </van-row>
            <van-row type="flex" align="center" style="padding-bottom: 5px">
              <div class="con-con" v-if="colItem.riskCode == 'bp'">
                当前血压
              </div>
              <div class="con-con" v-else-if="colItem.riskCode == 'tc'">
                总胆固醇
              </div>
              <div class="con-con" v-else-if="colItem.riskCode == 'tg'">
                甘油三酯
              </div>
              <div class="con-con" v-else-if="colItem.riskCode == 'hdl'">
                高密度脂蛋白胆固醇
              </div>
              <div class="con-con" v-else-if="colItem.riskCode == 'ldl'">
                低密度脂蛋白胆固醇
              </div>
              <div class="con-con" v-else-if="colItem.riskCode == 'bmi'">
                当前BMI
              </div>
              <div class="con-con" v-else-if="colItem.riskCode == 'wc'">
                当前腰围
              </div>
              <div class="con-num">{{ colItem.current }}</div>
            </van-row>
            <van-row type="flex" align="center">
              <div class="con-con">正常范围</div>
              <div class="con-num">{{ colItem.normal }}</div>
            </van-row>
          </van-row>

        </van-row>
      </van-row>
      <!-- 我能改变 -->
      <van-row style="padding: 10px" v-show="canChangeVisible"
        >我能改变<van-icon
          name="like-o"
          color="#4285F4"
          style="padding-left: 5px"
        ></van-icon
      ></van-row>
      <van-row
        v-show="canChangeVisible"
        v-for="(item, index) in canChangeList"
        :key="index"
      >
        <van-row class="m-collapse">
          <van-row type="flex" align="center">
            <van-image
              width="18px"
              height="18px"
              :src="require('../assets/steps.png')"
            ></van-image>
            <div class="life-title">{{ item.title }}</div>
            <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
          </van-row>
          <van-row class="col-con" style="color: #666666;">
            {{ item.normal }}
          </van-row>
        </van-row>
      </van-row>
      <!-- 不可改变 -->
      <van-row style="padding: 10px" v-show="notChangeVisible"
        >不可改变<van-icon
          name="like-o"
          color="#4285F4"
          style="padding-left: 5px"
        ></van-icon
      ></van-row>
      <van-row class="m-collapse" v-show="notChangeVisible">
        <van-row type="flex" align="center">
          <van-image
            width="18px"
            height="18px"
            :src="require('../assets/steps.png')"
          ></van-image>
          <div class="life-title">{{ notChange.title }}</div>
          <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
        </van-row>
        <van-row class="col-con" style="color: #666666;">
          {{ notChange.normal }}
        </van-row>
      </van-row>
      <!-- 我应如何做 -->
      <van-row style="padding: 10px" v-show="iCanDoVisible"
        >我应如何做<van-icon
          name="like-o"
          color="#4285F4"
          style="padding-left: 5px"
        ></van-icon
      ></van-row>
      <van-row v-show="iCanDoVisible">
        <!-- 诊疗建议 -->
        <van-row class="m-collapse" v-show="zljyVisible">
          <van-row type="flex" align="center">
            <van-image
              width="18px"
              height="18px"
              :src="require('../assets/steps.png')"
            ></van-image>
            <div class="life-title">诊疗建议</div>
            <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
          </van-row>
          <van-row
            class="col-con"
            style="color: #666666;"
            v-for="(item, index) in zljy"
            :key="'z' + index"
          >
            {{ item.details }}
          </van-row>
        </van-row>

        <!-- 评估建议 -->
        <van-row class="m-collapse" v-show="pgjyVisible">
          <van-row type="flex" align="center">
            <van-image
              width="18px"
              height="18px"
              :src="require('../assets/steps.png')"
            ></van-image>
            <div class="life-title">评估建议</div>
            <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
          </van-row>
          <van-row
            class="col-con"
            style="color: #666666;"
            v-for="(item, index) in pgjy"
            :key="'p' + index"
          >
            {{ item.details }}
          </van-row>
        </van-row>

        <!-- 生活方式建议 -->
        <van-row class="m-collapse" v-show="shfsjyVisible">
              <van-row type="flex" align="center">
            <van-image
              width="18px"
              height="18px"
              :src="require('../assets/steps.png')"
            ></van-image>
            <div class="life-title">生活方式建议</div>
            <van-icon name="arrow-down" color="#7D9BC0" size="13px"></van-icon>
          </van-row>
              <van-row
                class="col-con"
                style="color: #666666;"
                v-for="(item, index) in shfsjy"
                :key="'s' + index"
              >
                {{ item.details }}
                <van-row type="flex" align="center" style="padding-top: 5px" @click="handleTask">
                  <div style="color: #4285f4; padding-right: 5px">
                    {{ item.normal }}
                  </div>
                  <div><van-icon name="chat-o" color="#4285F4" /></div>
                </van-row>
              </van-row>
        </van-row>
      </van-row>
    </van-row>
  </div>
</template>

<script>
import fitNav from "../components/fitNav.vue";
import { basicReport } from "../api/report";

export default {
  name: "cdRisk-card",
  data() {
    return {
      riskVisible: false,
      canChangeVisible: false,
      notChangeVisible: false,
      iCanDoVisible: false,
      zljyVisible: false,
      pgjyVisible: false,
      shfsjyVisible: false,
      title: "",
      riskList: [],
      canChangeList: [],
      notChange: {},
      iCanDo: {},
      zljy: [],
      pgjy: [],
      shfsjy: [],
    };
  },
  components: {
    fitNav,
  },
  created() {
    // console.log(this.$getCookie("recordId"));
    let recordId = this.$route.query.recordId;
    this.getReport(recordId);
  },
  methods: {
    getReport(recordId) {
      basicReport(recordId).then((res) => {
        this.riskList = res.data.riskList;
        this.riskVisible = this.riskList.length > 0;
        this.canChangeList = res.data.canChangeList;
        if (this.canChangeList.length > 0) {
          this.canChangeVisible = true;
        }
        this.notChange = res.data.notChange;
        if (JSON.stringify(this.notChange) == "{}") {
          this.notChangeVisible = false;
        } else {
          this.notChangeVisible = true;
        }

        this.iCanDo = res.data.iCanDo;
        if (JSON.stringify(this.iCanDo) == "{}") {
          this.iCanDoVisible = false;
        } else {
          this.iCanDoVisible = true;
          this.zljy = this.iCanDo.zljy;
          this.pgjy = this.iCanDo.pgjy;
          this.shfsjy = this.iCanDo.shfsjy;
          if (this.zljy.length > 0) {
            this.zljyVisible = true;
          }
          if (this.pgjy.length > 0) {
            this.pgjyVisible = true;
          }
          if (this.shfsjy.length > 0) {
            this.shfsjyVisible = true;
          }
        }
      });
    },

    getStatus(value) {
      console.log(value);
    },
    handleTask() {
      this.$router.push({
        path: "/task",
      });
    }
  },
};
</script>


<style scoped>
* {
  padding: 0;
  margin: 0;
}
.box {
  width: 100%;
  overflow: hidden;
  background: url("../assets/cdBck.png") no-repeat;
  background-size: 100%;
}
.main {
  padding: 20px 10px 10px 10px;
  overflow: hidden;
  background: #dbe3f9;
  border-radius: 32px 32px 0px 2px;
  font-family: PingFang SC ExtraLight;
  /* font-weight: 550; */
  color: #1d1d1d;
  line-height: 0.6rem;
  font-size: 0.38rem;
  /* margin-top: 5.5rem; */
}
.m-collapse {
  background-color: #f3f6fe;
  border-radius: 32px;
  overflow: hidden;
  margin-bottom: 0.3rem;
  padding: 10px 10px 0px 10px;
}
.col-title {
  color: #4285f4;
}
.col-conten,
.should-content {
  color: #666666;
  font-size: 0.3rem;
}
.should-content {
  display: inline-block;
  padding: 0px 15px 0px 15px;
}
.nc-title {
  font-size: 0.3rem;
  color: #666666;
}

.col-con {
  background: #ebf0fe;
  border-radius: 24px;
  padding: 15px;
  margin-bottom: 10px;
}
.con-title {
  color: #4285f4;
  font-size: 0.38rem;
  padding-bottom: 5px;
}
.con-con {
  padding-right: 10px;
  color: #1d1d1d;
  /* font-weight: 400; */
}
.con-num {
  color: #666666;
  /* font-weight: 400; */
}
/deep/ .van-cell {
  background-color: #f3f6fe;
}
.life-title {
  padding: 15px 10px;
  font-size: 0.35rem;
}
</style>