import request from "../utils/request"

// 获取验证码
export function getPhoneCaptcha(data) {
    return request({
      url: '/web/sendVerificationCode',
      method: 'post',
      data: data
    })
  }

  //登录
  export function getUserLogin(data) {
    return request({
      url: '/web/login',
      method: 'post',
      data: data
    })
  }
  
  
