<template>
  <div class="box">
    <van-row class="b-top" type="flex" justify="center" align="center">
      <div class="b-logo"></div>
    </van-row>
    <van-row class="b-main">
      <van-row class="m-login">
        <van-row class="login-title">登录</van-row>
        <van-form ref="form" label-align="center">
          <van-field
            v-model="userForm.username"
            name="手机号"
            label="手机号"
            placeholder="手机号"
            type="tel"
            :rules="[
              { required: true, message: '请填写手机号' },
              { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
            ]"
          />
          <van-field
            v-model="userForm.code"
            name="验证码"
            label="验证码"
            placeholder="验证码"
            :rules="[{ required: true, message: '请填写验证码' }]"
          >
            <van-button
              slot="button"
              size="small"
              type="primary"
              :color="color"
              @click="getCaptcha"
              :disabled="isCaptcha"
              >{{ btnTixt }}</van-button
            >
          </van-field>
          <div style="margin: 16px">
            <van-button
              round
              block
              type="info"
              color="#005DFF"
              native-type="submit"
              @click="getLogin"
              >登录</van-button
            >
          </div>
        </van-form>
      </van-row>
    </van-row>
    <van-row class="b-foot">
      <span style="color: #999999">登录即代表您已同意</span>
      <span @click="goPage('privacypolicy')" class="f-txt">隐私政策</span>
      <span @click="goPage('serviceterms')" class="f-txt">服务条款</span>
    </van-row>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getPhoneCaptcha, getUserLogin } from "../api/login";
import { getUserInfos } from "../api/index";
export default {
  name: "login",
  data() {
    return {
      userForm: {
        username: "",
        code: "",
      },
      timer: null,
      btnTixt: "发送验证码",
      isCaptcha: false,
      counter: 60,
      color: "#4285F4",
    };
  },
  methods: {
    //
    reset() {
      this.isCaptcha = false;
      this.color = "#4285F4";
      this.btnTixt = "发送验证码";
      if (this.timer) {
        clearInterval(this.timer);
        this.counter = 60;
        this.timer = null;
      }
    },
    //
    countDown() {
      this.timer = setInterval(() => {
        this.color = "#D1D9F1";
        this.btnTixt = `(${this.counter}秒)后重新发送`;
        this.counter--;
        if (this.counter < 0) {
          this.reset();
        }
      }, 1000);
    },
    //获取验证码
    getCaptcha() {
      // this.$refs.form
      //   .validate("form.username")
      //   .then(() => {
      let data = {
        phone: this.userForm.username,
      };
      var isPhone = /^1[3456789]\d{9}$/;

      if (data.phone != "" && isPhone.test(data.phone)) {
        this.isCaptcha = true;
        getPhoneCaptcha(data).then((res) => {
          if (res.code == 200) {
            console.log("获取验证码成功！");
            this.countDown();
          } else {
            Toast.fail("获取验证码失败！");
          }
        });
      } else {
        Toast.fail("请输入正确手机号!");
      }
      // })
      // .catch(() => {
      //   //验证失败
      //   Toast.fail("验证失败!");
      // });
    },
    //
    baseInfo() {
      getUserInfos().then((res) => {
        if (res.code == 200) {
          // console.log(res);
          this.$setCookie("userName", res.data.user.name, 1);
          if (res.data.user.name ) {
            this.$router.push({
              path: "/reports",
            });
          } else {
            this.$router.push({
              path: "/baseInfo",
            });
          }
        }
      });
    },
    //登录
    getLogin() {
      this.$refs.form
        .validate()
        .then(() => {
          getUserLogin(this.userForm).then((res) => {
            if (res.code == 200) {
              this.$setCookie("token", res.token, 1);
              Toast.success("登录成功！");
              this.baseInfo();
            } else {
              Toast.fail("登录失败！");
            }
          });
        })
        .catch(() => {
          this.$toast.fail("手机号或密码为空！");
        });
    },
    goPage(path) {
      this.$router.push({
        path: "/" + path,
      });
    },
  },
};
</script>

<style scoped lang="less">
* {
  padding: 0;
  margin: 0;
}
.box {
  height: 100vh;
  width: 100%;
  background: url("../assets/bkg.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}
.b-top {
  padding: 0.8rem 0rem 0rem 0rem;
  /* border: 1px solid rebeccapurple; */
}
.b-logo {
  width: 3rem;
  height: 3rem;
  background: url("../assets/indexLogo.png") no-repeat;
  background-size: 100% 100%;
}
.b-main {
  padding: 0.8rem;
  /* border: 1px solid rebeccapurple; */
}
.m-login {
  background-color: white;
  overflow: hidden;
  border-radius: 32px;
}
.login-title {
  font-family: PingFang SC ExtraLight;
  /* font-weight: 500; */
  color: #1d1d1d;
  font-size: 0.8rem;
  padding: 0.8rem 0rem 0.8rem 0rem;
  text-align: center;
}
.b-foot {
  padding: 20px;
  text-align: center;
}
.f-txt {
  color: #1d6aff;
  display: inline-block;
  margin-left: 10px;
}
/deep/ .van-form {
  padding: 15px;
}
/deep/ .van-cell {
  padding: 5px 0px;
}
</style>