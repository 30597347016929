<template>
    <!-- 运动日记选择 -->
    <div class="box">
      <fit-nav title="请选择"></fit-nav>
      <van-row class="b-main">
          <van-row class="con">
            <van-row type="flex" justify="space-between" align="center" style="padding: 10px;">
                <van-col span="18">
                    <van-row class="title">步行（80~99步/分钟)</van-row>
                    <van-row class="type">生活活动-低强度</van-row>
                </van-col>
                <van-col span="6" style="text-align: right;">
                    <van-button size="mini" round color="#005DFF">选择</van-button>
                </van-col>
            </van-row>
          </van-row>
      </van-row>
    </div>
  </template>
  
  <script>
  import fitNav from "@/components/fitNav.vue";
  export default {
    name: "addSwap",
    data() {
      return {};
    },
    components: {
      fitNav,
    },
  };
  </script>
  
  <style scoped>
  * {
    margin: 0;
    padding: 0;
  }
  .box {
    width: 100%;
    overflow: hidden;
    background: url("../assets/bkg.png") no-repeat;
    background-size: 100%;
    padding-top: 2rem;
  }
  .b-main {
    padding: 0.5rem 10px;
    overflow: hidden;
    background: #dbe3f9;
    font-family: PingFang SC ExtraLight;
    border-radius: 32px 32px 0px 2px;
    min-height: calc(100vh - 3rem);
    line-height: 0.6rem;
  }
  .con{
      background-color: #F3F6FE;
      border-radius: 32px;
      padding: 10px;
      
  }
  .title{
    font-size: 0.4rem;
    padding-bottom: 5px;
    color: #4285F4;
  }
  .type{
    font-size: 0.35rem;
    color: #999999;
  }
  /deep/ .van-button{
    /* width: 1.5rem; */
    padding: 15px 20px;
  }
  </style>